import { AuthActionTypes as actionTypes } from './actionTypes';

export const actions = {
  saveUser: user => ({
    type: actionTypes.saveUser,
    payload: user,
  }),
  setUserPlan: () => ({
    type: actionTypes.SetUserPlan,
  }),
  enableLoading: () => ({
    type: actionTypes.enableLoading,
  }),
  disableLoading: () => ({
    type: actionTypes.disableLoading,
  }),
  signUpError: error => ({
    type: actionTypes.signUpError,
    payload: error,
  }),
  forgotPassError: error => ({
    type: actionTypes.forgotPassError,
    payload: error,
  }),
  login: (username, password) => ({
    type: actionTypes.Login,
    payload: { username, password },
  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested, payload: {} }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  locationChange: () => ({ type: actionTypes.LocationChange }),
  setExpiredPlanError: payload => ({
    type: actionTypes.SetExpiredPlanError,
    payload,
  }),
  setAccessDeniedError: payload => ({
    type: actionTypes.SetAccessDeniedError,
    payload,
  }),
  setConnectionError: payload => ({
    type: actionTypes.SetConnectionError,
    payload,
  }),
  adminFetchListUser: () => ({
    type: actionTypes.AdminFetchListUser,
  }),
  updateListUser: payload => ({
    type: actionTypes.UpdateListUser,
    payload,
  }),
  selectUser: payload => ({
    type: actionTypes.SelectUser,
    payload,
  }),
  //////////////
  // fetchResults: payload => ({
  //   type: actionTypes.FetchResults,
  //   payload
  // }),
  // fetchWeekDetails: payload => ({
  //   type: actionTypes.FetchWeekDetails,
  //   payload,
  // }),
  updateResults: (apiRes) => ({
    type: actionTypes.UpdateResults,
    payload: { apiRes },
  }),
  disableSendButtonState: (payload = true) => ({
    type: actionTypes.DisableSendButtonState,
    payload,
  }),
  setFetchingRecordState: payload => ({
    type: actionTypes.SetFetchingRecordState,
    payload,
  }),
  setDownloadingCSVState: flag => ({
    type: actionTypes.SetDownloadingCSVState,
    payload: { flag },
  }),
  rowClicked: payload => ({
    type: actionTypes.RowClicked,
    payload,
  }),
  updateCsvData: payload => ({
    type: actionTypes.UpdateCsvData,
    payload,
  }),
  updateIsFetchingCsvData: payload => ({
    type: actionTypes.UpdateIsFetchingCsvData,
    payload,
  }),
  //delete RP ~ Csv
  deleteReportSuccess: payload => ({
    type: actionTypes.DeleteReportSuccess,
    payload,
  }),
  fetchAsinsAnalysis: payload => ({
    type: actionTypes.FetchAsinsAnalysis,
    payload,
  }),
  fetchAsinsDetails: payload => ({
    type: actionTypes.FetchAsinsDetails,
    payload,
  }),
  updateAsinsDetails: payload => ({
    type: actionTypes.UpdateAsinsDetails,
    payload,
  }),
  updateRequestError: (payload = true) => ({
    type: actionTypes.UpdateRequestError,
    payload,
  }),
  updateAsinPriceDetails: payload => ({
    type: actionTypes.UpdateAsinPriceDetails,
    payload,
  }),
  requestCsv: (payload, cb) => ({
    type: actionTypes.RequestCsv,
    payload,
    cb,
  }),
  fetchAsinPriceDetails: payload => ({
    type: actionTypes.FetchAsinPriceDetails,
    payload,
  }),
  fetchUserDetails: () => ({
    type: actionTypes.FetchUserDetails,
    payload: {},
  }),
  setUserDetails: () => ({
    type: actionTypes.SetUserDetails,
    payload: {},
  }),
  setUserPlanFromDB: payload => ({
    type: actionTypes.SetUserPlanFromDB,
    payload,
  }),
  getUserSettings: payload => ({
    type: actionTypes.GetUserSettings,
    payload,
  }),
  setUserSettings: payload => ({
    type: actionTypes.SetUserSettings,
    payload,
  }),
  updateUserSettings: payload => ({
    type: actionTypes.UpdateUserSettings,
    payload,
  }),
  getGoogleAuthUrl: payload => ({
    type: actionTypes.GetGoogleAuthUrl,
    payload,
  }),
  revokeToken: () => ({
    type: actionTypes.RevokeToken,
  }),
  revokeTokenSuccess: () => ({
    type: actionTypes.RevokeTokenSuccess,
  }),
  setParentEmail: (payload) => ({
    type: actionTypes.SetParentEmail,
    payload,
  }),
  updateAccountName: (payload) => ({
    type: actionTypes.UpdateAccountName,
    payload,
  }),
};
