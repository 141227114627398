export const getCurrentUser = (userDetails) => {
  const parentUserId = localStorage.getItem('x-parent-user-id');
  if (
    !parentUserId ||
    !userDetails.parentUsers ||
    userDetails.parentUsers?.length === 0
  ) {
    return userDetails;
  } else {
    const currentUser = userDetails?.parentUsers.find(
      (item) => item.parentUserId === parentUserId,
    );
    return currentUser;
  }
};
