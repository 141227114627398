import { actionTypes } from './actionTypes';

export const actions = {
    updateResultsKeywordsRelevancy: payload => ({
      type: actionTypes.UpdateResultsKeywordsRelevancy,
      payload
    }),
    fetchKeywordsRelevancy: payload => ({
      type: actionTypes.FetchKeywordsRelevancy,
      payload
    })
};