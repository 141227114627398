import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonTypes } from 'app/components/Material/Inputs/Button';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

const ConfirmationCompleted = () => {
  const history = useHistory();
  const goToLogin = () => {
    history.push('/auth/login');
  };
  return (
    <AuthWrapper>
      <div className="login-form-title">Email Verified</div>
      <br />
      <div className="login-form-summary">Thanks for verifying your email.</div>
      <br />
      <div className="login-form-summary">You can now log in.</div>
      <div style={{ paddingTop: 0 }} className="kt-login__back">
        <Button type={ButtonTypes.ghostBorder} onClick={goToLogin}>
          <ArrowBackIcon />
          <span style={{ paddingLeft: '4px' }}>Back to Sign in</span>
        </Button>
      </div>
    </AuthWrapper>
  );
};

export default ConfirmationCompleted;
