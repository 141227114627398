import { actionTypes } from './actionTypes';

const initialKeywordSalesState = {
    results: [],
    twentySimilarAsins: [],
    filteredResults: null,
    sendIsDisabled: false,
    details: [],
    clickedRow: undefined,
    isFetchingRecord: false,
    searchedNoResults: false,
    searchHistoryKeywords: [],
    searchHistoryKeywordsLoading: false,
    deleteSearchHistoryKeywordsLoading: false,
  };
  
export const reducer =
    (state = initialKeywordSalesState, action) => {
      switch (action.type) {
        case actionTypes.LocationChange: {
          return initialKeywordSalesState;
        }
  
        case actionTypes.UpdateResults: {
          const updatedResults = action.payload.records;
          // TODO: refactor this
          if (updatedResults.length > 0) {
            return {
              ...state,
              results: updatedResults,
              twentySimilarAsins: action.payload.twentySimilarAsins || [],
              sendIsDisabled: false,
              clickedRow: undefined,
              searchedNoResults: false,
            };
          } else {
            return {
              ...state,
              results: [],
              twentySimilarAsins: action.payload.twentySimilarAsins || [],
              sendIsDisabled: false,
              clickedRow: undefined,
              searchedNoResults: true,
            };
          }
        }

        case actionTypes.ApplyFilteredResults: {
          const filteredResults = action.payload;

          return {
            ...state,
            filteredResults,
          }
        }

        case actionTypes.ClearFilteredResults: {
          return {
            ...state,
            filteredResults: action.payload,
          }
        }
  
        case actionTypes.UpdateDetails: {
          const updatedDetails = action.payload;
          const newDetails = [...state.details];
          newDetails.push(updatedDetails);
          return {
            ...state,
            details: newDetails,
          };
        }
  
        case actionTypes.ClearDetails: {
          return {
            ...state,
            details: []
          };
        }
  
        case actionTypes.DisableSendButtonState: {
          return {
            ...state,
            sendIsDisabled: action.payload
          };
        }
  
        case actionTypes.SetFetchingRecordState: {
          return {
            ...state,
            isFetchingRecord: action.payload
          }
        }
  
        case actionTypes.RowClicked: {
          return {
            ...state,
            clickedRow: action.payload
          };
        }

        case actionTypes.SearchHistoryKeywordsRequest: {
            return {
                ...state,
                searchHistoryKeywordsLoading: true,
            }
        }

        case actionTypes.SearchHistoryKeywordsSuccess: {
            return {
                ...state,
                searchHistoryKeywordsLoading: false,
                searchHistoryKeywords: action.payload,
            }
        }

        case actionTypes.SearchHistoryKeywordsFailure: {
            return {
                ...state,
                searchHistoryKeywordsLoading: false
            }
        }

        case actionTypes.SearchHistoryKeywordsSyncDelete: {
            const ids = action.payload;
            const newHistory = state.searchHistoryKeywords.filter((item) => !ids.includes(item.id));
            return {
                ...state,
                searchHistoryKeywords: newHistory,
            }
        }

        case actionTypes.SearchHistoryKeywordsDeleteRequest: {
            return {
                ...state,
                deleteSearchHistoryKeywordsLoading: true,
            }
        }

        case actionTypes.SearchHistoryKeywordsDeleteSuccess: {
            return {
                ...state,
                deleteSearchHistoryKeywordsLoading: false,
                successFullyDeleted: true
            }
        }

        case actionTypes.SearchHistoryKeywordsDeleteFailure: {
            return {
                ...state,
                deleteSearchHistoryKeywordsLoading: false,
                successFullyDeleted: false,
            }
        }
  
        default:
          return state;
      }
}