import { actionTypes } from './actionTypes';
import {
  IAction,
  IAsin,
  IKeyword,
  IKeywordSkeleton,
  IProject,
  IProjectState,
  IRank,
  IRanksByKeyword,
} from './types';
import { ITag } from '../keyword-tags/reducer';

const initialProjectState: IProjectState = {
  isLoading: false,
  projects: [],
  project_detail: {
    asins: [],
    asinWidget: {},
    keywords: [],
    keywordWidget: null,
    ranks: [],
    rankWidgetByAsin: {
      visibility: [],
      averagePosition: [],
      choiceBadges: [],
      distributions: [],
    },
    dateRange: [],
    ranksByKeyword: [],
  },
  isGeneratingProject: null,
  rankTracker: [],
  clickedRow: undefined,
  isLoadingDetail: false,
  loadingProduct: false,
  loadingKeyword: '',
  totalKeywords: [],
  updatedProjectId: null,
  asinMessage: '',
  updatedPrimaryAsin: null,
  selectedProject: null,
  invalidAsin: [],
  isSuccess: false,
  isAsinSuccess: false,
  adding: false,
  isTrackingRecordSuccess: false,
  seasonalityData: [],
  variants: [],
  relevantKeywords: [],
  isTrackSuccess: false,
  isAllSuccess: false,
  dateRange: [],
  shownTagsOnEditTagsPopup: [],
  loadingKeywordASINApply: false,
  loadingKeywordASINApplyNewProj: false,
  fetchedRank: false,
  historyPrice: null,
  historyRating: null,
  historyReviews: null,
  dataRanks: [],
  isFetchKeyword: false,
  ASINOverviewData: [],
  loadingRanks: false,
  loadingRank: false,
  keywordRanksData: null,
  currentRanks: null,
  bsrRanks: null,
  searchProject: '',
};

export const reducer = (state: IProjectState = initialProjectState, action: IAction) => {
  switch (action.type) {
    case actionTypes.SetProjects: {
      let totalKeywords: IKeywordSkeleton[] = [];
      let projectsNew: IProject[] = [];
      if (action.payload) {
        // eslint-disable-next-line array-callback-return
        action.payload.map((item) => {
          item.keywords.forEach((keyword) => {
            if (
              !totalKeywords.find(
                (el) =>
                  el.keyword === keyword &&
                  el.marketplace === item.marketplace &&
                  el.projectId === item.project_id,
              )
            ) {
              totalKeywords.push({
                keyword,
                marketplace: item.marketplace,
                projectId: item.project_id,
              });
            }
          });
          projectsNew = [
            ...projectsNew,
            {
              ...item,
              opened_on: item.opened_on ? item.opened_on : item.updated_at,
            },
          ];
        });
      }
      return {
        ...state,
        projects: projectsNew,
        totalKeywords,
        ASINOverviewData: [],
      };
    }

    case actionTypes.SetLoading: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case actionTypes.SetSuccessTrackRecord: {
      return {
        ...state,
        isTrackingRecordSuccess: action.payload,
      };
    }

    case actionTypes.UpdateProjectDetail: {
      let { asins, widget } = action.payload;
      asins = asins.map((item: IAsin) => {
        return {
          ...item,
          pin: item.tags?.some((item: ITag) => item.pin),
        };
      });

      const project_detail = { ...state.project_detail };
      project_detail.asins = asins;
      project_detail.asinWidget = widget;

      return {
        ...state,
        project_detail,
        historyPrice: action.payload.historyPrice,
        historyRating: action.payload.historyRating,
        historyReviews: action.payload.historyReviews,
      };
    }

    case actionTypes.TrackKeywordSuccess: {
      const newProjectDetail = { ...state.project_detail };
      // eslint-disable-next-line array-callback-return
      action.payload.keywords.map((keyword: IKeyword) => {
        const index = newProjectDetail.keywords.findIndex(
          (detail) => detail.keyword === keyword.keyword,
        );
        if (index > -1) {
          newProjectDetail.keywords[index].track_rank = action.payload.trackRank;
        }
      });
      let newTotalKeywords: IKeywordSkeleton[] = [...state.totalKeywords];
      if (action.payload.trackRank) {
        // eslint-disable-next-line array-callback-return
        action.payload.keywords
          .filter((item: IKeywordSkeleton) => !item.trackRank)
          .map((item: IKeywordSkeleton) => item.keyword)
          // eslint-disable-next-line array-callback-return
          .map((keyword: IKeywordSkeleton) => {
            newTotalKeywords.push(keyword);
          });
      } else {
        // eslint-disable-next-line array-callback-return
        action.payload.keywords
          .filter((item: IKeywordSkeleton) => item.trackRank)
          .map((item: IKeywordSkeleton) => item.keyword)
          // eslint-disable-next-line array-callback-return
          .map((item: IKeywordSkeleton) => {
            const index = newTotalKeywords.findIndex((k) => k === item);
            newTotalKeywords.splice(index, 1);
          });
      }
      return {
        ...state,
        project_detail: newProjectDetail,
        totalKeywords: newTotalKeywords,
      };
    }

    case actionTypes.TrackASINSuccess: {
      const newProjectDetail = { ...state.project_detail };
      const index = newProjectDetail.asins.findIndex(
        (detail) => detail.asin === action.payload.ASIN,
      );
      if (index > -1) {
        newProjectDetail.asins[index].status = action.payload.status;
      }
      return {
        ...state,
        project_detail: newProjectDetail,
      };
    }

    case actionTypes.SetIsGeneratingProject: {
      return {
        ...state,
        isGeneratingProject: action.payload,
      };
    }

    case actionTypes.SetSelectedProject: {
      return {
        ...state,
        selectedProject: action.payload,
      };
    }

    case actionTypes.GeneratingSuccess: {
      const newProject = [...state.projects];
      if (action.payload.message === 'Valid ASIN') {
        newProject.unshift({ ...action.payload.project });
        return {
          ...state,
          projects: newProject,
          asinMessage: action.payload.message,
        };
      }
      return {
        ...state,
        asinMessage: action.payload.message,
      };
    }

    case actionTypes.UpdateRankTracker: {
      const updatedResults = action.payload;
      return {
        ...state,
        rankTracker: updatedResults,
      };
    }

    case actionTypes.RowClicked: {
      return {
        ...state,
        clickedRow: action.payload,
      };
    }

    case actionTypes.DeleteProjectSuccess: {
      let newProjects = [...state.projects];
      const deletedProjects = newProjects.filter((item) =>
        action.payload.find(
          (project: IProject) => project.project_id === item.project_id,
        ),
      );
      let keywordsTracked: string[] = [];
      if (deletedProjects && deletedProjects.length) {
        deletedProjects.forEach((item) => {
          if (item.array_tracked_keywords) {
            item.array_tracked_keywords.forEach((keyword) => {
              if (!keywordsTracked.includes(keyword)) {
                keywordsTracked.push(keyword);
              }
            });
          }
        });
      }
      newProjects = newProjects.filter(
        (item) =>
          !action.payload.find(
            (project) => project.project_id === item.project_id,
          ),
      );
      const newTotalKeywords = state.totalKeywords.filter(
        (item) =>
          !deletedProjects.map((el) => el.project_id).includes(item.projectId),
      );
      return {
        ...state,
        projects: newProjects,
        totalKeywords: newTotalKeywords,
      };
    }

    case actionTypes.SetLoadingDetail: {
      return {
        ...state,
        isLoadingDetail: action.payload,
      };
    }

    case actionTypes.UpdateAsins: {
      if (!action.payload) {
        return {
          ...state,
          isGeneratingProject: null,
        };
      }
      if (action.payload.status === 'FAILED') {
        return {
          ...state,
          asinMessage: action.payload.message,
          isGeneratingProject: null,
        };
      }
      if (!action.payload.asins) {
        return {
          ...state,
          asinMessage: action.payload.message,
          isGeneratingProject: null,
          invalidAsin: action.payload.invalidAsin,
          variants: [],
        };
      }
      let newListProject = state.projects;
      if (action.payload.project_id) {
        newListProject.map((item) => {
          if (item.project_id === action.payload.project_id) {
            item.asins = action.payload.asins.map((item: IAsin) => item.asin);
            item.image_url = action.payload.asins[0].image_url;
            item.primary_asin = action.payload.asins[0].asin;
            item.product_tracked = action.payload.asins.length;
          }
          return item;
        });
      }

      const projectDetail = { ...state.project_detail };
      action.payload.asins.forEach((el: IAsin) => {
        projectDetail.asins.unshift({ ...el, tags: [] });
      });
      return {
        ...state,
        project_detail: projectDetail,
        asinMessage: action.payload.message,
        isGeneratingProject: false,
        invalidAsin: action.payload.invalidAsin,
        variants: action.payload.variants || [],
        projects: newListProject,
      };
    }

    case actionTypes.SetLoadingProductForProject: {
      return {
        ...state,
        loadingProduct: action.payload,
      };
    }

    case actionTypes.SetLoadingRanksForProject: {
      return {
        ...state,
        loadingRank: action.payload,
      };
    }

    case actionTypes.UpdateRankTrackerData: {
      const project_detail = { ...state.project_detail };
      const { responseData, dateRange, widget } = action.payload;
      project_detail.ranks = responseData.map((rank: IRank) => {
        const item = { ...rank };
        item.pin = rank.tags.some(tag => tag.pin);
        return item;
      });
      project_detail.dateRange = dateRange;
      project_detail.rankWidgetByAsin = widget;

      return {
        ...state,
        project_detail,
      };
    }

    case actionTypes.SetLoadingKeywordForProject: {
      return {
        ...state,
        loadingKeyword: action.payload,
      };
    }

    case actionTypes.FetchRankTrackerData: {
      return {
        ...state,
        fetchedRank: false,
      };
    }

    case actionTypes.FetchParticularKeywordData: {
      return {
        ...state,
        fetchedRank: false,
      };
    }

    case actionTypes.SetFetchedRank: {
      return {
        ...state,
        fetchedRank: action.payload,
      };
    }

    case actionTypes.UpdateKeywordRankValuesData: {
      const newResults = [...state.dataRanks, action.payload];
      return {
        ...state,
        fetchedRank: true,
        dataRanks: newResults,
      };
    }

    case actionTypes.RemoveKeywordTrackerData: {
      const keywordDelete = action.payload.keywords.map((item: IKeyword) => item.keyword);
      const project_id = action.payload.projectId;
      let newResults = { ...state.project_detail };
      newResults.keywords = newResults.keywords.filter(
        (item) => !keywordDelete.includes(item.keyword),
      );
      const newTotalKeywords = state.totalKeywords.filter(
        (item) =>
          !(
            item.projectId === project_id &&
            keywordDelete.includes(item.keyword)
          ),
      );

      const projects = state.projects;
      const projectIndex = projects.findIndex(item => item.project_id === project_id);
      if (projectIndex >= 0) {
        projects[projectIndex].keywords = projects[projectIndex].keywords.filter(
          keyword => !keywordDelete.includes(keyword)
        );
      }

      return {
        ...state,
        projects,
        project_detail: newResults,
        totalKeywords: newTotalKeywords,
      };
    }

    case actionTypes.UpdateProjectSuccess: {
      let newResults = [...state.projects];
      if (action.payload.message === 'Valid ASIN') {
        const projects = action.payload.projects;
        projects.forEach((project: IProject) => {
          const itemIndex = newResults.findIndex(
            (item) => item.project_id === project.project_id,
          );
          if (itemIndex > -1) {
            newResults[itemIndex] = {
              ...newResults[itemIndex],
              name: project.name,
              updated_at: project.updated_at,
              exact_asin: project.exact_asin,
              primary_asin: project.primary_asin,
              image_url: project.image_url,
              should_track_variations: project.should_track_variations,
              marketplace: project.marketplace,
              link_id: project.link_id,
            };
          }
        });
        return {
          ...state,
          projects: newResults,
          asinMessage: action.payload.message,
          isGeneratingProject: false,
        };
      }
      return {
        ...state,
        asinMessage: action.payload.message,
        isGeneratingProject: null,
      };
    }

    case actionTypes.SetUpdatedProjectId: {
      return {
        ...state,
        updatedProjectId: action.payload,
      };
    }

    case actionTypes.SetAsinMessage: {
      return {
        ...state,
        asinMessage: action.payload,
      };
    }

    case actionTypes.UpdateResultsProject: {
      const updatedResults = action.payload;
      return {
        ...state,
        projects: updatedResults,
      };
    }

    case actionTypes.SetTotalKeywords: {
      const projects = state.projects;
      action.payload.forEach((item: {project_id: number, keyword: string}) => {
        const index = projects.findIndex(proj => proj.project_id === item.project_id);
        if (index >= 0) {
          projects[index].keywords.push(item.keyword);
        }
      });
      return {
        ...state,
        projects,
        totalKeywords: [...state.totalKeywords, ...action.payload],
      };
    }

    case actionTypes.UpdateKeyword: {
      const newListProjects = state.projects.map((item) => {
        if (item.project_id === action.payload.project_id) {
          item.keywords = action.payload.keywords;
        }
        return item;
      });
      return {
        ...state,
        projects: newListProjects,
      };
    }

    case actionTypes.RemoveAsinFromProject: {
      const asinDelete = action.payload.asins;
      let newResults = { ...state.project_detail };
      newResults.asins = newResults.asins.filter((item) => !asinDelete.includes(item.asin));
      return {
        ...state,
        project_detail: newResults,
      };
    }

    case actionTypes.SetUpdatedPrimaryAsin: {
      return {
        ...state,
        updatedPrimaryAsin: action.payload,
      };
    }

    case actionTypes.SetInvalidAsin: {
      return {
        ...state,
        invalidAsin: action.payload,
      };
    }

    case actionTypes.UpdateKeywordsData: {
      let project_detail = { ...state.project_detail };
      const { keywords, widget } = action.payload;

      project_detail.keywords = keywords.map((keyword: IKeyword) => {
        const item = { ...keyword };
        item.pin = keyword.tags.some(tag => tag.pin);
        return item;
      });
      project_detail.keywordWidget = widget;
      return {
        ...state,
        project_detail,
        isFetchKeyword: true,
      };
    }

    case actionTypes.AddKeywordToProject: {
      return {
        ...state,
        loadingKeywordASINApplyNewProj: true,
      };
    }

    case actionTypes.AddKeywordToProjectSuccess: {
      return {
        ...state,
        loadingKeywordASINApplyNewProj: false,
        adding: false,
        loadingKeywordASINApply: false,
        isSuccess: action.payload,
      };
    }

    case actionTypes.AddAsinToProject: {
      return {
        ...state,
        loadingKeywordASINApplyNewProj: true,
      };
    }

    case actionTypes.AddAsinToProjectSuccess: {
      return {
        ...state,
        loadingKeywordASINApplyNewProj: false,
        loadingKeywordASINApply: false,
        isAsinSuccess: action.payload,
      };
    }

    case actionTypes.AddingToProject: {
      return {
        ...state,
        adding: action.payload,
      };
    }

    case actionTypes.UpdateSeasonalityData: {
      return {
        ...state,
        seasonalityData: action.payload,
      };
    }

    case actionTypes.SetVariants: {
      return {
        ...state,
        variants: action.payload,
      };
    }

    case actionTypes.UpdateRelevantKeywords: {
      return {
        ...state,
        relevantKeywords: action.payload,
      };
    }

    case actionTypes.TrackSuccess: {
      return {
        ...state,
        isTrackSuccess: action.payload,
      };
    }

    case actionTypes.ClearRelevantKeywords: {
      return {
        ...state,
        relevantKeywords: [],
      };
    }

    case actionTypes.AddKeywordAndAsinToProject: {
      return {
        ...state,
        loadingKeywordASINApply: true,
      };
    }

    case actionTypes.AddKeywordToExistProject: {
      return {
        ...state,
        loadingKeywordASINApply: true,
      };
    }

    case actionTypes.SetDefaultProjectAdditionSuccesses: {
      return {
        ...state,
        isSuccess: false,
        isAsinSuccess: false,
        isAllSuccess: false,
      };
    }

    case actionTypes.FetchASINOverviewSuccesses: {
      const newASINOverviewData = [...state.ASINOverviewData, action.payload];
      return {
        ...state,
        ASINOverviewData: newASINOverviewData,
      };
    }

    case actionTypes.AddAsinToExistProject: {
      return {
        ...state,
        loadingKeywordASINApply: true,
      };
    }

    case actionTypes.AddKeywordAndAsinToProjectSuccess: {
      return {
        ...state,
        loadingKeywordASINApply: false,
        adding: false,
        isAllSuccess: action.payload,
      };
    }

    case actionTypes.SetLoadingKeywordRanks: {
      return {
        ...state,
        loadingRanks: action.payload,
      };
    }

    case actionTypes.FetchKeywordRanksSuccesses: {
      const project_detail = { ...state.project_detail };
      if (action.payload.responseData) {
        const ranksByKeyword: IRanksByKeyword[] = [];
        action.payload.responseData.forEach((item) => {
          const index = project_detail.asins.findIndex((i) => i.asin === item.asin);
          if (index >= 0) {
            ranksByKeyword.push({
              ...project_detail.asins[index],
              keywordForRanks: item.keyword,
              ...item,
            });
          } else {
            ranksByKeyword.push({ ...item });
          }
        });
        project_detail.ranksByKeyword = ranksByKeyword;
      }
      project_detail.dateRange = action.payload.dateRange;

      return {
        ...state,
        loadingRanks: false,
        project_detail,
      };
    }

    case actionTypes.UpdateTagsForKeyword: {
      let projectDetail = { ...state.project_detail };
      const data = action.payload;
      let newShownTags = [];
      const updateTag = (item: any, key: string) => {
        const el = data.find((i) => i[key] === item[key]);
        if (el) {
          newShownTags = el.tags;
          return {
            ...item,
            tags: newShownTags,
            tagsLength: newShownTags.length,
            pin: newShownTags.some((tag: ITag) => tag.pin),
          };
        } else {
          return { ...item };
        }
      };
      projectDetail.asins = projectDetail.asins.map((item) => updateTag(item, 'asin'));
      projectDetail.keywords = projectDetail.keywords.map((item) => updateTag(item, 'keyword'));
      projectDetail.ranks = projectDetail.ranks.map((item) => updateTag(item, 'keyword'));
      projectDetail.ranksByKeyword = projectDetail.ranksByKeyword.map((item) => updateTag(item, 'asin'));

      let newASINOverviewData = state.ASINOverviewData;
      if (state.ASINOverviewData?.length) {
        data.forEach((resData) => {
          const checkASIN = state.ASINOverviewData.find(
            (item) => item.asin === resData.asin,
          );
          if (checkASIN) {
            checkASIN.tags = resData.tags;
            newASINOverviewData = [
              ...newASINOverviewData.filter(
                (item) => item.asin === checkASIN.asin,
              ),
              checkASIN,
            ];
          }
        });
      }
      return {
        ...state,
        project_detail: projectDetail,
        shownTagsOnEditTagsPopup: newShownTags,
        ASINOverviewData: newASINOverviewData,
      };
    }

    case actionTypes.UpdateTagsForProject: {
      let projects = [...state.projects];
      const payloadData = action.payload;
      let newShownTags = [];
      projects = projects.map((item) => {
        let data;

        if (payloadData.length) {
          data = payloadData.find((i) => i.project_id === item.project_id);
        } else if (payloadData?.project_id === item.project_id.toString()) {
          data = payloadData;
        }

        if (data) {
          newShownTags = data.tags;
          return {
            ...item,
            tags: newShownTags,
            tagsLength: newShownTags.length,
          };
        } else {
          return { ...item };
        }
      });

      return {
        ...state,
        projects: projects,
        shownTagsOnEditTagsPopup: newShownTags,
      };
    }

    case actionTypes.UpdateTagsForAllKeywords: {
      let projectDetail = { ...state.project_detail };
      const { id, tag, colour, pin } = action.payload;
      const updateTag = (item: any) => {
        const index = item.tags.findIndex((i) => i.id === id);
        if (index > -1) {
          item.tags.splice(index, 1, { id, tag, colour, pin });
          return {
            ...item,
            tags: item.tags,
            tagsLength: item.tags.length,
          };
        } else {
          return { ...item };
        }
      };
      projectDetail.keywords = projectDetail.keywords.map(updateTag);
      projectDetail.asins = projectDetail.asins.map(updateTag);
      return {
        ...state,
        project_detail: projectDetail,
      };
    }

    case actionTypes.DeleteTagsForAllKeywords: {
      let projectDetail = { ...state.project_detail };
      const { id } = action.payload;
      const removeTag = (item) => {
        // if (item.keyword) {
        const index = item.tags.findIndex((i) => i.id === id);
        if (index > -1) {
          item.tags.splice(index, 1);
          return {
            ...item,
            tags: item.tags,
            tagsLength: item.tags.length,
          };
        } else {
          return { ...item };
        }
      };
      projectDetail.keywords = projectDetail.keywords.map(removeTag);
      projectDetail.asins = projectDetail.asins.map(removeTag);
      return {
        ...state,
        project_detail: projectDetail,
      };
    }

    case actionTypes.UpdateTagsForAllProjects: {
      let projects = [...state.projects];
      const { id, tag, colour, pin } = action.payload;
      projects = projects.map((item) => {
        const index = item.tags.findIndex((i) => i.id === id);
        if (index > -1) {
          item.tags.splice(index, 1, { id, tag, colour, pin });
          return {
            ...item,
            tags: item.tags,
            tagsLength: item.tags.length,
          };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        projects: projects,
      };
    }

    case actionTypes.DeleteTagsForAllProjects: {
      let projects = [...state.projects];
      if (action.payload) {
        const { id } = action.payload;
        projects = projects.map((item) => {
          const index = item.tags.findIndex((i) => i.id === id);
          if (index > -1) {
            item.tags.splice(index, 1);
            return {
              ...item,
              tags: item.tags,
              tagsLength: item.tags.length,
            };
          } else {
            return { ...item };
          }
        });
      }
      return {
        ...state,
        projects: projects,
      };
    }

    case actionTypes.SetShownTags: {
      return {
        ...state,
        shownTagsOnEditTagsPopup: action.payload,
      };
    }

    case actionTypes.FetchCurrentRanksSuccesses: {
      return {
        ...state,
        currentRanks: action.payload?.rank_date,
      };
    }

    case actionTypes.FetchBSRRanksSuccesses: {
      return {
        ...state,
        bsrRanks: action.payload,
      };
    }

    case actionTypes.SetSearchProject: {
      return {
        ...state,
        searchProject: action.payload,
      };
    }

    default:
      return state;
  }
};
