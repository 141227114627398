import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getHeaders, request } from '../../../utils/fetch';
import { actionTypes as marketplaceActionTypes } from '../marketplace/actionTypes';
import { actionTypes } from './actionTypes';
import { actions } from './actions';

export function* watchDateRangeSaga() {
  yield takeLatest(actionTypes.FetchLatestDate, apiFetchLatestDateSaga);
  yield takeLatest(
    marketplaceActionTypes.SetMarketplaceFilter,
    apiFetchLatestDateSaga,
  );
}

function* apiFetchLatestDateSaga(): Generator<any> {
  const marketplace = yield select(
    (state) => state.marketplace.marketplaceFilter.marketplace_id,
  );
  const headers: any = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get(`/latest-date?marketplace=${marketplace}`),
  );
  if (apiRes) {
    yield put(actions.updateLatestDate(apiRes));
  }
}
