import { actionTypes } from './actionTypes';

const initialAsinAnalysisState = {
    results: [],
    suggestedKeywords: []
  };

export const reducer =
    (state = initialAsinAnalysisState, action) => {
      switch (action.type) {
        // case actionTypes.LocationChange: {
        //   return {
        //     ...state,
        //     seasonDetails: []
        //   }
        // }

        case actionTypes.UpdateMultipleAsinsAnalysis: {
          const results = action.payload;
          if (results && results.status === 'fail') {
            return {
              ...state,
              results: [],
            }
          }
          return {
            ...state,
            results,
          }
        }

        case actionTypes.UpdateSuggestedKeywords: {
          return {
            ...state,
            suggestedKeywords: action.payload
          }
        }

        case actionTypes.ClearResultsAnalysis: {
          return {
            ...state,
            results: []
          }
        }

        default:
          return state;
      }
}
