import { actionTypes } from './actionTypes';

export const actions = {
  addFrontendAnalytics: payload => ({
    type: actionTypes.AddFrontendAnalytics,
    payload,
  }),
  updateFrontendAnalytics: payload => ({
    type: actionTypes.UpdateFrontendAnalytics,
    payload,
  }),
};
