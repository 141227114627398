export const actionTypes = {
    FetchSearchTerms: "[FetchSearchTerms] Action",
    DisableSendButtonState: "[DisableSendButtonState] Action",
    UpdateResultsAsinKeywords: "[UpdateResultsAsinKeywords] Action",
    LocationChange: "[LocationChange] Action",
    RowClicked: "[RowClicked] Action",
    FetchTopAsinRank: "[FetchTopAsinRank] Action",
    UpdateTopAsinRank: "[UpdateTopAsinRank] Action",
    FetchKeywordSales: "[FetchKeywordSales] Action",
    UpdateKeywordSales: "[UpdateKeywordSales] Action",
    SetFetchingRecordState: "[SetFetchingRecordState] Action",
    FetchAsinKeywordDetails: "[FetchAsinKeywordDetails] Action",
    UpdateAsinKeywordDetails: "[UpdateAsinKeywordDetails] Action",
    SetIsValidAsin: "[SetIsValidAsin] Action",
    SearchHistoryASINsRequest: "[SearchHistoryASINsRequest] Action",
    SearchHistoryASINsSuccess: "[SearchHistoryASINsSuccess] Action",
    SearchHistoryASINsFailure: "[SearchHistoryASINsFailure] Action",
    SearchHistoryASINsDeleteRequest: "[SearchHistoryASINsDeleteRequest] Action",
    SearchHistoryASINsDeleteSuccess: "[SearchHistoryASINsDeleteSuccess] Action",
    SearchHistoryASINsDeleteFailure: "[SearchHistoryASINsDeleteFailure] Action",
  };