import { actionTypes } from './actionTypes';

export const actions = {
    updateMultipleAsinsAnalysis: payload => ({
      type: actionTypes.UpdateMultipleAsinsAnalysis,
      payload
    }),
    fetchMultipleAsinsAnalysis: payload => ({
      type: actionTypes.FetchMultipleAsinsAnalysis,
      payload
    }),
    fetchSuggestedKeywords: payload => ({
      type: actionTypes.FetchSuggestedKeywords,
      payload
    }),
    updateSuggestedKeywords: payload => ({
      type: actionTypes.UpdateSuggestedKeywords,
      payload
    }),
    clearResultsAnalysis: payload => ({
      type: actionTypes.ClearResultsAnalysis,
      payload
    }),
};