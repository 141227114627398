import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Marketplace, MarketplaceFilter } from 'app/utils/marketplaces';
import { actionTypes } from './actionTypes';

export interface State {
  marketplaceFilter?: MarketplaceFilter;
}

export interface Action {
  type: string;
  payload: MarketplaceFilter;
}

const initialState: State = {
  marketplaceFilter: {
    label: 'amazon.com',
    marketplace_id: Marketplace.US,
  },
};

export const reducer = persistReducer(
  {
    storage,
    key: 'marketplace',
    whitelist: ['marketplaceFilter'],
  },
  (state: State = initialState, action: Action) => {
    switch (action.type) {
      case actionTypes.SetMarketplaceFilter: {
        return {
          ...state,
          marketplaceFilter: action.payload,
        };
      }
      default:
        return state;
    }
  },
);
