export const passwordHelper =
  "Password must contain at least 1 lowercase character, 1 uppercase character, 1 number and be at least 8 characters long.";
export const forgotPassText = "Password has been changed !";
export const registrationCompleteText =
  "Your account has been created. We've sent you an email with a link to verify your account. Please check your email and click on the confirmation link within 24 hours.";
export const changeTemporaryPass = "Password has been changed !";
export const forgotPasswordCompleteText =
  "We have sent you an email with a confirmation code to reset your password.";
export const resendEmailCompleteText =
  "We've sent you an email with a link to verify your account. Please check your email and click on the confirmation link within 24 hours.";
export const toolTipSearchTerm = 'Keyword, or search term, used by customers when shopping on Amazon';
export const toolTipKeywordWeeklyClicks = 'Average times per week customers click on a product after searching the keyword';
export const toolTipKeywordWeeklySales = 'Average sales made per week after customers have searched for the keyword';
export const toolTipKeywordWeeklyconvRate = 'The percentage of customers who buy a product after searching the keyword and clicking on a product';
export const toolTipAsinWeeklyClicks = 'Average times per week customers click on the entered ASIN after searching the keyword';
export const toolTipAsinWeeklySales = 'Average sales made per week of the entered ASIN after customers have searched for the keyword';
export const toolTipAsinConvRate = 'The percentage of customers who buy the entered ASIN after searching the keyword and clicking on its listing';
export const toolTipWeeksAsinInTop3 = 'The percentage of weeks the entered ASIN was in the top 3 ASINs from the entered date range';
export const toolTipAsinOrganicRank = 'The organic rank of the entered ASIN for the keyword';
export const toolTipAsinSponsoredRank = 'The sponsored rank of the entered ASIN for the keyword';
export const toolTiptopAsin = 'The ASIN that was the most recorded sales for the date range entered';
export const toolTiptopAsinWeeklyClick = 'Average times per week customers click on the Top ASIN after searching the keyword';
export const toolTiptopAsinWeeklySales = 'Average sales made per week of the Top ASIN after customers have searched for the keyword';
export const toolTiptopAsinConvRate = 'The percentage of customers who buy the Top ASIN after searching the keyword and clicking on its listing';
export const toolTipDailyGiveaway = 'Estimated number of daily units that need to be sold for an ASIN, after a search for the keyword is made, for it to be in the top 3 ASINs for that keyword';

