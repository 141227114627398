import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { actions } from '../../../store/ducks/auth/actions';
import { Auth } from 'aws-amplify';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { passwordHelper, forgotPassText } from '../../../../constants';
import styles from './ForgotPasswordConfirm.module.scss';
import DialogWindow from '../../../components/DialogWindow/DialogWindow';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';
import errorIcon from 'app/assets/images/icons/errorIcon.jpg';
import successIcon from 'app/assets/images/icons/successIcon.jpg';

const ForgotPasswordConfirm = () => {
  const [open, setOpen] = useState(false);
  const { enableLoading, disableLoading, forgotPassError } = actions;
  const errorSet = useSelector(state => state.auth.forgotPassError);
  const loading = useSelector(state => state.auth.loading);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = window;
  const urlParams = new URLSearchParams(location.search);
  const verificationCode = urlParams.get('code');
  const email = urlParams.get('email');
  const handleResend = () => {
    history.push('/auth/forgot-password');
  };

  if (!verificationCode || !email) {
    return (
      <AuthWrapper>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <p className="login-subtitle">
                The reset link has expired or is not valid.
              </p>
            </div>
            <div>
              <button
                onClick={handleResend}
                className={`btn ${styles.resendBtn}`}
              >
                Resend
              </button>
            </div>
          </div>
        </div>
      </AuthWrapper>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const clearErrorPanel = () => {
    dispatch(forgotPassError(''));
  };

  const handleClose = () => {
    setTimeout(500, setOpen(false));
    history.push('/auth');
  };

  async function passwordVerification(values) {
    const { email, verificationCode, password } = values;
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
    } catch (err) {
      if (err.code === 'ExpiredCodeException') {
        dispatch(
          forgotPassError('The reset link has expired or is not valid.'),
        );
      } else {
        dispatch(forgotPassError(err.message));
      }
    }
  }

  return (
    <>
      <AuthWrapper>
        <div className="login-form-title">Confirm Password</div>
        <div className="login-form-summary">
          Enter a new password for the <br />
          {email} account
        </div>
        <div className="kt-login__body" style={{ position: 'relative' }}>
          <div className="kt-login__form">
            <Formik
              initialValues={{
                email,
                verificationCode,
                password: '',
                confirmPassword: '',
              }}
              validate={values => {
                const errors = {};

                // if (!values.password) {
                //   errors.password = intl.formatMessage({
                //     id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                //   });
                // }

                // if (!values.confirmPassword) {
                //   errors.confirmPassword = intl.formatMessage({
                //     id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                //   });
                // } else
                if (
                  values.password &&
                  values.confirmPassword &&
                  values.password !== values.confirmPassword
                ) {
                  errors.confirmPassword =
                    "Password and Confirm Password didn't match.";
                }

                if (
                  values.password &&
                  !/^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,}$/i.test(
                    values.password,
                  )
                ) {
                  errors.password = 'Password does not meet criteria';
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                dispatch(enableLoading());
                setTimeout(() => {
                  passwordVerification(values)
                    .then(() => {
                      dispatch(disableLoading());
                      handleClickOpen();
                      localStorage.clear();
                    })
                    .catch(() => {
                      dispatch(disableLoading());
                      setSubmitting(false);
                      setStatus(
                        intl.formatMessage(
                          { id: 'AUTH.VALIDATION.NOT_FOUND' },
                          { name: values.email },
                        ),
                      );
                    });
                }, 800);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {errorSet ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{errorSet}</div>
                    </div>
                  ) : (
                    status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )
                  )}

                  <div className="form-group">
                    <div className={styles.passwordBlock}>
                      {/* <div className="label">New Password</div> */}
                      <TextField
                        type="password"
                        // label="New password"
                        margin="normal"
                        fullWidth={true}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                        className="kt-width-full custom-form-label"
                        placeholder="New password"
                      />
                      <div className={styles.tooltipInfo}>
                        <Tooltip
                          title={passwordHelper}
                          position="bottom-start"
                        />
                      </div>
                      {Boolean(touched.password && errors.password) ? (
                        <img
                          className={styles.passwordIcon}
                          src={errorIcon}
                          alt="icon"
                        />
                      ) : (
                        Boolean(
                          touched.password &&
                            values.password &&
                            !errors.password,
                        ) && (
                          <img
                            className={styles.passwordIcon}
                            src={successIcon}
                            alt="icon"
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      // label="Confirm New Password"
                      // className="kt-width-full"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword,
                      )}
                      className="kt-width-full custom-form-label"
                      placeholder="Confirm new password"
                    />
                    {Boolean(
                      touched.confirmPassword && errors.confirmPassword,
                    ) ? (
                      <img
                        className={styles.confirmIcon}
                        src={errorIcon}
                        alt="icon"
                      />
                    ) : (
                      Boolean(
                        touched.confirmPassword &&
                          values.confirmPassword &&
                          !errors.confirmPassword,
                      ) && (
                        <img
                          className={styles.confirmIcon}
                          src={successIcon}
                          alt="icon"
                        />
                      )
                    )}
                  </div>
                  <div className="login-form-actions">
                    <button
                      type="submit"
                      className={`btn button-login ${clsx({
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading || false,
                      })}`}
                      disabled={
                        !values.password ||
                        !values.confirmPassword ||
                        errors.password ||
                        errors.confirmPassword ||
                        loading
                      }
                      onClick={clearErrorPanel}
                    >
                      Confirm Password
                    </button>
                  </div>
                  {/* <div className="kt-login__actions" style={{ marginTop: 40 }}>
                    <SubmitButton
                      clearErrorPanel={clearErrorPanel}
                      type={
                        <FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
                      }
                    />
                  </div> */}
                </form>
              )}
            </Formik>
          </div>
          <div className="kt-login__back">
            <Link to="/auth">
              <ArrowBackIcon />
            </Link>
            <span>Back to Sign in</span>
          </div>
        </div>
        {errorSet || loading ? (
          ''
        ) : (
          <DialogWindow
            text={forgotPassText}
            errorSet={errorSet}
            open={open}
            handleClose={handleClose}
          />
        )}
      </AuthWrapper>
    </>
  );
};

export default ForgotPasswordConfirm;
