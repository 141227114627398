import React from 'react';
import { When } from 'react-if';
import clsx from 'clsx';

import { Icon } from 'app/components/Material/Icon';
import { ButtonTypes } from 'app/components/Material/Inputs/Button';
import { useStyles } from 'app/components/Material/Inputs/Button/styles';

export const CustomButton = (props) => {
    const {
        type = ButtonTypes.primary,
        children,
        disabled = false,
        icon,
        iconLeft,
        iconRight,
        className,
        childrenClassName,
        hideChildren = false,
        rotateIcon = false,
        rotateIconRight = false,
        selected = false,
        showBorder = false,
        hasHoveredState = true,
        ...args
    } = props;

    const classes = useStyles();

    return <button
        disabled={disabled}
        className={clsx(
            classes.root,
            className,
            type,
            {
                'Button-Selected': selected,
                'Button-Border': showBorder,
                'Hide-Children': hideChildren,
                'Show-Hover': hasHoveredState,
            }
        )}
        {...args}
    >
        <When condition={!!iconLeft}>
            <div className={clsx(classes.iconLeft, {
                'Hide-Children': hideChildren,
            })}>
                <Icon name={iconLeft} rotated={!rotateIconRight && rotateIcon} />
            </div>
        </When>
        <When condition={!!icon}>
            <div>
                <Icon name={icon} rotated={!rotateIconRight && rotateIcon} />
            </div>
        </When>
        <div className={clsx(classes.children, childrenClassName, {
            'Hide-Children': hideChildren,
        })}>{children}</div>
        <When condition={!!iconRight}>
            <div className={clsx(classes.iconRight, {
                'Hide-Children': hideChildren,
            })}>
                <Icon name={iconRight} rotated={rotateIcon} />
            </div>
        </When>
    </button>
}