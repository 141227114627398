import { actionTypes } from './actionTypes';

const initialUserFiltersState = {
  tags: [],
  tagsExist: false,
  loadingTag: false,
  addingTag: false,
};

export const reducer = (state = initialUserFiltersState, action) => {
  switch (action.type) {
    case actionTypes.SetLoadingProjectTag: {
      return {
        ...state,
        loadingTag: action.payload,
      };
    }

    case actionTypes.SetUserProjectTags: {
      return {
        ...state,
        tags: action.payload,
        tagsExist: true,
      };
    }

    case actionTypes.AddUserProjectTags: {
      const addedTags = action.payload.map(item => ({
        id: item.id,
        tag: item.tag,
        colour: item.colour,
        pin: item.pin,
        keywords: 0,
        asins: 0,
      }));
      return {
        ...state,
        tags: [...state.tags, ...addedTags],
      };
    }

    case actionTypes.SetAddingProjectTag: {
      return {
        ...state,
        addingTag: action.payload,
      };
    }

    case actionTypes.RemoveProjectTag: {
      const tags = [...state.tags];
      const removedTag = action.payload;
      const index = tags.findIndex(tag => tag.id === removedTag.id);
      if (index > -1) {
        tags.splice(index, 1);
      }
      return {
        ...state,
        tags,
      };
    }

    case actionTypes.UpdateUserProjectTag: {
      const tags = [...state.tags];
      const { id, tag, colour, pin } = action.payload;
      const index = tags.findIndex(tag => tag.id === id);
      if (index > -1) {
        tags[index] = {
          ...tags[index],
          tag,
          colour,
          pin,
        }
      }
      return {
        ...state,
        tags,
      };
    }

    default:
      return state;
  }
};