import { actionTypes } from './actionTypes';

export const actions = {
    fetchCompetitorAnalysis: payload => ({
      type: actionTypes.FetchCompetitorAnalysis,
      payload
    }),
    disableSendButtonState: (payload = true) => ({
      type: actionTypes.DisableSendButtonState,
      payload
    }),
    updateResultsCompetitor: payload => ({
      type: actionTypes.UpdateResultsCompetitor,
      payload
    }),
    fetchCompetitorDetails: payload => ({
      type: actionTypes.FetchCompetitorDetails,
      payload
    }),
    updateCompetitorDetails: (payload) => ({
      type: actionTypes.UpdateCompetitorDetails,
      payload
    }),
    setFetchingRecordState: payload => ({
      type: actionTypes.SetFetchingRecordState,
      payload
    }),
    rowClicked: payload => ({
      type: actionTypes.RowClicked,
      payload
    }),
    clearResults: payload => ({
      type: actionTypes.ClearResults,
      payload
    }),
  };