import { notify } from 'app/Services/app/notificationService';
import { actionTypes } from './actionTypes';

const initialStripeState = {
  levelOfSubscription: 0,
  availableTotalKeywordsTrack: 0,
  isRetry: false,
  products: [],
  sending: false,
  paymentInfo: {},
  upcomingInvoice: null,
  client_secret: '',
  isUpdatePayment: false,
  invoices: [],
  subscribeNewPlan: false,
  loadingInvoice: false,
  listInvoices: [],
  paymentMethod: {},
  loadingPaymentMethod: false,
  cancelSuccess: false,
  updateSuccess: false,
  continueSuccess: false,
  stripeInvoiceRecord: {},
  customerInfo: null,
  loading: false,
};

export const reducer = (state = initialStripeState, action) => {
  switch (action.type) {
    case actionTypes.UpdateListProduct: {
      return {
        ...state,
        products: action.payload.products,
        allRestrictions: action.payload.allRestrictions,
      };
    }

    case actionTypes.UpdateListInvoices: {
      return {
        ...state,
        listInvoices: action.payload,
      };
    }

    case actionTypes.OnSubscriptionComplete: {
      const subscription = action.payload;
      if (subscription.status === 'active' || subscription.status === 'trialing') {
        notify.success('Subscription success');
      }
      break;
    }

    case actionTypes.UpdateProductResource: {
      const role = action.payload.accessibility;
      switch (role) {
        case 1:
          return {
            ...state,
            availableTotalKeywordsTrack: 1000,
          };
        default:
          return state;
      }
    }

    case actionTypes.UpdatePaymentInfo: {
      return {
        ...state,
        paymentInfo: action.payload,
      };
    }

    case actionTypes.UpdateUpComingInvoice: {
      return {
        ...state,
        upcomingInvoice: action.payload,
      };
    }

    case actionTypes.SetSendingStatus: {
      return {
        ...state,
        sending: action.payload,
      };
    }

    case actionTypes.SetSendingInvoices: {
      return {
        ...state,
        loadingInvoice: action.payload,
      };
    }
    case actionTypes.SetClientSecret: {
      return {
        ...state,
        client_secret: action.payload,
      };
    }

    case actionTypes.IsUpdatePayment: {
      return {
        ...state,
        isUpdatePayment: action.payload,
      };
    }

    case actionTypes.UpdateInvoices: {
      return {
        ...state,
        invoices: action.payload,
      };
    }

    case actionTypes.SetSubscribeNewPlan: {
      return {
        ...state,
        subscribeNewPlan: action.payload,
      };
    }

    case actionTypes.UpdateInvoicePayment: {
      const paymentMethodData = state.paymentMethod;
      const tempData = {};
      if (action.payload.data && action.payload.data.length) {
        action.payload.data.forEach((charge) => {
          tempData[charge.id] = charge;
        });
      }
      const paymentData = Object.assign(paymentMethodData, tempData);
      return {
        ...state,
        paymentMethod: paymentData,
      };
    }

    case actionTypes.SetSendingPayment: {
      return {
        ...state,
        loadingPaymentMethod: action.payload,
      };
    }

    case actionTypes.CancelSuccess: {
      return {
        ...state,
        cancelSuccess: action.payload,
      };
    }

    case actionTypes.UpdateSuccess: {
      return {
        ...state,
        updateSuccess: action.payload,
      };
    }

    case actionTypes.ReactivateSuccess: {
      return {
        ...state,
        continueSuccess: action.payload,
      };
    }

    case actionTypes.GetInvoiceDetails: {
      return {
        ...state,
        stripeInvoiceRecord: action.payload,
      };
    }

    case actionTypes.UpdateCustomerInfo: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.SetCustomerInfo: {
      return {
        ...state,
        customerInfo: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};