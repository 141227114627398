import _ from 'lodash';
import config from 'config';
import moment from 'moment';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import posthog from 'posthog-js';
import stringify from 'qs-stringify';

import { getJwt, getUserByToken } from 'app/crud/auth.crud';
import { getHeaders, postRequestWrapper, request } from 'app/utils/fetch';
import { sentryCaptureException } from 'app/utils/sentry';
import { notify } from 'app/Services/app/notificationService';
import { actions as ProjectActions } from '../projects/actions';
import { AuthActionTypes as actionTypes } from './actionTypes';
import { actions } from './actions';

export function* watchAuthSaga() {
  yield takeLatest(actionTypes.Login, loginSaga);
  yield takeLatest(actionTypes.FetchUserDetails, FetchUserDetailsSaga);
  yield takeLatest(actionTypes.AdminFetchListUser, AdminFetchListUserSaga);

  yield takeLatest(actionTypes.GetGoogleAuthUrl, apiGetGoogleAuthUrl);
  yield takeLatest(actionTypes.RevokeToken, revokeTokenSaga);
  yield takeLatest(actionTypes.UpdateAccountName, updateAccountName);
  yield takeLatest(actionTypes.SelectUser, SelectUser);
  yield takeLatest(actionTypes.RequestCsv, requestCsv);
  yield takeLatest(actionTypes.FetchCsv, fetchCsv);

  //delete RP

  yield takeLatest(actionTypes.DeleteCsv, apiDeleteReportSaga);
  yield takeLatest(actionTypes.Register, registerSaga);
  yield takeLatest(actionTypes.UserRequested, userRequested);
  yield takeLatest(
    actionTypes.FetchAsinPriceDetails,
    apiFetchAsinPriceDetailsSaga,
  );
  yield takeLatest(actionTypes.FetchAsinsAnalysis, apiFetchAsinsAnalysisSaga);
  yield takeLatest(actionTypes.FetchAsinsDetails, apiFetchAsinsDetailsSaga);

  yield takeLatest(actionTypes.GetUserSettings, apiGetUserSettingsSaga);
  yield takeLatest(actionTypes.UpdateUserSettings, apiUpdateUserSettings);
}

function* loginSaga(action) {
  const data = yield Auth.currentSession();

  const headers = yield getHeaders();
  yield call(() => request(headers).get('/get-customer')); // Get and set user's plan when first login
  const userPlan = yield select((state) => state.auth?.userPlan?.name);
  posthog.identify(
    data.idToken.payload.sub,
    {
      user_id: data.idToken.payload.sub,
      email: data.idToken.payload.email,
      userPlan,
    },
  );
  const { payload } = data.getIdToken();
  const user = payload;
  const token = yield getJwt();
  const requestPayload = {
    email: user.email,
  };
  yield postRequestWrapper('/auth/email', token, requestPayload);
  yield put(actions.fulfillUser(user));
  yield put(actions.disableLoading());
}

function* FetchUserDetailsSaga(action) {
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/users/details'));
  if (apiRes) {
    const { settings, ...userDetails } = apiRes;
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    yield put(actions.setUserDetails());
    yield put(actions.setUserSettings(settings));
  }
}

function* AdminFetchListUserSaga(action) {
  yield put(actions.disableSendButtonState());
  const requestPayload = {};
  const token = yield getJwt();
  const apiRes = yield postRequestWrapper('/list-user', token, requestPayload);
  if (apiRes) {
    yield put(actions.updateListUser(apiRes));
  }
  yield put(actions.disableSendButtonState(false));
}

function* SelectUser(action) {
  yield put(ProjectActions.fetchProjectDetails(action.payload));
}

function* requestCsv(action) {
  yield put(actions.setDownloadingCSVState(true));
  const requestPayload = {
    reportType: action.payload.reportType,
    keywordASIN: action.payload.keywordASIN,
    from: moment(action.payload.from).format('YYYY-MM-DD'),
    to: moment(action.payload.to).format('YYYY-MM-DD'),
    rangeType: action.payload.rangeType,
    searchType: action.payload.searchType,
    exactAsinOnly: action.payload.exactAsin,
    onlyOneKeyword: action.payload.onlyOneKeyword,
    showRanks: action.payload.showRanks,
    getSimilarASINs: action.payload.getSimilarASINs,
    projectId: action.payload.projectId,
    order: action.payload.order,
    orderBy: action.payload.orderBy,
    selectedUserSub: action.payload.selectedUserSub,
    keywords: action.payload.keywords,
    marketplace: action.payload.marketplace,
  };
  // Only use for testing page keywords-relevancy
  if (requestPayload.reportType === 8) {
    requestPayload.keywords = action.payload.keywords;
  }
  const headers = yield getHeaders();
  yield call(() =>
    request(headers).post('/download/add', stringify(requestPayload)),
  );
  if (_.isFunction(action.cb)) {
    action.cb(null);
  }
  yield put(actions.setDownloadingCSVState(false));
}

function* updateAccountName(action) {
  yield put(actions.enableLoading());
  const requestPayload = {
    name: action.payload.accountName,
  };
  const headers = yield getHeaders();
  yield call(() => request(headers).put('/account-names', stringify(requestPayload)));
  if (_.isFunction(action.cb)) {
    action.cb(null);
  }
  yield put(actions.fetchUserDetails());
  yield put(actions.disableLoading());
}

function* fetchCsv(action) {
  yield put(actions.updateIsFetchingCsvData(true));
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/downloads'));
  if (apiRes) {
    yield put(actions.updateCsvData(apiRes));
  }
  yield put(actions.updateIsFetchingCsvData(false));
}

function* apiDeleteReportSaga(action) {
  yield put(actions.updateIsFetchingCsvData(true));

  const token = yield getJwt();
  const requestPayload = action.payload;
  const apiRes = yield postRequestWrapper(
    '/report/delete',
    token,
    requestPayload,
  );
  if (apiRes) {
    yield put(actions.deleteReportSuccess(apiRes));
  }
  yield put(actions.updateIsFetchingCsvData(false));
}

function* registerSaga() {
  yield put(actions.requestUser());
}

function* userRequested() {
  const { data: user } = yield getUserByToken();

  yield put(actions.fulfillUser(user));
}

function* apiFetchAsinPriceDetailsSaga(action) {
  const token = yield getJwt();
  const requestPayload = {
    ASIN: action.payload.ASIN,
    marketplace: action.payload.marketplace || 'US',
  };
  let apiRes = yield postRequestWrapper(
    '/asin-price-detail',
    token,
    requestPayload,
  );
  if (apiRes) {
    if (_.get(apiRes, 'status', '') === 'fail') {
      apiRes = { ...requestPayload, ...apiRes };
    }
    yield put(actions.updateAsinPriceDetails({ apiRes }));
  }
}

function* apiFetchAsinsAnalysisSaga(action) {
  yield put(actions.disableSendButtonState());
  const token = yield getJwt();
  const requestPayload = {
    arASINs: action.payload.arASINs,
    startDate: moment(action.payload.startDate).format('YYYY-MM-DD'),
    endDate: moment(action.payload.endDate).format('YYYY-MM-DD'),
    exactAsinOnly: action.payload.exactAsin,
    rangeType: action.payload.rangeType.toLowerCase(),
  };
  const apiRes = yield postRequestWrapper(
    '/competitor-analysis',
    token,
    requestPayload,
  );
  if (apiRes) {
    // yield put(actions.updateResultsAsinsAnalysis({ apiRes }));
  }
  yield put(actions.disableSendButtonState(false));
}

function* apiFetchAsinsDetailsSaga(action) {
  const token = yield getJwt();
  const requestPayload = {
    arASINs: action.payload.arASINs,
  };
  let error = false;
  const apiRes = yield call(() => {
    return fetch(config.api.SERVER_URL + '/asin-details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `${token}`,
      },
      body: stringify(requestPayload),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        return data;
      })
      .catch((e) => {
        error = true;
        sentryCaptureException(e, {
          payload: requestPayload,
          error: e,
        });
      });
  });
  if (error) {
    yield put(actions.updateRequestError(true));
  }
  if (apiRes) {
    yield put(actions.updateAsinsDetails({ apiRes }));
  }
}

function* apiGetGoogleAuthUrl(action) {
  yield put(actions.enableLoading());
  const token = yield getJwt();
  let error = false;
  const isOpenDashboardRequest = _.get(
    action,
    'payload.isOpenDashboardRequest',
    false,
  );
  const apiRes = yield call(() => {
    return fetch(
      `${config.api.SERVER_URL}/google/auth?isOpenDashboardRequest=${isOpenDashboardRequest}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `${token}`,
          'x-parent-user-id': localStorage.getItem('x-parent-user-id') || '',
          'x-parent-user-email': localStorage.getItem('x-parent-user-email') || '',
        },
      },
    )
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        return data;
      })
      .catch((e) => {
        error = true;
        sentryCaptureException(e, {
          error: e,
        });
      });
  });
  if (error) {
    yield put(actions.updateRequestError(true));
  }
  if (apiRes && apiRes.url) {
    if (isOpenDashboardRequest) {
      window.open(apiRes.url, '_blank', 'noreferrer');
    } else {
      window.location.href = apiRes.url;
    }
  }
  if (apiRes && apiRes.status === 'FAILED') {
    toast.error(apiRes.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  yield put(actions.disableLoading());
}

function* revokeTokenSaga(action) {
  yield put(actions.enableLoading());
  const token = yield getJwt();
  let error = false;
  const apiRes = yield call(() => {
    return fetch(config.api.SERVER_URL + '/google/auth', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `${token}`,
        'x-parent-user-id': localStorage.getItem('x-parent-user-id') || '',
        'x-parent-user-email': localStorage.getItem('x-parent-user-email') || '',
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        return data;
      })
      .catch((e) => {
        error = true;
        sentryCaptureException(e, {
          error: e,
        });
      });
  });
  if (error) {
    yield put(actions.updateRequestError(true));
  }
  if (apiRes && apiRes.message === 'Successfully revoked Google account.') {
    const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
    localStorage.setItem(
      'userDetails',
      JSON.stringify({ ...userDetails, isGoogleConnected: false }),
    );
    yield put(actions.revokeTokenSuccess());
    notify.success(apiRes.message);
  }
  if (apiRes && apiRes.status === 'FAILED') {
    toast.error(apiRes.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  yield put(actions.disableLoading());
}

function* apiGetUserSettingsSaga(action) {
  yield put(actions.enableLoading());

  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get(`/users/${action.payload}/settings`));

  yield put(actions.setUserSettings(apiRes.data));
  yield put(actions.disableLoading());
}

function* apiUpdateUserSettings(action) {
  yield put(actions.enableLoading());

  const headers = yield getHeaders();
  const requestPayload = { direction: action.payload.direction };
  const apiRes = yield call(() => request(headers).put(`/users/${action.payload.userId}/settings`, stringify(requestPayload)));
  yield put(actions.setUserSettings(apiRes.data));
  yield put(actions.disableLoading());
}
