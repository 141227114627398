import { takeLatest } from 'redux-saga/effects';

import { getJwt } from '../../../crud/auth.crud';
import { postRequestWrapper } from '../../../utils/fetch';
import { actionTypes } from './actionTypes';

export function* watchFrontendAnalyticsSaga() {
  yield takeLatest(actionTypes.AddFrontendAnalytics, addFrontendAnalyticsSaga);
  yield takeLatest(
    actionTypes.UpdateFrontendAnalytics,
    updateFrontendAnalyticsSaga,
  );
}

function* addFrontendAnalyticsSaga(action) {
  const token = yield getJwt();
  yield postRequestWrapper('/frontend-analytics/add', token, action.payload);
}

function* updateFrontendAnalyticsSaga(action) {
  const token = yield getJwt();
  yield postRequestWrapper('/frontend-analytics/update', token, action.payload);
}
