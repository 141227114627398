import { actionTypes } from './actionTypes';

export const actions = {
    fetchLatestDate: (payload) => ({
      type: actionTypes.FetchLatestDate,
      payload,
    }),
    updateLatestDate: (payload) => ({
      type: actionTypes.UpdateLatestDate,
      payload,
    }),
    setDateRange: (payload) => ({
      type: actionTypes.SetDateRange,
      payload,
    }),
    setRangeType: (payload) => ({
      type: actionTypes.SetRangeType,
      payload,
    }),
    setLabelSelected: (payload) => ({
      type: actionTypes.SetLabelSelected,
      payload,
    }),
    changeRangeType: (payload) => ({
      type: actionTypes.ChangeRangeType,
      payload,
    }),
  };