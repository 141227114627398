import React, { Component } from "react";
import DialogWindow from "../DialogWindow/DialogWindow";
import { sentryCaptureException } from "../../utils/sentry";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Log the error to Sentry
    sentryCaptureException(error, info);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <DialogWindow
          text={[
            "An error has occurred. Please refresh the page and if the problem persists contact us via intercom or email at ",
            <a href="mailto:support@datarova.com" target="_blank" rel="noopener noreferrer">support@datarova.com</a>
          ]}
          open={this.state.hasError}
          handleClose={() => window.location.reload()}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;