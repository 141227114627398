export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Keyword Spy" || "Keyword Sales",
        root: true,
        icon: "icon-keyword-spy",
        page: "keyword-spy",
        bullet: "dot",
        acronym: "KS",
      },
      {
        title: "ASIN Insights" || "ASIN Keywords",
        root: true,
        icon: "icon-asin-insight",
        page: "asin-insights",
        bullet: "dot",
        acronym: "AK",
      },
      //  {
      //    title: "ASIN Sales",
      //    root: true,
      //    icon: "icon-asin-sales",
      //    page: "asin-sales",
      //    bullet: "dot",
      //    acronym: "AS"
      //  },
      {
        title: "Projects",
        root: true,
        icon: "icon-project-plus",
        page: "projects",
        bullet: "dot",
        acronym: "PR",
      },
      /*{
        title: "Competitor Analysis",
        root: true,
        icon: "icon-competitor-analysis-plus",
        page: "competitor-analysis",
        bullet: "dot",
        acronym: "CA",
        beta: true,
      },*/
      //  {
      //    title: "Seasonality",
      //    root: true,
      //    icon: "icon-seasonality",
      //    page: "seasonality",
      //    bullet: "dot",
      //    acronym: "SS"
      //  },
      // {
      //   title: "Rank Tracker",
      //   root: true,
      //   icon: "icon-rank-tracker",
      //   page: "rank-tracker",
      //   bullet: "dot",
      //   acronym: "RT"
      // },
      {
        title: "Download Report",
        root: true,
        icon: "icon-download-report-plus",
        page: "download-report",
        bullet: "dot",
        acronym: "RP",
      },
      //below Menu
      // {
      //   title: "Help",
      //   root: true,
      //   icon: "icon-help-center",
      //   page: "https://help.datarova.com/",
      //   bullet: "dot",
      //   acronym: "HL",
      // },
      // {
      //   title: "Account Settings",
      //   root: true,
      //   icon: "icon-account-settings",
      //   page: "settings/account",
      //   bullet: "dot",
      //   acronym: "AS",
      // },
      // {
      //   title: "Log out",
      //   root: true,
      //   icon: "icon-log-out-plus",
      //   page: "logout",
      //   bullet: "dot",
      //   acronym: "LO",
      // },
      {
        title: "Account Settings",
        root: true,
        icon: "icon-account-settings",
        // page: "settings/account",
        bullet: "dot",
        acronym: "AS",
      },
    ],
  },
};
