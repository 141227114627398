export const actionTypes = {
    AddFilters: "[AddFilters] Action",
    UpdateListFilter: "[UpdateListFilter] Action",
    setLoadingFilter: "[setLoadingFilter] Action",
    FetchUserFilters: "[FetchUserFilters] Action",
    UpdateUserFilters: "[UpdateUserFilters] Action",
    UpdateSingleFilter: "[UpdateSingleFilter] Action",
    DeleteUserFilterRequest: "[DeleteUserFilterRequest] Action",
    RemoveUserFilterSuccess: "[RemoveUserFilterSuccess] Action",
    RemoveUserFilterFailure: "[RemoveUserFilterFailure] Action",
    FetchUserCustomColumns: "[FetchUserCustomColumns] Action",
    SaveUserCustomColumns: "[SaveUserCustomColumns] Action",
    UpdateUserCustomColumns: "[UpdateUserCustomColumns] Action",
};