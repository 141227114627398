import { actionTypes } from './actionTypes';

export const actions = {
  getUsers: payload => ({
    type: actionTypes.GetUsers,
    payload,
  }),
  getUsersSuccess: payload => ({
    type: actionTypes.GetUsersSuccess,
    payload,
  }),
  getUsersFailure: payload => ({
    type: actionTypes.GetUsersFailure,
    payload,
  }),
  addUser: payload => ({
    type: actionTypes.AddUser,
    payload,
  }),
  addUserSuccess: payload => ({
    type: actionTypes.AddUserSuccess,
    payload,
  }),
  addUserFailure: payload => ({
    type: actionTypes.AddUserFailure,
    payload,
  }),
  updateUser: payload => ({
    type: actionTypes.UpdateUser,
    payload,
  }),
  updateUserSuccess: payload => ({
    type: actionTypes.UpdateUserSuccess,
    payload,
  }),
  updateUserFailure: payload => ({
    type: actionTypes.UpdateUserFailure,
    payload,
  }),
  removeUser: payload => ({
    type: actionTypes.RemoveUser,
    payload,
  }),
  removeUserSuccess: payload => ({
    type: actionTypes.RemoveUserSuccess,
    payload,
  }),
  removeUserFailure: payload => ({
    type: actionTypes.RemoveUserFailure,
    payload,
  }),
  updateUserId: payload => ({
    type: actionTypes.UpdateUserId,
    payload,
  }),
};
