import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import React from "react";

function NetworkError() {
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div
            style={{
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>There is a problem connecting to the server. Please try again later</h2>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default NetworkError;
