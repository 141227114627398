import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  headerLogo: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "18px",
    "&:hover": {
      color: "#FFF"
    },
    "& img": {
      height: '40px',
    }
  },
  brand: {
    margin: "32px 18px"
  }
}));

export default useStyles;
