import { Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { usePaymentStyles } from '../classNames';
import { useApiData } from 'app/utils/helpers';
import { Elements } from '@stripe/react-stripe-js';
import PaymentMethodModalBody from './PaymentMehtodModalBody';

function PaymentMethodModal({ open, onClose }) {
  const classes = usePaymentStyles();
  const { client_secret } = useApiData('stripe', {});
  const appearance = {
    rules: {
      '.Label': {
        color: '#999',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
      },
    },
  };

  return (
    <Dialog open={open} classes={{ paper: classes.card }}>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Elements
          stripe={window.stripe}
          options={{ clientSecret: client_secret, appearance: appearance }}
        >
          <PaymentMethodModalBody onClose={onClose} />
        </Elements>
      </DialogContent>
    </Dialog>
  );
}
PaymentMethodModal.defaultProps = {
  open: false,
};
PaymentMethodModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
export default PaymentMethodModal;
