import React, { useState } from 'react';
import { actions } from '../../store/ducks/auth/actions';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {Dialog, TextField} from '@material-ui/core';
import {awsCompletePassword, awsLogin} from '../../crud/auth.crud';
import clsx from 'clsx';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';
import removeRedEyeIcon from 'app/assets/images/icons/remove_red_eye2.jpg';
import NewPassword from "./NewPassword";

function Login() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enableLoading, disableLoading, login, saveUser } = actions;

  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(false);

  const loading = useSelector(state => state.auth.loading);

  return (
    <AuthWrapper>
      <>
        <div className="login-form-title">Welcome Back</div>
        <div className="kt-login__body" style={{ position: 'relative' }}>
          <div className="kt-login__form">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: 'AUTH.VALIDATION.INVALID_FIELD',
                  });
                }

                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                  });
                }
                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                dispatch(enableLoading());
                setTimeout(() => {
                  awsLogin(values.email, values.password)
                    .then(resp => {
                      if (resp.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        dispatch(saveUser(resp));
                        dispatch(disableLoading());
                        setIsOpen(true);
                        setUser(resp);
                      } else {
                        dispatch(login(resp));
                      }
                    })
                    .catch(() => {
                      dispatch(disableLoading());
                      setSubmitting(false);
                      setStatus(
                        intl.formatMessage({
                          id: 'AUTH.VALIDATION.INVALID_LOGIN',
                        }),
                      );
                    });
                }, 1000);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {status ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="form-group">
                    <div className="label">Email</div>
                    <TextField
                      type="email"
                      // label="Email"
                      margin="normal"
                      className="custom-form-label"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                      placeholder="Enter email"
                    />
                  </div>

                  <div className="form-group">
                    <div className="label">Password</div>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      margin="normal"
                      // label="Password"
                      className="kt-width-full custom-form-label"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                      placeholder="Password"
                    />
                    <img
                      onClick={() => setShowPassword(prev => !prev)}
                      className="hide-password-icon"
                      src={removeRedEyeIcon}
                      alt="hide"
                    />
                  </div>

                  <div className="login-form-actions">
                    <div className="login-form-actions__forgot-button">
                      <Link
                        to="/auth/forgot-password"
                        className="forgot-button-link"
                      >
                        <FormattedMessage
                          id="AUTH.GENERAL.FORGOT_BUTTON"
                          style={{}}
                        />
                      </Link>
                    </div>
                    <button
                      id="login_signin_submit"
                      data-test-id={"login-submit"}
                      type="submit"
                      className={`btn button-login ${clsx({
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                          loading || false,
                      })}`}
                      disabled={
                        isSubmitting || !values.email || !values.password
                      }
                    >
                      <FormattedMessage id="AUTH.LOGIN.TITLE" />
                    </button>
                    <div className="login-form-actions__signup-wrapper">
                      <div className="login-form-actions__signup">
                        <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
                        <Link
                          to="/auth/registration"
                          className="login-form-actions__link-register"
                        >
                          <FormattedMessage
                            id="AUTH.GENERAL.SIGNUP_BUTTON"
                            style={{}}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Dialog
              open={isOpen}
              onClose={() => setIsOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <NewPassword onSubmit={async (password) => {
              await awsCompletePassword(user, password)
              setIsOpen(false);
              setUser(null);
              dispatch(login(user));
            }}/>
          </Dialog>
        </div>
      </>
    </AuthWrapper>
  );
}

export default Login;
