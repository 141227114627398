const local = {
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_0CKqzWHDX',
    APP_CLIENT_ID: '5dcti49pggi19uqs3df8iae3o1',
  },
  api: {
    SERVER_URL: process.env.REACT_APP_SERVER_URL,
  },
  admin: {
    USER_SUB: '',
  },
  stripe: {
    PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  },
  posthog: {
    API_KEY: process.env.REACT_APP_POSTHOG_API_KEY,
  },
  spApi: {
    APPLICATION_ID: process.env.REACT_APP_SP_API_APPLICATION_ID,
    REDIRECT_URI: process.env.REACT_APP_SELLER_CENTRAL_REDIRECT_URL,
    BUTTON_VISIBLE_TO: process.env.REACT_APP_DEMO_USER_ID,
  },
  productIds: {
    basic: 'prod_JXdOnKZyLjnOs8',
    pro: 'prod_JXdPNU93ruyXCr',
    betaUser: 'prod_Joq3l4InSFzNz0',
    specialBetaPlan: 'prod_K0pf1tuV7P3Cfm',
    business: 'prod_L6aYRHpUHA1L1X',
    enterprise: 'prod_LqjGEr4UeXezYa',
  },
};

const staging = {
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_0CKqzWHDX',
    APP_CLIENT_ID: '5dcti49pggi19uqs3df8iae3o1',
  },
  api: {
    SERVER_URL: 'https://api.data-staging.com',
  },
  admin: {
    USER_SUB: '',
  },
  stripe: {
    PUBLIC_KEY: 'pk_test_NhgKYuZF62t4B7n2gRjLaY6A',
  },
  posthog: {
    API_KEY: 'phc_uVYaX4VvEwBuISEZG42popj6Az3vVlsAmjYMbUuQxoa',
  },
  spApi: {
    APPLICATION_ID: 'amzn1.sp.solution.33478e47-0c55-4b5b-9f92-e1b4933c4c19',
    REDIRECT_URI: 'https://api.data-staging.com/sp-api/auth',
    BUTTON_VISIBLE_TO: 'b348e714-896f-4fd0-a8f4-62967d24239d',
  },
  productIds: {
    basic: 'prod_JXdOnKZyLjnOs8',
    pro: 'prod_JXdPNU93ruyXCr',
    betaUser: 'prod_Joq3l4InSFzNz0',
    specialBetaPlan: 'prod_K0pf1tuV7P3Cfm',
    business: 'prod_L6aYRHpUHA1L1X',
    enterprise: 'prod_LqjGEr4UeXezYa',
  },
};

const prod = {
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_0CKqzWHDX',
    APP_CLIENT_ID: '5dcti49pggi19uqs3df8iae3o1',
  },
  api: {
    SERVER_URL: 'https://api.datarova.com',
  },
  admin: {
    USER_SUB:
      '42c95508-2728-438f-ba61-3f50c8d3621f e5bfa73e-839b-4a8c-b2d4-84533e17e75e 4c23f4c0-2874-4445-852b-1c029091751c da2b2c7a-5b6f-409c-aeca-91bd3ba015d1',
  },
  stripe: {
    PUBLIC_KEY: 'pk_live_ae0CSedPBE5e92IjzUJwAeE2',
  },
  posthog: {
    API_KEY: 'phc_uVYaX4VvEwBuISEZG42popj6Az3vVlsAmjYMbUuQxoa',
  },
  spApi: {
    APPLICATION_ID: 'amzn1.sp.solution.33478e47-0c55-4b5b-9f92-e1b4933c4c19',
    REDIRECT_URI: 'https://api.datarova.com/sp-api/auth',
    BUTTON_VISIBLE_TO: 'b348e714-896f-4fd0-a8f4-62967d24239d',
  },
  productIds: {
    basic: 'prod_JXdGUxZ898L6lq',
    pro: 'prod_JXdHiobD2AZTtH',
    betaUser: 'prod_IL2oey7sgs3yLc',
    specialBetaPlan: 'prod_K1Zebwfx8VugFV',
    business: 'prod_L6IgB6Fs6bGBeO',
    enterprise: 'prod_LqjFmaLKMN44Rp',
  },
};

var config;

switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    config = prod;
    break;
  case 'staging':
    config = staging;
    break;
  case 'local':
    config = local;
    break;
  default:
    throw new Error('Environment not set');
}

export default {
  intercom: {
    APP_ID: 'yul8somt',
  },
  mws: {
    CLIENT_ID: 'amzn1.application-oa2-client.391875b445174e86ac75798a9ef07e54',
    CLIENT_SECRET:
      '2d915f6bf4c9a06cdcd585172c64ba983716a07d87ba67061618cac100488e3f',
  },
  ...config,
};
