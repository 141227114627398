import { actionTypes } from './actionTypes';

export const actions = {
  fetchListProduct: () => ({
    type: actionTypes.FetchListProduct,
  }),
  fetchListInvoices: () => ({
    type: actionTypes.FetchListInvoices,
  }),
  updateListProduct: (payload) => ({
    type: actionTypes.UpdateListProduct,
    payload,
  }),
  updateListInvoices: (payload) => ({
    type: actionTypes.UpdateListInvoices,
    payload,
  }),
  createSubscription: (payload) => ({
    type: actionTypes.CreateSubscription,
    payload,
  }),
  // onSubscriptionComplete: (payload) => ({
  //   type: actionTypes.OnSubscriptionComplete,
  //   payload,
  // }),
  retryInvoiceWithNewPaymentMethod: (payload) => ({
    type: actionTypes.RetryInvoiceWithNewPaymentMethod,
    payload,
  }),
  updateSubscription: (payload) => ({
    type: actionTypes.UpdateSubscription,
    payload,
  }),
  updateSuccess: (payload) => ({
    type: actionTypes.UpdateSuccess,
    payload,
  }),
  getUserPlan: (payload) => ({
    type: actionTypes.GetUserPlan,
    payload,
  }),
  updateProductResource: (payload) => ({
    type: actionTypes.UpdateProductResource,
    payload,
  }),
  retrievePaymentInfo: () => ({
    type: actionTypes.RetrievePaymentInfo,
  }),
  updatePaymentInfo: (payload) => ({
    type: actionTypes.UpdatePaymentInfo,
    payload,
  }),
  cancelSubscription: (payload) => ({
    type: actionTypes.CancelSubscription,
    payload,
  }),
  cancelSuccess: (payload) => ({
    type: actionTypes.CancelSuccess,
    payload,
  }),
  grantAccess: (payload) => ({
    type: actionTypes.GrantAccess,
    payload,
  }),
  // retrieveUpcomingInvoice: (payload) => ({
  //   type: actionTypes.RetrieveUpcomingInvoice,
  //   payload,
  // }),
  updateUpComingInvoice: (payload) => ({
    type: actionTypes.UpdateUpComingInvoice,
    payload,
  }),
  setSendingStatus: (payload) => ({
    type: actionTypes.SetSendingStatus,
    payload,
  }),
  setSendingInvoices: (payload) => ({
    type: actionTypes.SetSendingInvoices,
    payload,
  }),
  updateUserPaymentMethod: (payload) => ({
    type: actionTypes.UpdateUserPaymentMethod,
    payload,
  }),
  getClientSecret: () => ({
    type: actionTypes.GetClientSecret,
  }),
  setClientSecret: (payload) => ({
    type: actionTypes.SetClientSecret,
    payload,
  }),
  isUpdatePayment: (payload) => ({
    type: actionTypes.IsUpdatePayment,
    payload,
  }),
  getInvoices: () => ({
    type: actionTypes.GetInvoices,
  }),
  updateInvoices: (payload) => ({
    type: actionTypes.UpdateInvoices,
    payload,
  }),
  reactivateSubscription: (payload) => ({
    type: actionTypes.ReactivateSubscription,
    payload,
  }),
  reactivateSuccess: (payload) => ({
    type: actionTypes.ReactivateSuccess,
    payload,
  }),
  setSubscribeNewPlan: (payload) => ({
    type: actionTypes.SetSubscribeNewPlan,
    payload,
  }),
  // payLastInvoice: (payload) => ({
  //   type: actionTypes.PayLastInvoice,
  //   payload,
  // }),
  // applyCoupon: (payload) => ({
  //   type: actionTypes.ApplyCoupon,
  //   payload,
  // }),
  // verifyCoupon: (payload) => ({
  //   type: actionTypes.VerifyCoupon,
  //   payload,
  // }),
  // fetchInvoicePayment: (payload) => ({
  //   type: actionTypes.FetchInvoicePayment,
  //   payload,
  // }),
  updateInvoicePayment: (payload) => ({
    type: actionTypes.UpdateInvoicePayment,
    payload,
  }),
  setSendingPayment: (payload) => ({
    type: actionTypes.SetSendingPayment,
    payload,
  }),
  getInvoiceDetails: (payload) => ({
    type: actionTypes.GetInvoiceDetails,
    payload,
  }),
  getCustomerInfo: () => ({
    type: actionTypes.GetCustomerInfo,
  }),
  setCustomerInfo: (payload) => ({
    type: actionTypes.SetCustomerInfo,
    payload,
  }),
  updateCustomerInfo: (payload) => ({
    type: actionTypes.UpdateCustomerInfo,
    payload,
  }),
};