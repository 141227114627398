import { put, takeLatest, call } from "redux-saga/effects";
import moment from "moment";
import { getJwt } from "../../../crud/auth.crud";
import { postRequestWrapper, request, getHeaders, serialize } from "../../../utils/fetch";
import { actionTypes } from './actionTypes';
import { actions } from './actions';

export function* watchCompetitorAnalysisSaga() {
    yield takeLatest(actionTypes.FetchCompetitorAnalysis, apiFetchCompetitorAnalysisSaga);
    yield takeLatest(actionTypes.FetchCompetitorDetails, apiFetchCompetitorDetailsSaga);
}

function* apiFetchCompetitorAnalysisSaga(action) {
    yield put(actions.disableSendButtonState());
    const token = yield getJwt();
    const requestPayload = {
      arASINs: action.payload.arASINs,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      exactAsinOnly: action.payload.exactAsin,
      rangeType: action.payload.rangeType.toLowerCase()
    };
    const apiRes = yield postRequestWrapper("/competitor-analysis", token, requestPayload);
    if (apiRes) {
      yield put(actions.updateResultsCompetitor(apiRes));
    }
    yield put(actions.disableSendButtonState(false));
}

function* apiFetchCompetitorDetailsSaga(action) {
    yield put(actions.setFetchingRecordState(true));
    const requestPayload = {
      keyword: action.payload.keyword,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      rangeType: action.payload.rangeType.toLowerCase()
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/records/record?${serialize(requestPayload)}`));
    if (apiRes) {
      const res = {
        keyword: action.payload.keyword,
        tableData: apiRes.data.tableData,
        chartData: {
          arrDates: apiRes.data.arr_dates,
          arrSales: apiRes.data.arr_sales,
          arrClicks: apiRes.data.arr_clicks
        }
      }
      yield put(actions.updateCompetitorDetails(res));
    } else {
      yield put(actions.rowClicked(null))
    }
    yield put(actions.setFetchingRecordState(false));
}