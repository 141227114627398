import { makeStyles } from '@material-ui/styles';
import { ButtonTypes } from 'app/components/Material/Inputs/Button';

export const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'none',
            height: 36,
            width: undefined,
            border: 0,
            minWidth: 'auto',
            borderRadius: 6,
            backgroundColor: theme.defaultButtonBgColor,
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            padding: '8px 24px',
            color: '#FFFFFF',
            '& .ecom-icon::before': {
                color: '#FFFFFF'
            },
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                '&.Hide-Children': {
                    minWidth: 'auto',
                }
            },
            '&:hover': {
                backgroundColor: theme.hoveredButtonBgColor
            },
            '&:disabled, &:hover.Mui-disabled': {
                color: '#FFFFFF',
                backgroundColor: theme.disabledButtonBgColor
            },
            ['&.' + ButtonTypes.primary]: {
                height: 48,
                borderRadius: 8,
                padding: '14px 24px',
            },
            ['&.' + ButtonTypes.secondarySmall]: {
                padding: 8
            },
            ['&.' + ButtonTypes.icon]: {
                width: 36,
                height: 36,
                padding: 8,
                minWidth: 36,
                backgroundColor: 'transparent',
                color: theme.primaryTextColor,
                '&.Button-Selected': {
                    backgroundColor: theme.selectedBgColor,
                },
                '& .ecom-icon::before': {
                    color: theme.secondaryTextColor,
                },
                '&:hover': {
                    backgroundColor: 'rgba(86, 94, 237, 0.12)',
                },
                '&:disabled, &:hover.Mui-disabled': {
                    color: theme.disabledButtonBgColor,
                    '& .ecom-icon::before': {
                        color: theme.disabledButtonBgColor,
                    },
                    backgroundColor: 'transparent'
                }
            },
            ['&.' + ButtonTypes.iconSmall]: {
                width: 24,
                height: 24,
                minWidth: 24,
                padding: 0,
                backgroundColor: 'transparent',
                color: theme.primaryTextColor,
                '&.Button-Selected': {
                    backgroundColor: theme.selectedBgColor,
                },
                '& .ecom-icon::before': {
                    color: theme.secondaryTextColor,
                },
                '&:hover': {
                    backgroundColor: 'rgba(86, 94, 237, 0.12)',
                },
                '&:disabled, &:hover.Mui-disabled': {
                    color: theme.disabledButtonBgColor,
                    backgroundColor: 'transparent',
                    '& .ecom-icon::before': {
                        color: theme.disabledButtonBgColor,
                    },
                }
            },
            ['&.' + ButtonTypes.ghost]: {
                backgroundColor: 'transparent',
                color: theme.primaryTextColor,
                '&.Button-Border:not(:hover)': {
                    border: theme.primaryBorder,
                },
                '&.Button-Selected': {
                    backgroundColor: theme.selectedBgColor,
                },
                '& .ecom-icon::before': {
                    color: theme.secondaryTextColor,
                },
                '&:hover': {
                    backgroundColor: 'rgba(86, 94, 237, 0.12)',
                    '&.Button-Border': {
                        borderColor: 'transparent',
                    },
                },
                '&:disabled, &:hover.Mui-disabled': {
                    color: theme.disabledButtonBgColor,
                    backgroundColor: 'transparent',
                    '& .ecom-icon::before': {
                        color: theme.disabledButtonBgColor,
                    },
                }
            },
            ['&.' + ButtonTypes.ghostSmall]: {
                backgroundColor: 'transparent',
                color: theme.primaryTextColor,
                '&.Button-Border': {
                    boxSizing: 'border-box',
                    border: theme.primaryBorder,
                },
                [theme.breakpoints.down('sm')]: {
                    '&.Hide-Children': {
                        border: '1px solid transparent !important',
                    }
                },
                '&.Button-Selected': {
                    backgroundColor: theme.selectedBgColor,
                },
                '& .ecom-icon::before': {
                    color: theme.secondaryTextColor,
                },
                padding: 8,
                '&:hover': {
                    '&.Show-Hover': {
                        backgroundColor: 'rgba(86, 94, 237, 0.12)',
                    },
                    '&.Button-Border': {
                        borderColor: 'transparent',
                    },
                },
                '&:disabled, &:hover.Mui-disabled': {
                    color: theme.disabledButtonBgColor,
                    backgroundColor: 'transparent',
                    '& .ecom-icon::before': {
                        color: theme.disabledButtonBgColor,
                    },
                }
            },
            ['&.' + ButtonTypes.ghostBorder]: {
                backgroundColor: 'transparent',
                border: '1px solid rgba(46, 49, 94, 0.16)',
                color: theme.primaryTextColor,
                '&.Button-Border': {
                    boxSizing: 'border-box',
                    border: theme.primaryBorder,
                },
                [theme.breakpoints.down('sm')]: {
                    '&.Hide-Children': {
                        border: '1px solid transparent !important',
                    }
                },
                '&.Button-Selected': {
                    backgroundColor: theme.selectedBgColor,
                },
                '& .ecom-icon::before': {
                    color: theme.secondaryTextColor,
                },
                padding: 8,
                '&:hover': {
                    '&.Show-Hover': {
                        backgroundColor: 'rgba(86, 94, 237, 0.12)',
                    },
                    '&.Button-Border': {
                        borderColor: 'transparent',
                    },
                },
                '&:disabled, &:hover.Mui-disabled': {
                    color: theme.disabledButtonBgColor,
                    backgroundColor: 'transparent',
                    '& .ecom-icon::before': {
                        color: theme.disabledButtonBgColor,
                    },
                }
            }
        },
        iconLeft: {
            marginRight: 4,
            [theme.breakpoints.down('sm')]: {
                '&.Hide-Children': {
                    marginRight: 0,
                }
            }
        },
        iconRight: {
            marginLeft: 4,
            [theme.breakpoints.down('sm')]: {
                '&.Hide-Children': {
                    marginLeft: 0,
                }
            }
        },
        children: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                '&.Hide-Children': {
                    display: 'none',
                }
            }
        }
    }
});