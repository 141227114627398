import { put, takeLatest, call } from 'redux-saga/effects';
import _isFunction from 'lodash/isFunction';
import { request, getHeaders, serialize } from '../../../utils/fetch';
import { actions } from './actions';
import { actionTypes } from './actionTypes';

var stringify = require('qs-stringify');

export function* watchUserFilterSaga() {
  yield takeLatest(actionTypes.AddFilters, apiAddFiltersSaga);
  yield takeLatest(actionTypes.FetchUserFilters, apiFetchUserFiltersSaga);
  yield takeLatest(actionTypes.UpdateSingleFilter, apiUpdateUserFilterSaga);
  yield takeLatest(actionTypes.DeleteUserFilterRequest, apiDeleteUserFilterSaga);
  yield takeLatest(actionTypes.FetchUserCustomColumns, apiFetchUserCustomColumnsSaga);
  yield takeLatest(actionTypes.SaveUserCustomColumns, apiSaveUserCustomColumnsSaga);
}

function* apiAddFiltersSaga(action) {
  yield put(actions.setLoadingFilter(true));
  const requestPayload = {
    filterName: action.payload.filterName,
    listFilters: action.payload.filter,
    page: action.payload.page,
  };
  if (action.payload.id) {
    requestPayload.id = action.payload.id;
  }
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/filters', stringify(requestPayload)),
  );
  if (apiRes && apiRes.status === 'OK') {
    if (_isFunction(action.cb)) {
      action.cb();
    }
    yield put(
      actions.updateUserFilters([
        {
          id: action.payload.id,
          filterName: action.payload.filterName,
          page: action.payload.page,
          data: action.payload.filter,
        },
      ]),
    );
  }
  yield put(actions.setLoadingFilter(false));
}

function* apiFetchUserFiltersSaga(action) {
  yield put(actions.setLoadingFilter(true));
  const requestPayload = {
    page: action.payload,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get(`/filters?${serialize(requestPayload)}`),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.updateUserFilters(apiRes.data));
  }
  yield put(actions.setLoadingFilter(false));
}

function* apiUpdateUserFilterSaga(action) {
  yield put(actions.setLoadingFilter(true));
  const requestPayload = {
    filterName: action.payload.filterName, // = selected filter name
    page: action.payload.page, // ASINKeywords
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).delete(`/filters?${serialize(requestPayload)}`),
  ); // call delete first
  if (apiRes && apiRes.status === 'OK') {
    // if delete if success.
    requestPayload.listFilters = action.payload.filter;
    const addRes = yield call(() =>
      request(headers).post('/filters', stringify(requestPayload)),
    ); // add the new filter

    if (addRes && addRes.status === 'OK') {
      yield put(
        actions.updateUserFilters([
          {
            filterName: action.payload.filterName,
            page: action.payload.page,
            data: action.payload.filter,
          },
        ]),
      );
      yield put(actions.setLoadingFilter(false));
    } else yield put(actions.setLoadingFilter(false)); // addres ok
  } else yield put(actions.setLoadingFilter(false)); // api res ok
}

function* apiDeleteUserFilterSaga(action) {
  const requestPayload = {
    filterName: action.payload.filterName,
    page: action.payload.page,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).delete(`/filters?${serialize(requestPayload)}`),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.removeUserFilterSuccess(requestPayload));
  }
}

function* apiFetchUserCustomColumnsSaga(action) {
  const requestPayload = {
    page: action.payload.page,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get(`/filters/columns?${serialize(requestPayload)}`),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.updateUserCustomColumns(apiRes.data));
  }
}

function* apiSaveUserCustomColumnsSaga(action) {
  const requestPayload = {
    page: action.payload.page,
    custom_columns: action.payload.custom_columns,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/filters/columns', stringify(requestPayload)),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.updateUserCustomColumns([requestPayload]));
  }
}