import React from "react";
import _isEmpty from 'lodash/isEmpty';
import {useSelector} from "react-redux";
import ReactIntercom from "react-intercom";

import config from "config";

export const Intercom = () => {
    const userObj = useSelector((store) => store.auth.user);
    let user = {};
    if (!_isEmpty(userObj)) {
        let beta = false, name = userObj.email;
        if (userObj["cognito:groups"]) {
            beta = !!userObj["cognito:groups"].includes("beta");
        }
        if (userObj.given_name && userObj.family_name) {
            name = userObj.given_name + " " + userObj.family_name;
        }
        user = {
            user_id: userObj.sub,
            email: userObj.email,
            Name: name,
            beta_user: beta,
        };
    }

    return <div className="app">
        <ReactIntercom appID={config.intercom.APP_ID} {...user} />
    </div>
}