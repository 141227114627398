export enum AuthActionTypes {
  Login = '[Login] Action',
  Logout = '[Logout] Action',
  Register = '[Register] Action',
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] Auth API',
  LocationChange = '[LocationChange] Action',
  SetUserPlan = '[SetUserPlan] Action',
  SetExpiredPlanError = '[SetExpiredPlanError] Action',
  SetConnectionError = '[SetConnectionError] Action',
  AdminFetchListUser = '[AdminFetchListUser] Action',
  UpdateListUser = '[UpdateListUser] Action',
  SelectUser = '[SelectUser] Action',
  //////////////  FetchWeekDetails = '[FetchWeekDetails] Action',
  UpdateResults = '[UpdateResults] Action',
  DisableSendButtonState = '[DisableSendButtonState] Action',
  SetFetchingRecordState = '[SetFetchingRecordState] Action',
  SetDownloadingCSVState = '[SetDownloadingCSVState] Action',
  RequestCsv = '[RequestCsv] Action',
  // RP page - report ~ Csv
  FetchCsv = '[FetchCsv] Action',
  DeleteCsv = '[DeleteCsv] Action',
  DeleteReportSuccess = '[DeleteReportSuccess] Action',
  UpdateCsvData = '[UpdateCsvData] Action',
  RowClicked = '[RowClicked] Action',
  FetchSearchTerms = '[FetchSearchTerms] Action',
  signUpError = '[signUpError:] Action',
  forgotPassError = '[forgotPassError:] Action',
  enableLoading = '[enableLoading]: Action',
  disableLoading = '[disableLoading]: Action',
  saveUser = '[saveUser] Action',
  UpdateIsFetchingCsvData = '[UpdateIsFetchingCsvData] Action',
  FetchAsinsAnalysis = '[FetchAsinsAnalysis] Action',
  UpdateResultsAsinsAnalysis = '[UpdateResultsAsinsAnalysis] Action',
  FetchAsinsDetails = '[FetchAsinsDetails] Action',
  UpdateAsinsDetails = '[UpdateAsinsDetails] Action',
  UpdateRequestError = '[UpdateRequestError] Action',
  FetchAsinPriceDetails = '[FetchAsinPriceDetails] Action',
  UpdateAsinPriceDetails = '[UpdateAsinPriceDetails] Action',
  FetchUserDetails = '[FetchUserDetails] Action',
  SetUserDetails = '[SetUserDetails] Action',
  SetUserPlanFromDB = '[SetUserPlanFromDB] Action',
  SetSettingsOverview = '[SetSettingsOverview] Action',
  SetSettingsKeywordRank = '[SetSettingsKeywordRank] Action',
  GetGoogleAuthUrl = '[GetGoogleAuthUrl] Action',
  RevokeToken = '[RevokeToken] Action',
  RevokeTokenSuccess = '[RevokeTokenSuccess] Action',
  SetParentEmail = '[SetParentEmail] Action',
  SetAccessDeniedError = '[SetAccessDeniedError] Action',
  UpdateAccountName = '[UpdateAccountName] Action',
  GetUserSettings = '[GetUserSettings] Action',
  SetUserSettings = '[SetUserSettings] Action',
  UpdateUserSettings = '[UpdateUserSettings] Action',
}
