import { actionTypes } from './actionTypes';

export const actions = {
    updateListFilter: (payload) => ({
      type: actionTypes.UpdateListFilter,
      payload,
    }),
    setLoadingFilter: (payload) => ({
      type: actionTypes.setLoadingFilter,
      payload,
    }),
    addFilters: (payload, cb) => ({
      type: actionTypes.AddFilters,
      payload,
      cb,
    }),
    fetchUserFilters: (payload) => ({
      type: actionTypes.FetchUserFilters,
      payload,
    }),
    updateSingleUserFilter: (payload) => ({
      type: actionTypes.UpdateSingleFilter,
      payload,
    }),
    updateUserFilters: (payload) => ({
      type: actionTypes.UpdateUserFilters,
      payload,
    }),
    deleteUserFilterRequest: (payload) => ({
      type: actionTypes.DeleteUserFilterRequest,
      payload,
    }),
    removeUserFilterSuccess: (payload) => ({
      type: actionTypes.RemoveUserFilterSuccess,
      payload,
    }),
    removeUserFilterFailure: (payload) => ({
      type: actionTypes.RemoveUserFilterFailure,
      payload,
    }),
    fetchUserCustomColumns: (payload) => ({
      type: actionTypes.FetchUserCustomColumns,
      payload,
    }),
    saveUserCustomColumns: (payload) => ({
      type: actionTypes.SaveUserCustomColumns,
      payload,
    }),
    updateUserCustomColumns: (payload) => ({
      type: actionTypes.UpdateUserCustomColumns,
      payload,
    }),
  };