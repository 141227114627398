export const searchHistoryASINsAdapter = (searchHistoryASINs) => {
    const resultASINs = [];

    for (let i = 0; i < searchHistoryASINs.length; i += 1) {
        const searchHistoryItem = searchHistoryASINs[i]

        resultASINs.push({...searchHistoryItem, 
                                searchTime: searchHistoryItem.searchTime,
                                searchTerm: searchHistoryItem.params.ASIN,
                                imageLink: searchHistoryItem?.params.searchedASIN?.imageLink || null,
                                name: searchHistoryItem.params?.searchedASIN?.title || null,
                                price: searchHistoryItem.params?.searchedASIN?.price || null,
                        });
    }

    return resultASINs;
}