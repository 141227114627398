import { makeStyles } from '@material-ui/core';
import { removeCSSClass } from '_metronic/utils/utils';
import { actions as additionalUsersActions } from 'app/store/ducks/additional-users/actions';
import { actions as authActions } from 'app/store/ducks/auth/actions';
import { getUserType } from 'app/utils/userType';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import helpIcon from 'app/assets/images/icons/help-icon.svg';
// import teamIcon from 'app/assets/images/icons/team-icon.svg';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import connectionsIcon from 'app/assets/images/icons/connections-icon.svg';
import logoutIcon from 'app/assets/images/icons/logout-icon.svg';
import settingsIcon from 'app/assets/images/icons/settings-icon.svg';
import { Icon } from 'app/components/Material/Icon';
import { Menu } from 'app/components/Material/Menu';
import clsx from 'clsx';
import { BetaTag } from '../Common/BetaTag';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '16px 24px 24px',
    borderBottom: '1px solid rgba(46, 49, 94, 0.16);',
    display: 'flex',
  },
  companyIcon: {
    display: 'flex',
    height: '40px',
    width: '40px',
    color: '#fff',
    fontSize: '24px',
    fontWeight: 800,
    borderRadius: '8px',
    background: 'var(--2nd-primary, #797FED)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerName: {
    paddingLeft: '12px',
  },
  companyName: {
    color: '#222',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px' /* 142.857% */,
  },
  userName: {
    color: 'var(--icon-2nd-pr-text, #666)',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px' /* 133.333% */,
    paddingRight: '4px',
  },
  role: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    background: 'rgba(121, 127, 237, 0.10)',
    color: '#666',
    fontSize: '12px',
    fontWeight: 500,
    padding: '1px 6px',
  },
  menuContainer: {
    padding: '8px 0',
    borderBottom: '1px solid rgba(46, 49, 94, 0.16);',
  },
  menuItem: {
    cursor: 'pointer',
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    position: 'relative',
    '&:hover': {
      background: 'var(--hover, rgba(86, 94, 237, 0.08))',
    },
  },
  beta: {
    right: '6px',
  },
  menuItemText: {
    color: 'var(--text, #333)',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px' /* 142.857% */,
  },
  menuItemSwitch: {
    cursor: 'pointer',
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: 'var(--hover, rgba(86, 94, 237, 0.08))',
    },
  },
  menuItemLeft: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  footer: {
    paddingTop: '8px',
  },
  avatar: {
    height: '24px',
    width: '24px',
    borderRadius: '4px',
    background: 'var(--2nd-primary, #797FED)',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '20px' /* 142.857% */,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  isActive: {
    background: 'var(--lines-2-st-pr, rgba(46, 49, 94, 0.08))',
  },
}));

export const AccountSettings = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userDetails, onClose, onSettings, currentUser, user } = props;

  const userType = getUserType(user);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickHelp = () => {
    window.open('https://help.datarova.com/', '_blank');
    onClose();
  };

  // const handleClickTeam = () => {
  //   history.push('/settings/team');
  //   onClose();
  // };

  const handleClickConnections = () => {
    history.push('/settings/connections');
    onClose();
  };

  const handleClickSettings = () => {
    onSettings();
    history.push('/settings/company-profile');
  };

  const handleClickLogout = () => {
    removeCSSClass(document.body, 'kt-aside--minimize-hover');
    history.push('/logout');
  };

  const menu = [
    {
      id: 0,
      icon: helpIcon,
      label: 'Help',
      onClick: handleClickHelp,
    },
    // {
    //   id: 1,
    //   icon: teamIcon,
    //   label: 'Team',
    //   onClick: handleClickTeam,
    // },
    {
      id: 3,
      icon: settingsIcon,
      label: 'Settings',
      onClick: handleClickSettings,
    },
  ];

  userType.includes('beta') &&
    menu.push({
      id: 2,
      icon: connectionsIcon,
      label: 'Connections',
      onClick: handleClickConnections,
      betaUser: userType.includes('beta') ? true : false,
    });

  const handleOpenSwitch = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseSwitch = (e) => {
    setAnchorEl(null);
  };

  const onChange = (item, email) => {
    localStorage.setItem('x-parent-user-id', item);
    localStorage.setItem('x-parent-user-email', email);
    dispatch(authActions.setParentEmail(email));
    dispatch(additionalUsersActions.getUsers());
    handleCloseSwitch();
    onClose();
  };

  const onRemove = () => {
    localStorage.removeItem('x-parent-user-id');
    localStorage.removeItem('x-parent-user-email');
    localStorage.removeItem('ownAccount');
    dispatch(authActions.setParentEmail());
    dispatch(additionalUsersActions.getUsers());
    handleCloseSwitch();
    onClose();
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.companyIcon}>
          {currentUser?.accountName?.charAt(0)?.toUpperCase()}
        </div>
        <div className={classes.headerName}>
          <div className={classes.companyName}>{currentUser?.accountName}</div>
          <div>
            <span className={classes.userName}>
              {currentUser?.firstName || user?.given_name || ''}
            </span>
            <span className={classes.role}>{currentUser?.role || 'Owner'}</span>
          </div>
        </div>
      </div>
      <div className={classes.menuContainer}>
        {menu
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <div className={classes.menuItem} onClick={item.onClick}>
              {item.betaUser && (
                <BetaTag className={classes.beta} title="Beta" />
              )}
              <img src={item.icon} alt="" />
              <span className={classes.menuItemText}>{item.label}</span>
            </div>
          ))}
      </div>
      <div className={classes.footer}>
        {userDetails?.parentUsers?.length > 1 && (
          <div
            onClick={handleOpenSwitch}
            className={clsx(classes.menuItem, anchorEl && classes.isActive)}
          >
            <span className={classes.menuItemText}>Switch Account</span>
            <div style={{ transform: 'rotate(270deg)' }}>
              <Icon name="expand-more" />
            </div>
          </div>
        )}

        <div onClick={handleClickLogout} className={classes.menuItem}>
          <span className={classes.menuItemText}>Logout</span>
          <img src={logoutIcon} alt="" />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseSwitch}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className="kt-menu-account__modal"
        // withMobileView={true}
      >
        <div>
          {localStorage.getItem('ownAccount') !== 'disabled' && (
            <div onClick={() => onRemove()} className={classes.menuItemSwitch}>
              <div className={classes.menuItemLeft}>
                <span className={classes.avatar}>
                  {userDetails?.accountName?.charAt(0).toUpperCase()}
                </span>
                <span className={classes.menuItemText}>
                  {userDetails?.accountName || ''}
                </span>
              </div>
              {currentUser && !currentUser.id && <CheckOutlinedIcon />}
            </div>
          )}
          {userDetails?.parentUsers?.map((item) => (
            <div
              onClick={() => onChange(item.parentUserId, item.parentUserEmail)}
              className={classes.menuItemSwitch}
            >
              <div className={classes.menuItemLeft}>
                <span className={classes.avatar}>
                  {item.accountName?.charAt(0).toUpperCase()}
                </span>
                <span className={classes.menuItemText}>
                  {item.accountName || ''}
                </span>
              </div>
              {currentUser?.id && currentUser.id === item.id && (
                <CheckOutlinedIcon />
              )}
            </div>
          ))}
        </div>
      </Menu>
    </div>
  );
};
