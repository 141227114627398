import avatarIcon from 'app/assets/images/icons/avatar-icon.svg';
import { AccountSettings } from 'app/components/AccountSettings';
import { Icon } from 'app/components/Material/Icon';
import { Menu } from 'app/components/Material/Menu';
import { MenuSettings } from 'app/components/MenuSettings';
import { getCurrentUser } from 'app/utils/currentUser';
import clsx from 'clsx';
import objectPath from 'object-path';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import KTMenu from '../../_assets/js/menu';
import KTUtil from '../../_assets/js/util';
import * as builder from '../../ducks/builder';
import { removeCSSClass } from '../../utils/utils';
import MenuList from './MenuList';

class AsideLeft extends React.Component {
  asideMenuRef = React.createRef();

  insideTm;
  outsideTm;

  state = {
    anchorEl: null,
    lastPath: '',
  };

  componentDidMount() {
    this.initMenu(); // By ID

    const options = this.setupMenuOptions();
    const ktMenu = new KTMenu(this.asideMenuRef.current, options); // eslint-disable-line
  }

  initMenu() {
    const { config } = this.props;
    if (
      objectPath.get(config, 'aside.menu.dropdown') !== 'true' &&
      objectPath.get(config, 'aside.self.fixed')
    ) {
      this.asideMenuRef.current.setAttribute('data-ktmenu-scroll', '1');
    }

    if (objectPath.get(config, 'aside.menu.dropdown') === 'true') {
      this.asideMenuRef.current.setAttribute('data-ktmenu-dropdown', '1');
      this.asideMenuRef.current.setAttribute(
        'data-ktmenu-dropdown-timeout',
        objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'),
      );
    }
  }

  setupMenuOptions() {
    let menuOptions = {
      // vertical scroll
      scroll: null,
      // submenu setup
      submenu: {
        desktop: {
          // by default the menu mode set to accordion in desktop mode
          default: 'dropdown',
        },
        tablet: 'accordion', // menu set to accordion in tablet mode
        mobile: 'accordion', // menu set to accordion in mobile mode
      },
      // accordion setup
      accordion: {
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
    };

    // init aside menu
    let menuDesktopMode = 'accordion';
    const dataKtmenuDropdown = this.asideMenuRef.current.getAttribute(
      'data-ktmenu-dropdown',
    );
    if (dataKtmenuDropdown === '1') {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop.default = menuDesktopMode;
    }

    return menuOptions;
  }

  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter = (e) => {
    // check if the left aside menu is fixed
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (
          document.body.classList.contains('kt-aside--minimize') &&
          // eslint-disable-next-line no-undef
          KTUtil.isInResponsiveRange('desktop')
        ) {
          removeCSSClass(document.body, 'kt-aside--minimize');
          document.body.classList.add('kt-aside--minimize-hover');
        }
      }, 50);
    }
  };

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave = (e) => {
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        /* eslint-disable-next-line  */
        const kUtilIsResponsiveRange = KTUtil.isInResponsiveRange('desktop');
        const hasMinimizeHover = document.body.classList.contains(
          'kt-aside--minimize-hover',
        );
        if (
          hasMinimizeHover &&
          // eslint-disable-next-line no-undef
          kUtilIsResponsiveRange &&
          !this.props.isSettingsOpen &&
          !this.state.anchorEl
        ) {
          // hide back the left aside menu
          removeCSSClass(document.body, 'kt-aside--minimize-hover');
          document.body.classList.add('kt-aside--minimize');
        }
      }, 100);
    }
  };

  render() {
    const {
      ulClasses,
      menuConfig,
      layoutConfig,
      asideMenuAttr,
      asideClassesFromConfig,
      location: { pathname },
      userDetails,
      user,
      isSettingsOpen,
      setIsSettingsOpen,
    } = this.props;

    const handleOpenSettings = () => {
      setIsSettingsOpen(true);
      this.setState({
        anchorEl: null,
        lastPath: window?.location?.pathname,
      });
      this.setState({ anchorEl: null });
    };

    const handleCloseSettings = () => {
      setIsSettingsOpen(false);
      this.setState({ anchorEl: null });
    };

    const handleClickAccountSettings = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleCloseModalAccountSettings = () => {
      this.setState({ anchorEl: null });
      if (!isSettingsOpen) {
        removeCSSClass(document.body, 'kt-aside--minimize-hover');
        document.body.classList.add('kt-aside--minimize');
      }
    };

    const currentUser = getCurrentUser(userDetails);

    return (
      <>
        <div
          id="kt_aside_menu"
          ref={this.asideMenuRef}
          style={{ height: '100%', position: 'relative' }}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          data-ktmenu-vertical="1"
          className={clsx('kt-aside-menu', asideClassesFromConfig)}
          {...asideMenuAttr}
        >
          {this.props.disableAsideSelfDisplay && (
            <div className="kt-header-logo">
              <Link to="">
                <img alt="logo" src={this.props.headerLogo} />
              </Link>
            </div>
          )}
          {isSettingsOpen ? (
            <div
              className={clsx('kt-menu__nav', ulClasses)}
              style={{ height: 'calc(100vh - 110px)', position: 'relative' }}
            >
              <MenuSettings
                handleCloseSettings={handleCloseSettings}
                lastPath={this.state.lastPath}
              />
              <div
                onClick={handleClickAccountSettings}
                className={clsx(
                  'kt-menu__link kt-menu__toggle',
                  'kt-menu-account__item',
                  {
                    'kt-menu-account__item-active': this.state.anchorEl,
                  },
                )}
                style={{
                  position: 'absolute',
                  bottom: '52px',
                  width: '100%',
                  height: '89px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f6f7fd',
                  },
                }}
              >
                <span style={{ position: 'relative' }}>
                  <img src={avatarIcon} alt="" />
                  <span
                    style={{
                      fontSize: '24px',
                      fontWeight: '800',
                      color: '#fff',
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {currentUser?.accountName
                      ? currentUser?.accountName?.charAt(0)?.toUpperCase()
                      : user?.given_name?.charAt(0)?.toUpperCase()}
                  </span>
                </span>
                <span
                  className="kt-menu__link-text"
                  style={{
                    marginLeft: '8px',
                    fontFamily: 'Manrope',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '40px',
                  }}
                >
                  <span
                    className="kt-menu__link-text"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    <span className="kt-menu__link-text">
                      <span
                        style={{
                          color: '#222',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '20px',
                        }}
                      >
                        {currentUser?.accountName ||
                          `${user?.given_name}'s account`}
                      </span>
                    </span>
                    <span className="kt-menu__link-text">
                      <span
                        style={{
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '16px',
                          color: 'var(--icon-2nd-pr-text, #666)',
                        }}
                      >
                        {currentUser?.firstName || user?.given_name || ''}
                      </span>
                      <span
                        style={{
                          marginLeft: '4px',
                          borderRadius: '4px',
                          border: '1px solid rgba(0, 0, 0, 0.08)',
                          background: 'rgba(121, 127, 237, 0.10)',
                          color: '#666',
                          fontSize: '12px',
                          fontWeight: 500,
                          padding: '1px 6px',
                        }}
                      >
                        {currentUser?.role || 'Owner'}
                      </span>
                    </span>
                  </span>
                  <span
                    className="kt-menu__link-text"
                    style={{ transform: 'rotate(180deg)' }}
                  >
                    <Icon name="expand-more" />
                  </span>
                </span>
              </div>
            </div>
          ) : (
            <ul
              className={clsx('kt-menu__nav', ulClasses)}
              style={{ height: 'calc(100vh - 110px)', position: 'relative' }}
            >
              <MenuList
                currentUrl={pathname}
                menuConfig={menuConfig}
                layoutConfig={layoutConfig}
                currentUser={currentUser}
                handleClickAccountSettings={handleClickAccountSettings}
                user={user}
                isOpenAccountSettings={this.state.anchorEl}
              />
            </ul>
          )}
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={handleCloseModalAccountSettings}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          className="kt-menu-account__modal"
          withMobileView={true}
        >
          <AccountSettings
            userDetails={userDetails}
            onClose={handleCloseModalAccountSettings}
            onSettings={handleOpenSettings}
            user={user}
            currentUser={currentUser}
          />
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  menuConfig: store.builder.menuConfig,
  layoutConfig: store.builder.layoutConfig,
  headerLogo: builder.selectors.getLogo(store),
  asideMenuAttr: builder.selectors.getAttributes(store, { path: 'aside_menu' }),
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, 'aside.self.display') === false,

  ulClasses: builder.selectors.getClasses(store, {
    path: 'aside_menu_nav',
    toString: true,
  }),

  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: 'aside_menu',
    toString: true,
  }),
  userDetails: store.auth.userDetails,
  user: store.auth.user,
});

export default withRouter(connect(mapStateToProps)(AsideLeft));
