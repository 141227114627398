import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from "@material-ui/core";
import styles from './DialogNotifyWindow.module.scss'

const DialogNotifyWindow = ({ errorset, open, handleClose, text }) => {
  return (
    <Dialog
      open={errorset ? !open : open}
      onClose={handleClose}
      className={styles.dialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={styles.dialogContent}>
        <DialogContentText id="alert-dialog-description" style={{ fontFamily: "Manrope" }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.dialogButton}>
        <Button onClick={handleClose} color="primary" autoFocus style={{ fontFamily: "Manrope" }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNotifyWindow;
