import { put, takeLatest } from "redux-saga/effects";
import { getJwt } from "../../../crud/auth.crud";
import { postRequestWrapper } from "../../../utils/fetch";
import { actions as authActions } from "../auth/actions";
import { actions } from './actions';
import { actionTypes } from './actionTypes';

export function* watchKeywordsRelevancySaga() {
    yield takeLatest(actionTypes.FetchKeywordsRelevancy, apiFetchKeywordsRelevancySaga);
}

function* apiFetchKeywordsRelevancySaga(action) {
    yield put(authActions.disableSendButtonState());
    const token = yield getJwt();
    const requestPayload = {
        ASIN: action.payload.ASIN,
        keywords: action.payload.keywords,
        exactAsinOnly: action.payload.exactAsin
    };
    const apiRes = yield postRequestWrapper("/keywords-relevancy", token, requestPayload);
    if (apiRes) {
        yield put(actions.updateResultsKeywordsRelevancy(apiRes));
    }
    yield put(authActions.disableSendButtonState(false));
}