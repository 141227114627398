import { put, takeLatest, call } from "redux-saga/effects";
import moment from "moment";
import { request, serialize, getHeaders } from "../../../utils/fetch";
import { actions } from './actions';
import { actionTypes } from './actionTypes';

export function* watchAsinSalesSaga() {
    yield takeLatest(actionTypes.FetchAsinSales, apiFetchAsinSalesSaga);
    yield takeLatest(actionTypes.FetchWeekDetails, apiFetchWeekDetailsSaga);
}

function* apiFetchAsinSalesSaga(action) {
    yield put(actions.disableSendButtonState());
    const requestPayload = {
      ASIN: action.payload.ASIN,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      exactAsinOnly: action.payload.exactAsin,
      rangeType: action.payload.rangeType.toLowerCase()
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/asin-sales?${serialize(requestPayload)}`));
    if (apiRes) {
      yield put(
        actions.updateAsinSales(apiRes.data)
      );
    }
    yield put(actions.disableSendButtonState(false));
}

function* apiFetchWeekDetailsSaga(action) {
    yield put(actions.setFetchingRecordState(true));
    const requestPayload = {
      ASIN: action.payload.ASIN,
      date: action.payload.date,
      exactAsinOnly: action.payload.exactAsinOnly,
      rangeType: action.payload.rangeType.toLowerCase()
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/asin-sales/week-details?${serialize(requestPayload)}`));
    if (apiRes) {
      const res = {
        date: action.payload.date,
        data: apiRes.data
      }
      yield put(actions.updateWeekDetails(res));
    } else {
      yield put(actions.rowClicked(null))
    }
    yield put(actions.setFetchingRecordState(false));
}