import { actionTypes } from './actionTypes';

const initialSeasonalityState = {
    seasonDetails: [],
};
  
export const reducer = 
    (state = initialSeasonalityState, action) => {
      switch (action.type) {
        case actionTypes.LocationChange: {
          return {
            ...state,
            seasonDetails: []
          }
        }
  
        case actionTypes.UpdateSeasonDetails: {
          return {
            ...state,
            seasonDetails: action.payload
          }
        }
  
        default:
          return state;
      }
}
  