import { put, takeLatest } from "redux-saga/effects";
import { getJwt } from "../../../crud/auth.crud";
import { postRequestWrapper } from "../../../utils/fetch";
import { actions as authActions } from "../auth/actions";
import { actions } from './actions';
import { actionTypes } from './actionTypes';

export function* watchMultipleAsinsAnalysisSaga() {
    yield takeLatest(actionTypes.FetchMultipleAsinsAnalysis, apiFetchMultipleAsinsAnalysisSaga);
    yield takeLatest(actionTypes.FetchSuggestedKeywords, apiFetchSuggestedKeywordsSaga);
}

function* apiFetchMultipleAsinsAnalysisSaga(action) {
    yield put(authActions.disableSendButtonState());
    yield put(actions.clearResultsAnalysis());
    const token = yield getJwt();
    const requestPayload = {
      ASINs: action.payload.ASINs,
      limit: action.payload.limit,
      marketplace: action.payload.marketplace,
    };
    const apiRes = yield postRequestWrapper("/multiple-asins-analysis", token, requestPayload);
    if (apiRes) {
      yield put(actions.updateMultipleAsinsAnalysis(apiRes));
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiFetchSuggestedKeywordsSaga(action) {
    yield put(authActions.disableSendButtonState());
    const token = yield getJwt();
    const requestPayload = {
      ASIN: action.payload.ASIN,
    };
    const apiRes = yield postRequestWrapper("/suggested-keywords", token, requestPayload);
    if (apiRes) {
      yield put(actions.updateSuggestedKeywords(apiRes));
    }
    yield put(authActions.disableSendButtonState(false));
}