import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/store/ducks/stripe/actions';
import styles from './SubscriptionPlans.module.scss';
import Plans from './Plans';
import logo from 'app/assets/images/logo/logo.jpg';
// import arrowUp from 'app/assets/images/icons/arrow-up.jpg';

const SubscriptionPlans = () => {
  const dispatch = useDispatch();

  const products = useSelector(state => state.stripe.products);
  const allRestrictions = useSelector(state => state.stripe.allRestrictions);

  // eslint-disable-next-line no-unused-vars
  const [selectedInterval, setSelectedInterval] = useState('month');

  useEffect(() => {
    dispatch(actions.fetchListProduct());
  }, [dispatch]);

  const productsFilter =
    useMemo(() => (products && Array.isArray(products) ? products : []).filter(p => p.is_public &&  p.prices && p.prices.find(price => price.interval === selectedInterval && price.is_public)), [products, selectedInterval]) || null;

  return (
    <div className={styles.subscriptionContainer}>
      <div className={styles.subscriptionLogo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.subscriptionTitle}>Choose Subscription Plan</div>
      <div
        className={styles.subscriptionSummary}
        style={{ paddingBottom: '20px' }}
      >
        Compare plans and choose the one that works best for you.
        <br />
        30-Day Money Back Guarantee
      </div>
      {/* <div className={styles.selectedIntervals}>
        <div className={styles.subscriptionButtonGroup}>
          <div
            onClick={() => setSelectedInterval('month')}
            className={`${styles.subscriptionButton} ${selectedInterval ===
              'month' && styles.subscriptionButtonActive}`}
          >
            Monthly
          </div>
          <div
            onClick={() => setSelectedInterval('year')}
            className={`${styles.subscriptionButton} ${selectedInterval ===
              'year' && styles.subscriptionButtonActive}`}
          >
            Annual
          </div>
        </div>
      </div>
      <div className={styles.subscriptionSaveUp}>
        Save up to 20% <img src={arrowUp} alt="icon" />
      </div> */}
      <div className={styles.planContainer}>
        {productsFilter &&
          [...productsFilter]
            .sort((a, b) => a.prices.find(item => item.interval === selectedInterval)?.price - b.prices.find(item => item.interval === selectedInterval)?.price)
            .map((product, index) => (
              <Plans
                key={index}
                product={product}
                allRestrictions={allRestrictions}
                selectedInterval={selectedInterval}
              />
            ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
