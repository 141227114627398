import React from 'react';
import { MaterialTypes } from 'app/main/constants';
import { MaterialUIButton } from './Buttons/MaterialUIButton';
import { CustomButton } from './Buttons/CustomButton';

export const Button = (props) => {
    const {
        materialType = MaterialTypes.materialUI,
        ...args
    } = props;


    if (materialType === MaterialTypes.materialUI) {
        return <MaterialUIButton {...args} />
    }

    return <CustomButton {...args} />
}