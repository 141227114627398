export const actionTypes = {
  FetchUserTags: '[FetchUserTags] Action',
  SetLoadingTag: '[SetLoadingTag] Action',
  SetUserTags: '[SetUserTags] Action',
  AddUserTags: '[AddUserTags] Action',
  AddTags: '[AddTags] Action',
  SetAddingTag: '[SetAddingTag] Action',
  DeleteTag: '[DeleteTag] Action',
  RemoveTag: '[RemoveTag] Action',
  UpdateTag: '[UpdateTag] Action',
  UpdateUserTag: '[UpdateUserTag] Action',
};