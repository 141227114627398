import { actionTypes } from './actionTypes';

export const actions = {
    updateAsinAnalysis: payload => ({
      type: actionTypes.UpdateAsinAnalysis,
      payload
    }),
    fetchAsinAnalysis: payload => ({
      type: actionTypes.FetchAsinAnalysis,
      payload
    }),
    fetchSuggestedKeywords: payload => ({
      type: actionTypes.FetchSuggestedKeywords,
      payload
    }),
    updateSuggestedKeywords: payload => ({
      type: actionTypes.UpdateSuggestedKeywords,
      payload
    })
  };