import _get from "lodash/get";
import { actionTypes } from './actionTypes';

const initialRankTrackerState = {
    resultsRankTracker: [],
    details: [],
    sending: false,
    totalKeywords: 0,
    loading: false,
    loadingProduct: "",
    loadingKeyword: "",
    rankTrackerForProject: []
};

export const reducer = (
    (state = initialRankTrackerState, action) => {
      switch (action.type) {
        case actionTypes.UpdateResultsRankTracker: {
          const updatedResults = action.payload;
          let result = 0;
          updatedResults.forEach(row => {
            result += _get(row, "data", []).length;
          })
          return {
            ...state,
            resultsRankTracker: updatedResults,
            totalKeywords: result
          }
        }
  
        case actionTypes.RemoveKeywordTracker: {
          const asin = action.payload.ASIN;
          const keywordDelete = action.payload.keyword;
          let newResults = [...state.resultsRankTracker];
          const rowIndex = newResults.findIndex(row => row.ASIN === asin);
          if (rowIndex > -1) {
            const itemIndex = newResults[rowIndex].data.findIndex(item => item.keyword === keywordDelete)
            if (itemIndex > -1) {
              newResults[rowIndex].data.splice(itemIndex, 1);
            }
          }
          return {
            ...state,
            resultsRankTracker: newResults,
            totalKeywords: (state.totalKeywords - 1)
          }
        }
  
        case actionTypes.RemoveTracker: {
          const asin = action.payload.ASIN;
          let newResults = [...state.resultsRankTracker];
          const rowIndex = newResults.findIndex(row => row.ASIN === asin);
          let totalKeywordsRemove = 0;
          if (rowIndex > -1) {
            totalKeywordsRemove = _get(newResults[rowIndex], "data", []).length;
            newResults.splice(rowIndex, 1);
          }
          return {
            ...state,
            resultsRankTracker: newResults,
            totalKeywords: (state.totalKeywords - totalKeywordsRemove)
          }
        }
  
        case actionTypes.UpdateKeywordDetails: {
          const updatedDetails = action.payload;
          const newDetails = [...state.details];
          newDetails.push(updatedDetails);
          return {
            ...state,
            details: newDetails,
          };
        }
  
        case actionTypes.UpdateSending: {
          return {
            ...state,
            sending: action.payload
          }
        }
  
        case actionTypes.UpdateTotalKeywords: {
          return {
            ...state,
            totalKeywords: action.payload
          }
        }
  
        case actionTypes.SetLoading: {
          return {
            ...state,
            loading: action.payload
          }
        }
  
        case actionTypes.SetLoadingProduct: {
          return {
            ...state,
            loadingProduct: action.payload
          }
        }
  
        case actionTypes.UpdateProductRankValues: {
          const newResults = [...state.resultsRankTracker];
          const {ASIN, data} = action.payload;
          const item = newResults.find(item => item.ASIN === ASIN);
          if(item) {
            item.data = data
          }
          return {
            ...state,
            resultsRankTracker: newResults
          }
        }
  
        case actionTypes.UpdateKeywordRankValues: {
          const newResults = [...state.resultsRankTracker];
          const {ASIN, keyword, agg_date_with_timezone, agg_organic_rank} = action.payload;
          const record = newResults.find(item => item.ASIN === ASIN);
          if(record) {
            const item = record.data.find(item => item.keyword === keyword);
            if(item) {
              item.agg_date_with_timezone = agg_date_with_timezone;
              item.agg_organic_rank = agg_organic_rank;
              item.fetched = true;
            }
          }
          return {
            ...state,
            resultsRankTracker: newResults
          }
        }
  
        case actionTypes.SetLoadingKeyword: {
          return {
            ...state,
            loadingKeyword: action.payload
          }
        }
  
        case actionTypes.UpdateRankTrackerForProject: {
          // const newResults = [...state.rankTrackerForProject];
          const newResults = [];
          const { ASIN, data } = action.payload;
          newResults.push({
            ASIN: ASIN,
            data: [...data]
          })
          return {
            ...state,
            rankTrackerForProject: newResults,
            totalKeywords: data.length
          }
        }
  
        case actionTypes.RemoveKeywordTrackerForProject: {
          const asin = action.payload.ASIN;
          const keywordDelete = action.payload.keyword;
          let newResults = [...state.rankTrackerForProject];
          const rowIndex = newResults.findIndex(row => row.ASIN === asin);
          if (rowIndex > -1) {
            const itemIndex = newResults[rowIndex].data.findIndex(item => item.keyword === keywordDelete)
            if (itemIndex > -1) {
              newResults[rowIndex].data.splice(itemIndex, 1);
            }
          }
          return {
            ...state,
            rankTrackerForProject: newResults,
            totalKeywords: (state.totalKeywords - 1)
          }
        }
  
        case actionTypes.UpdateKeywordRankValuesForProject: {
          const newResults = [...state.rankTrackerForProject];
          const { ASIN, keyword, agg_date_with_timezone, agg_organic_rank } = action.payload;
          const record = newResults.find(item => item.ASIN === ASIN);
          if(record) {
            const item = record.data.find(item => item.keyword === keyword);
            if(item) {
              item.agg_date_with_timezone = agg_date_with_timezone;
              item.agg_organic_rank = agg_organic_rank;
              item.fetched = true;
            }
          }
          return {
            ...state,
            rankTrackerForProject: newResults
          }
        }
  
        default:
          return state;
      }
    }
  );