import { put, takeLatest } from "redux-saga/effects";
import { getJwt } from '../../../crud/auth.crud';
import { postRequestWrapper } from '../../../utils/fetch';
import { actions as authActions } from "../auth/actions";
import { actionTypes } from "./actionTypes";
import { actions } from './actions';

export function* watchAsinAnalysisSaga() {
    yield takeLatest(actionTypes.FetchAsinAnalysis, apiFetchAsinAnalysisSaga);
    yield takeLatest(actionTypes.FetchSuggestedKeywords, apiFetchSuggestedKeywordsSaga);
}

function* apiFetchAsinAnalysisSaga(action) {
    yield put(authActions.disableSendButtonState());
    const token = yield getJwt();
    const requestPayload = {
      ASIN: action.payload.ASIN,
      useNew: action.payload.useNew
    };
    const apiRes = yield postRequestWrapper("/asin-analysis", token, requestPayload);
    if (apiRes) {
      yield put(actions.updateAsinAnalysis(apiRes));
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiFetchSuggestedKeywordsSaga(action) {
    yield put(authActions.disableSendButtonState());
    const token = yield getJwt();
    const requestPayload = {
      ASIN: action.payload.ASIN,
    };
    const apiRes = yield postRequestWrapper("/suggested-keywords", token, requestPayload);
    if (apiRes) {
      yield put(actions.updateSuggestedKeywords(apiRes));
    }
    yield put(authActions.disableSendButtonState(false));
}