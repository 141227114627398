import React from "react";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";

const theme = responsiveFontSizes(createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    mainModalBgColor: '#ffffff',
    defaultButtonBgColor: '#565EED',
    hoveredButtonBgColor: '#454BBE',
    selectedButtonBgColor: '#454BBE',
    disabledButtonBgColor: '#DDDDE4',
    primaryTextColor: '#333333',
    primaryFontFamily: 'Manrope',
    primaryTitleFontWeight: 800,
    selectedBgColor: 'rgba(46, 49, 94, 0.08)', 
    secondaryTextColor: '#666666 ',
    tertiaryTextColor: '#999999',
    primaryBorder: '1px solid rgba(46, 49, 94, 0.16)',
    secondaryBorder: '1px solid #DDDDE4',
    filterLabelBackgroundColor: '#2E315E14',
    filterLabelBorder: '1px solid #2E315E29',
    primaryShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.15)',
    // direction: "rtl",
    typography: {
      fontFamily: ["Manrope"].join(",")
    },

    palette: {
      contrastThreshold: 2,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#5d78ff"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#0abb87",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#ffffff"
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#fd397a"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
));

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
