export const actionTypes = {
    AddRankTracker: "[AddRankTracker] Action",
    FetchRankTrackerDetails: "[FetchRankTrackerDetails] Action",
    UpdateResultsRankTracker: "[UpdateResultsRankTracker] Action",
    DeleteAsinRankTracker: "[DeleteAsinRankTracker] Action",
    ModifyKeywordTracker: "[ModifyKeywordTracker] Action",
    AddKeywordTracker: "[AddKeywordTracker] Action",
    DeleteKeywordTracker: "[DeleteKeywordTracker] Action",
    RemoveKeywordTracker: "[RemoveKeywordTracker] Action",
    RemoveTracker: "[RemoveTracker] Action",
    FetchKeywordDetails: "[FetchKeywordDetails] Action",
    UpdateKeywordDetails: "[UpdateKeywordDetails] Action",
    UpdateSending: "[UpdateSending] Action",
    UpdateTotalKeywords: "[UpdateTotalKeywords] Action",
    SetLoading: "[SetLoading] Action",
    FetchRankDataForParticularProduct: "[FetchRankDataForParticularProduct] Action",
    SetLoadingProduct: "[SetLoadingProduct] Action",
    UpdateProductRankValues: "[UpdateProductRankValues] Action",
    FetchParticularKeyword: "[FetchParticularKeyword] Action",
    UpdateKeywordRankValues: "[UpdateKeywordRankValues] Action",
    SetLoadingKeyword: "[SetLoadingKeyword] Action",
    FetchRankTrackerForProject: "[FetchRankTrackerForProject] Action",
    UpdateRankTrackerForProject: "[UpdateRankTrackerForProject] Action",
    AddKeywordTrackerForProject: "[AddKeywordTrackerForProject] Action",
    DeleteKeywordTrackerForProject: "[DeleteKeywordTrackerForProject] Action",
    RemoveKeywordTrackerForProject: "[RemoveKeywordTrackerForProject] Action",
    FetchParticularKeywordForProject: "[FetchParticularKeywordForProject] Action",
    UpdateKeywordRankValuesForProject: "[UpdateKeywordRankValuesForProject] Action",
};