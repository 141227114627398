import React from 'react';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import { actions } from 'app/store/ducks/stripe/actions';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import styles from './CompletePurchase.module.scss';

const CardForm = ({ priceId }) => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const sending = useSelector(state => state.stripe.sending);

  const onStartTrial = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    dispatch(actions.setSendingStatus(true));

    const {error, setupIntent} = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    if (error) {
      dispatch(actions.setSendingStatus(false));
    } else {
      dispatch(
        actions.createSubscription({
          paymentMethodId: setupIntent.payment_method,
          priceId: priceId,
          couponCode: '',
          isTrial: true,
        }),
      );
    }
  };

  return (
    <div className={styles.purchaseCard}>
      <PaymentElement/>
      <button
        onClick={onStartTrial}
        className={`btn ${styles.purchaseCardButton} ${clsx({
          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              sending || false,
        })}`}
        disabled={sending}
      >
        Start Trial
      </button>
    </div>
  );
};

export default CardForm;
