import { actionTypes } from './actionTypes';

const initialAsinSalesState = {
    results: [],
    sendIsDisabled: false,
    clickedRow: undefined,
    weekDetails: [],
  };
  
export const reducer =
    (state = initialAsinSalesState, action) => {
      switch (action.type) {
        case actionTypes.LocationChange: {
          return initialAsinSalesState;
        }
  
        case actionTypes.UpdateResultsAsinSales: {
          const updatedResults = action.payload;
          // TODO: refactor this
          if (updatedResults.length > 0) {
            return {
              ...state,
              results: updatedResults,
              sendIsDisabled: false,
              clickedRow: undefined,
            };
          } else {
            alert("No results found for the given query parameters");
            return {
              ...state,
              results: [],
              sendIsDisabled: false,
              clickedRow: undefined,
            };
          }
        }
  
        case actionTypes.DisableSendButtonState: {
          return {
            ...state,
            sendIsDisabled: action.payload
          };
        }
  
        case actionTypes.UpdateWeekDetails: {
          const updatedWeekDetails = action.payload;
          const newWeekDetails = [...state.weekDetails];
          newWeekDetails.push(updatedWeekDetails);
          return {
            ...state,
            weekDetails: newWeekDetails
          }
        }
  
        case actionTypes.ClearWeekDetails: {
          return {
            ...state,
            weekDetails: []
          }
        }
  
  
        case actionTypes.RowClicked: {
          return {
            ...state,
            clickedRow: action.payload
          };
        }
  
        case actionTypes.SetFetchingRecordState: {
          return {
            ...state,
            isFetchingRecord: action.payload
          }
        }
  
        default:
          return state;
      }
}