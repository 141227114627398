import { takeLatest, put, call } from 'redux-saga/effects';

import { getJwt } from '../../../crud/auth.crud';
import {
  getHeaders,
  getHeadersNoAuth,
  postRequestWrapper,
  request,
} from '../../../utils/fetch';
import { actionTypes } from './actionTypes';
// import {toast} from "react-toastify";
import { actions } from './actions';
import stringify from 'qs-stringify';

export function* watchAdditionalUsersSaga() {
  yield takeLatest(actionTypes.AddUser, addUser);
  yield takeLatest(actionTypes.GetUsers, getUsers);
  yield takeLatest(actionTypes.UpdateUser, updateUser);
  yield takeLatest(actionTypes.UpdateUserId, UpdateUserId);
  yield takeLatest(actionTypes.RemoveUser, removeUser);
}

function* addUser(action) {
  const token = yield getJwt();
  const apiRes = yield postRequestWrapper(
    '/additional-users',
    token,
    action.payload,
  );
  if (!apiRes || apiRes.status === 'FAILED' || apiRes === 'Bad request') {
    yield put(actions.addUserFailure(apiRes));
  } else {
    yield put(actions.addUserSuccess(apiRes));
    yield put(actions.getUsers());
  }
}

function* updateUser(action) {
  const headers = yield getHeaders();
  const requestPayload = {
    role_id: action.payload.role_id,
  };
  const apiRes = yield call(() =>
    request(headers).put(
      `/additional-users/${action.payload.id}`,
      stringify(requestPayload),
    ),
  );
  if (!apiRes || apiRes.status === 'FAILED' || apiRes === 'Bad request') {
    yield put(actions.updateUserFailure(apiRes));
  } else {
    yield put(actions.updateUserSuccess(apiRes));
    yield put(actions.getUsers());
  }
}

function* UpdateUserId(action) {
  const headers = yield getHeadersNoAuth();
  const requestPayload = {
    email: action.payload.email,
  };
  // const apiRes =
  yield call(() =>
    request(headers).put(
      `/additional-users/acceptance/${action.payload.userId}`,
      stringify(requestPayload),
    ),
  );

  // if (!apiRes || apiRes.status === 'FAILED' ) {

  // }
}

function* removeUser(action) {
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).delete(`/additional-users/${action.payload.id}`),
  );
  if (!apiRes || apiRes.status === 'FAILED' || apiRes === 'Bad request') {
    yield put(actions.removeUserFailure(apiRes));
  } else {
    yield put(actions.removeUserSuccess(apiRes));
    yield put(actions.getUsers());
  }
}

function* getUsers(action) {
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get(`/additional-users`));
  if (!apiRes || apiRes.status === 'FAILED' || apiRes === 'Bad request') {
    yield put(actions.getUsersFailure(apiRes));
  } else {
    yield put(actions.getUsersSuccess(apiRes));
  }
}
