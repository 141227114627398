export enum UserType {
  Admin = 'admin',
  Beta = 'beta',
  Normal = 'normal',
}

export const getUserType = (user: any) => {
  let userTypes = [UserType.Normal];
  if (user['cognito:groups']) {
    userTypes = [UserType.Normal, ...user['cognito:groups']];
  }
  return userTypes;
};
