import { actionTypes } from './actionTypes';

export const actions = {
    fetchResults: payload => ({
      type: actionTypes.FetchResults,
      payload
    }),
    fetchDetails: payload => ({
      type: actionTypes.FetchDetails,
      payload
    }),
    updateResults: (payload) => ({
      type: actionTypes.UpdateResults,
      payload
    }),
    updateDetails: (payload) => ({
      type: actionTypes.UpdateDetails,
      payload
    }),
    disableSendButtonState: (payload = true) => ({
      type: actionTypes.DisableSendButtonState,
      payload
    }),
    setFetchingRecordState: payload => ({
      type: actionTypes.SetFetchingRecordState,
      payload
    }),
    rowClicked: payload => ({
      type: actionTypes.RowClicked,
      payload
    }),
    clearDetails: () => ({
      type: actionTypes.ClearDetails
    }),
    searchHistoryKeywordsSyncDelete: (payload, cb) => ({
        type: actionTypes.SearchHistoryKeywordsSyncDelete,
        payload,
        cb,
    }),
    searchHistoryKeywordsRequest: (payload, cb) => ({
        type: actionTypes.SearchHistoryKeywordsRequest,
        payload,
        cb,
    }),
    searchHistoryKeywordsSuccess: (payload) => ({
        type: actionTypes.SearchHistoryKeywordsSuccess,
        payload
    }),
    searchHistoryKeywordsFailure: () => ({
        type: actionTypes.SearchHistoryKeywordsFailure,
    }),
    searchHistoryKeywordsDeleteRequest: (payload, cb) => ({
        type: actionTypes.SearchHistoryKeywordsDeleteRequest,
        payload,
        cb
    }),
    searchHistoryKeywordsDeleteSuccess: (payload) => ({
        type: actionTypes.SearchHistoryKeywordsDeleteSuccess,
        payload
    }),
    searchHistoryKeywordsDeleteFailure: () => ({
        type: actionTypes.SearchHistoryKeywordsDeleteFailure,
    }),
    applyFilteredResults: (payload) => ({
      type: actionTypes.ApplyFilteredResults,
      payload
    }),
    clearFilteredResults: (payload) => ({
      type: actionTypes.ClearFilteredResults,
      payload
    })
};