import { actionTypes } from './actionTypes';

const initialKeywordsRelevancyState = {
    results: [],
  };
  
export const reducer = 
    (state = initialKeywordsRelevancyState, action) => {
      switch (action.type) {
        // case actionTypes.LocationChange: {
        //   return {
        //     ...state,
        //     seasonDetails: []
        //   }
        // }
  
        case actionTypes.UpdateResultsKeywordsRelevancy: {
          return {
            ...state,
            results: action.payload
          }
        }
  
        default:
          return state;
      }
}