import React from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { passwordHelper } from '../../../../constants';
import styles from '../ForgotPasswordConfirm/ForgotPasswordConfirm.module.scss';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';
import errorIcon from 'app/assets/images/icons/errorIcon.jpg';
import successIcon from 'app/assets/images/icons/successIcon.jpg';

const NewPassword = ({onSubmit}) => {
    return (
        <>
            <AuthWrapper>
                <div className="login-form-title">Set New Password</div>
                <div className="kt-login__body" style={{ position: 'relative' }}>
                    <div className="kt-login__form">
                        <Formik
                            initialValues={{
                                password: '',
                                confirmPassword: '',
                            }}
                            validate={values => {
                                const errors = {};
                                if (
                                    values.password &&
                                    values.confirmPassword &&
                                    values.password !== values.confirmPassword
                                ) {
                                    errors.confirmPassword =
                                        "Password and Confirm Password didn't match.";
                                }

                                if (
                                    values.password &&
                                    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,}$/i.test(
                                        values.password,
                                    )
                                ) {
                                    errors.password = 'Password does not meet criteria';
                                }

                                return errors;
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(true);
                                onSubmit(values.password);
                                setSubmitting(false)
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                    isSubmitting
                              }) => (
                                <form onSubmit={handleSubmit} className="kt-form">

                                    <div className="form-group">
                                        <div className={styles.passwordBlock}>
                                            <TextField
                                                type="password"
                                                margin="normal"
                                                fullWidth={true}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                helperText={touched.password && errors.password}
                                                error={Boolean(touched.password && errors.password)}
                                                className="kt-width-full custom-form-label"
                                                placeholder="New password"
                                            />
                                            <div className={styles.tooltipInfo}>
                                                <Tooltip
                                                    title={passwordHelper}
                                                    position="bottom-start"
                                                />
                                            </div>
                                            {touched.password && errors.password ? (
                                                <img
                                                    className={styles.passwordIcon}
                                                    src={errorIcon}
                                                    alt="icon"
                                                />
                                            ) : (
                                                Boolean(
                                                    touched.password &&
                                                    values.password &&
                                                    !errors.password,
                                                ) && (
                                                    <img
                                                        className={styles.passwordIcon}
                                                        src={successIcon}
                                                        alt="icon"
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type="password"
                                            margin="normal"
                                            name="confirmPassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            helperText={
                                                touched.confirmPassword && errors.confirmPassword
                                            }
                                            error={Boolean(
                                                touched.confirmPassword && errors.confirmPassword,
                                            )}
                                            className="kt-width-full custom-form-label"
                                            placeholder="Confirm new password"
                                        />
                                        {touched.confirmPassword && errors.confirmPassword ? (
                                            <img
                                                className={styles.confirmIcon}
                                                src={errorIcon}
                                                alt="icon"
                                            />
                                        ) : (
                                            Boolean(
                                                touched.confirmPassword &&
                                                values.confirmPassword &&
                                                !errors.confirmPassword,
                                            ) && (
                                                <img
                                                    className={styles.confirmIcon}
                                                    src={successIcon}
                                                    alt="icon"
                                                />
                                            )
                                        )}
                                    </div>
                                    <div className="login-form-actions">
                                        <button
                                            type="submit"
                                            className={`btn button-login ${clsx({
                                                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                                                    isSubmitting || false,
                                            })}`}
                                            disabled={
                                                !values.password ||
                                                !values.confirmPassword ||
                                                errors.password ||
                                                errors.confirmPassword ||
                                                isSubmitting
                                            }
                                        >
                                            Confirm Password
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </AuthWrapper>
        </>
    );
};

export default NewPassword;
