export const actionTypes = {
    FetchResults: "[FetchResults] Action",
    FetchDetails: "[FetchDetails] Action",
    UpdateResults: "[UpdateResults] Action",
    UpdateDetails: "[UpdateDetails] Action",
    DisableSendButtonState: "[DisableSendButtonState] Action",
    SetFetchingRecordState: "[SetFetchingRecordState] Action",
    RowClicked: "[RowClicked] Action",
    ClearDetails: "[ClearDetails] Action",
    LocationChange: "[LocationChange] Action",
    SearchHistoryKeywordsRequest: "[SearchHistoryKeywordsRequest] Action",
    SearchHistoryKeywordsSuccess: "[SearchHistoryKeywordsSuccess] Action",
    SearchHistoryKeywordsFailure: "[SearchHistoryKeywordsFailure] Action",
    SearchHistoryKeywordsDeleteRequest: "[SearchHistoryKeywordsDeleteRequest] Action",
    SearchHistoryKeywordsDeleteSuccess: "[SearchHistoryKeywordsDeleteSuccess] Action",
    SearchHistoryKeywordsDeleteFailure: "[SearchHistoryKeywordsDeleteFailure] Action",
    ApplyFilteredResults: "[ApplyFilteredResults] Action",
    ClearFilteredResults: "[ClearFilteredResults] Action",
    SearchHistoryKeywordsSyncDelete: "[SearchHistoryKeywordsSyncDelete] Action",
};