import { actionTypes } from './actionTypes';
import { userFilterAdapter } from './adapter';

const initialUserFiltersState = {
  filter: [],
  loadingFilter: false,
  userFilters: [],
  userCustomColumns: [],
  removeFilterLoading: false,
};

export const reducer = (state = initialUserFiltersState, action) => {
  switch (action.type) {
    case actionTypes.UpdateListFilter: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case actionTypes.setLoadingFilter: {
      return {
        ...state,
        loadingFilter: action.payload,
      };
    }

    case actionTypes.UpdateUserFilters: {
      const userFilters = [...state.userFilters];
      const filterUpdate = [...action.payload];
      if (filterUpdate && filterUpdate[0]) {
        filterUpdate.forEach((val) => {
          const index = userFilters.findIndex(
            (filter) => filter.id === val.id,
          );
          if (index > -1) {
            userFilters.splice(index, 1);
          }
        });
      }
      const updateUserFilter = userFilterAdapter([...userFilters, ...filterUpdate]);
      return {
        ...state,
        userFilters: updateUserFilter,
      };
    }

    case actionTypes.RemoveUserFilterSuccess: {
      const userFilters = [...state.userFilters];
      const filterRemove = action.payload;
      const index = userFilters.findIndex(
        (filter) => filter.filterName === filterRemove.filterName,
      );
      if (index > -1) {
        userFilters.splice(index, 1);
      }
      return {
        ...state,
        userFilters: userFilters,
        filter: [],
        removeFilterLoading: false,
      };
    }

    case actionTypes.UpdateUserCustomColumns: {
      let customColumns = [...state.userCustomColumns];
      // eslint-disable-next-line array-callback-return
      action.payload.map(row => {
        const index = customColumns.findIndex(item => item.page === row.page);
        if (index > -1) {
          customColumns[index] = row;
        } else {
          customColumns.push(row);
        }
      });
      return {
        ...state,
        userCustomColumns: customColumns,
      };
    }

    case actionTypes.DeleteUserFilterRequest: {
      return {
        ...state,
        removeFilterLoading: true,
      };
    }

    default:
      return state;
  }
};