import React, {useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { shallowEqual } from "shallow-equal";

export const PrivateRouteWrapper = (props) => {
  const {
    Component
  } = props;

  
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    isAuthorized,
    userPlan,
    expiredPlanError,
    connectionError,
    accessDeniedError
  } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null && auth.user.sub != null,
      userPlan: auth.userPlan,
      expiredPlanError: auth.expiredPlanError,
      connectionError: auth.connectionError,
      accessDeniedError: auth.accessDeniedError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthorized) {
      history.push('/auth/login');
    } else {
      const hasPlan = userPlan && userPlan.expiry_date;
      const currentProduct = localStorage.getItem("currentProduct");
      const ALLOWED_FREE_PLAN_PATHS = ['/plans', '/settings/company-profile', '/settings/user-profile', '/settings/billing', '/settings/connections', '/settings/team', '/settings/team-test', '/network-error'];
      if (!ALLOWED_FREE_PLAN_PATHS.includes(location.pathname)) {
         if (!hasPlan && !currentProduct && !connectionError) {
          history.push('/subscription-plans');
        } else if (expiredPlanError && !connectionError) {
          history.push('/no-subscription');
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, userPlan])

  useEffect(() => {
    const ALLOWED_FREE_PLAN_PATHS = ['/plans', '/settings/account', '/settings/billing', '/settings/connections', '/settings/team', '/settings/team-test', '/access-denied'];
    if (!ALLOWED_FREE_PLAN_PATHS.includes(location.pathname) && accessDeniedError && !connectionError) {
      history.push('/access-denied');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessDeniedError]);

  useEffect(() => {
    if(connectionError) {
      history.push('/network-error');
    }
  }, [connectionError, dispatch, history])

  if (!isAuthorized) {
    return null;
  }

  return <Component />;
}