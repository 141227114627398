import { actionTypes } from './actionTypes';

export const actions = {
    fetchAsinSales: payload => ({
      type: actionTypes.FetchAsinSales,
      payload
    }),
    disableSendButtonState: (payload = true) => ({
      type: actionTypes.DisableSendButtonState,
      payload
    }),
    updateAsinSales: payload => ({
      type: actionTypes.UpdateResultsAsinSales,
      payload: payload
    }),
    fetchWeekDetails: payload => ({
      type: actionTypes.FetchWeekDetails,
      payload
    }),
    setFetchingRecordState: payload => ({
      type: actionTypes.SetFetchingRecordState,
      payload
    }),
    updateWeekDetails: payload => ({
      type: actionTypes.UpdateWeekDetails,
      payload
    }),
    rowClicked: payload => ({
      type: actionTypes.RowClicked,
      payload
    }),
    clearWeekDetails: () => ({
      type: actionTypes.ClearWeekDetails
    }),
  };
  