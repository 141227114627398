import React, { useState } from "react";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { resendEmail } from "../../crud/auth.crud";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import DialogWindow from "../../components/DialogWindow/DialogWindow";
import { resendEmailCompleteText } from "../../../constants";
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';

const ResendEmail = () => {
  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);
  const intl = useIntl();

  const handleClose = () => {
    setTimeout(500, setIsRequested(false));
    history.push("/auth/login");
  };

  return (
    <AuthWrapper>
    <div className="kt-login__body" style={{ position: "relative" }}>
      <div className="kt-login__form">
        <div className="kt-login__back">
          <Link to="/auth">
            <ArrowBackIcon />
          </Link>
          <span>Back to Sign in</span>
        </div>
        <div className="kt-login__title">
          <h3>
            Resend email confirmation <br />
          </h3>
          <p className="login-subtitle">
            Enter your email below to get a new confirmation email
          </p>
        </div>

        <Formik
          initialValues={{ email: "" }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            resendEmail(values.email)
              .then(() => {
                setIsRequested(true);
              })
              .catch(() => {
                setSubmitting(false);
                setStatus(
                  intl.formatMessage(
                    { id: "AUTH.VALIDATION.NOT_FOUND" },
                    { name: values.email }
                  )
                );
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
              <form onSubmit={handleSubmit} className="kt-form">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    margin="normal"
                    fullWidth={true}
                    name="email"
                    className="kt-width-full custom-form-label"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    placeholder="Your email"
                  />
                </div>

                <div className="kt-login__actions" style={{ marginTop: 40 }}>
                  <button
                    type="submit"
                    className="btn btn-elevate kt-login__btn-primary"
                    disabled={isSubmitting}
                  >
                    Resend confirmation email
                  </button>
                </div>
              </form>
            )}
        </Formik>
      </div>
      {isRequested && (
        <DialogWindow
          text={resendEmailCompleteText}
          open={isRequested}
          handleClose={handleClose}
        />
      )}
    </div>
    </AuthWrapper>
  );
}

export default ResendEmail;
