import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from "react-redux";

export const PublicRouteWrapper = (props) => {
  const {
    Component
  } = props;

  const history = useHistory();

  const {
    isAuthorized,
  } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null && auth.user.sub != null,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized) {
      history.push('/keyword-spy')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  return <Component />;

}