export const actionTypes = {
    FetchUserProjectTags: "[FetchUserProjectTags] Action",
    SetLoadingProjectTag: "[SetLoadingProjectTag] Action",
    SetUserProjectTags: "[SetUserProjectTags] Action",
    AddUserProjectTags: "[AddUserProjectTags] Action",
    AddProjectTags: "[AddProjectTags] Action",
    SetAddingProjectTag: "[SetAddingProjectTag] Action",
    DeleteProjectTag: "[DeleteProjectTag] Action",
    RemoveProjectTag: "[RemoveProjectTag] Action",
    UpdateProjectTag: "[UpdateProjectTag] Action",
    UpdateUserProjectTag: "[UpdateUserProjectTag] Action",
};