import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import { reducer as asinAnalysisReducer } from './ducks/asin-analysis/reducer';
import { reducer as asinKeywordsReducer } from './ducks/asin-keywords/reducer';
import { reducer as asinSalesReducer } from './ducks/asin-sales/reducer';
import { reducer as authReducer } from './ducks/auth/reducer';
import { reducer as competitorAnalysisReducer } from './ducks/competitor-analysis/reducer';
import { reducer as dateRangeReducer } from './ducks/date-range/reducer';
import { reducer as keywordSalesReducer } from './ducks/keyword-sales/reducer';
import { reducer as keywordsRelevancyReducer } from './ducks/keywords-relevancy/reducer';
import { reducer as rankTrackerReducer } from './ducks/rank-tracker/reducer';
import { reducer as seasonalityReducer } from './ducks/seasonality/reducer';
import { reducer as stripeReducer } from './ducks/stripe/reducer';
import { reducer as userFiltersReducer } from './ducks/user-filter/reducer';
// import * as project from "./ducks/project/reducer";
import { reducer as keywordTagsReducer } from './ducks/keyword-tags/reducer';
import { reducer as marketplaceReducer } from './ducks/marketplace/reducer';
import { reducer as multipleAsinsAnalysisReducer } from './ducks/multiple-asins-analysis/reducer';
import { reducer as projectTagsReducer } from './ducks/project-tags/reducer';
import { reducer as projectsReducer } from './ducks/projects/reducer';
import { reducer as additionalUsersReducer } from './ducks/additional-users/reducer';

import { metronic } from '../../_metronic';

import { watchAsinAnalysisSaga } from './ducks/asin-analysis/saga';
import { watchAsinKeywordsSaga } from './ducks/asin-keywords/saga';
import { watchAsinSalesSaga } from './ducks/asin-sales/saga';
import { watchAuthSaga } from './ducks/auth/saga';
import { watchCompetitorAnalysisSaga } from './ducks/competitor-analysis/saga';
import { watchDateRangeSaga } from './ducks/date-range/saga';
import { watchFrontendAnalyticsSaga } from './ducks/frontend-analytics/saga';
import { watchKeywordSalesSaga } from './ducks/keyword-sales/saga';
import { watchKeywordTagsSaga } from './ducks/keyword-tags/saga';
import { watchKeywordsRelevancySaga } from './ducks/keywords-relevancy/saga';
import { watchMultipleAsinsAnalysisSaga } from './ducks/multiple-asins-analysis/saga';
import { watchProjectsSaga } from './ducks/projects/saga';
import { watchRankTrackerSaga } from './ducks/rank-tracker/saga';
import { watchSeasonalitySaga } from './ducks/seasonality/saga';
import { watchStripeSaga } from './ducks/stripe/saga';
import { watchUserFilterSaga } from './ducks/user-filter/saga';
import { watchProjectTagsSaga } from './ducks/project-tags/saga';
import { watchAdditionalUsersSaga } from './ducks/additional-users/saga';

export const rootReducer = combineReducers({
  auth: authReducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  rankTracker: rankTrackerReducer,
  seasonality: seasonalityReducer,
  asinAnalysis: asinAnalysisReducer,
  keywordsRelevancy: keywordsRelevancyReducer,
  stripe: stripeReducer,
  dateRange: dateRangeReducer,
  keywordSales: keywordSalesReducer,
  asinKeywords: asinKeywordsReducer,
  asinSales: asinSalesReducer,
  competitorAnalysis: competitorAnalysisReducer,
  userFilters: userFiltersReducer,
  projects: projectsReducer,
  multipleAsinsAnalysis: multipleAsinsAnalysisReducer,
  keywordTags: keywordTagsReducer,
  projectTags: projectTagsReducer,
  additionalUsers: additionalUsersReducer,
  marketplace: marketplaceReducer,
});

export function* rootSaga() {
  yield all([
    fork(watchAuthSaga),
    fork(watchRankTrackerSaga),
    fork(watchSeasonalitySaga),
    fork(watchAsinAnalysisSaga),
    fork(watchKeywordsRelevancySaga),
    fork(watchStripeSaga),
    fork(watchDateRangeSaga),
    fork(watchKeywordSalesSaga),
    fork(watchAsinKeywordsSaga),
    fork(watchAsinSalesSaga),
    fork(watchCompetitorAnalysisSaga),
    fork(watchUserFilterSaga),
    // fork(project.saga),
    fork(watchProjectsSaga),
    fork(watchMultipleAsinsAnalysisSaga),
    fork(watchKeywordTagsSaga),
    fork(watchProjectTagsSaga),
    fork(watchFrontendAnalyticsSaga),
    fork(watchAdditionalUsersSaga),
  ]);
  // yield all([auth.saga()]);
}
