import _ from "lodash";
import { actionTypes } from './actionTypes';

const initialAsinCompetitorState = {
    results: {},
    sendIsDisabled: false,
    details: [],
    clickedRow: undefined,
    isFetchingRecord: false,
  };
  
export const reducer =
    (state = initialAsinCompetitorState, action) => {
      switch (action.type) {
        case actionTypes.LocationChange: {
          return initialAsinCompetitorState;
        }
  
        case actionTypes.UpdateResultsCompetitor: {
          const updatedResults = action.payload;
          if (_.get(updatedResults, "data", []).length === 0) {
            alert("No results found for the given query parameters");
            return {
              ...state,
              results: {}
            }
          }
          return {
            ...state,
            results: updatedResults
          };
        }
  
        case actionTypes.DisableSendButtonState: {
          return {
            ...state,
            sendIsDisabled: action.payload
          };
        }
  
        case actionTypes.UpdateCompetitorDetails: {
          const updatedDetails = action.payload;
          const newDetails = [...state.details];
          newDetails.push(updatedDetails);
          return {
            ...state,
            details: newDetails,
          };
        }
  
        case actionTypes.SetFetchingRecordState: {
          return {
            ...state,
            isFetchingRecord: action.payload
          }
        }
  
        case actionTypes.RowClicked: {
          return {
            ...state,
            clickedRow: action.payload
          };
        }
  
        case actionTypes.ClearResults: {
          return {
            ...state,
            results: {}
          };
        }
  
        default:
          return state;
      }
}