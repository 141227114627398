import { actionTypes } from './actionTypes';

export const actions = {
    addRankTracker: payload => ({
      type: actionTypes.AddRankTracker,
      payload
    }),
    fetchRankTrackerDetails: payload => ({
      type: actionTypes.FetchRankTrackerDetails,
      payload
    }),
    deleteAsinRankTracker: payload => ({
      type: actionTypes.DeleteAsinRankTracker,
      payload
    }),
    modifyKeywordTracker: payload => ({
      type: actionTypes.ModifyKeywordTracker,
      payload
    }),
    addKeywordTracker: payload => ({
      type: actionTypes.AddKeywordTracker,
      payload
    }),
    deleteKeywordTracker: payload => ({
      type: actionTypes.DeleteKeywordTracker,
      payload
    }),
    updateResultsRankTracker: payload => ({
      type: actionTypes.UpdateResultsRankTracker,
      payload
    }),
    removeKeywordTracker: payload => ({
      type: actionTypes.RemoveKeywordTracker,
      payload
    }),
    removeTracker: payload => ({
      type: actionTypes.RemoveTracker,
      payload
    }),
    fetchKeywordDetails: payload => ({
      type: actionTypes.FetchKeywordDetails,
      payload
    }),
    updateKeywordDetails: payload => ({
      type: actionTypes.UpdateKeywordDetails,
      payload
    }),
    updateSending: payload => ({
      type: actionTypes.UpdateSending,
      payload
    }),
    updateTotalKeywords: payload => ({
      type: actionTypes.UpdateTotalKeywords,
      payload
    }),
    setLoading: (payload = true) => ({
      type: actionTypes.SetLoading,
      payload
    }),
    fetchRankDataForParticularProduct: payload => ({
      type: actionTypes.FetchRankDataForParticularProduct,
      payload
    }),
    setLoadingProduct: payload => ({
      type: actionTypes.SetLoadingProduct,
      payload
    }),
    updateProductRankValues: payload => ({
      type: actionTypes.UpdateProductRankValues,
      payload
    }),
    fetchParticularKeyword: payload => ({
      type: actionTypes.FetchParticularKeyword,
      payload
    }),
    updateKeywordRankValues: payload => ({
      type: actionTypes.UpdateKeywordRankValues,
      payload
    }),
    setLoadingKeyword: payload => ({
      type: actionTypes.SetLoadingKeyword,
      payload
    }),
    fetchRankTrackerForProject: payload => ({
      type: actionTypes.FetchRankTrackerForProject,
      payload
    }),
    updateRankTrackerForProject: payload => ({
      type: actionTypes.UpdateRankTrackerForProject,
      payload
    }),
    addKeywordTrackerForProject: payload => ({
      type: actionTypes.AddKeywordTrackerForProject,
      payload
    }),
    deleteKeywordTrackerForProject: payload => ({
      type: actionTypes.DeleteKeywordTrackerForProject,
      payload
    }),
    removeKeywordTrackerForProject: payload => ({
      type: actionTypes.RemoveKeywordTrackerForProject,
      payload
    }),
    fetchParticularKeywordForProject: payload => ({
      type: actionTypes.FetchParticularKeywordForProject,
      payload
    }),
    updateKeywordRankValuesForProject: payload => ({
      type: actionTypes.UpdateKeywordRankValuesForProject,
      payload
    }),
};