import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import { shallowEqual } from "shallow-equal";

function AccessDenied() {
    const {
        accessDeniedError,
    } = useSelector(
        ({ auth }) => ({

            accessDeniedError: auth.accessDeniedError,
        }),
        shallowEqual
    );

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div
            style={{
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>{accessDeniedError}</h2>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default AccessDenied;
