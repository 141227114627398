import avatarIcon from 'app/assets/images/icons/avatar-icon.svg';
import { Icon } from 'app/components/Material/Icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class MenuItemText extends React.Component {
  render() {
    const { item, parentItem, isActive, currentUser, user } = this.props;
    //Ui menu RP change, I'm hiding the old code. RP -> _RP
    return (
      <>
        {item.acronym !== '_RP' && item.acronym !== 'AS' && (
          <img
            src={`${window.location.origin}/media/icons/svg/AsideLeft/${item.icon}.svg`}
            alt="icon"
            style={
              isActive ? { display: 'none' } : { width: 20, marginRight: 8 }
            }
          />
        )}

        {item.acronym !== 'AS' ? (
          <img
            src={`${window.location.origin}/media/icons/svg/AsideLeft/${item.icon}-active.svg`}
            alt="icon"
            style={
              !isActive ? { display: 'none' } : { width: 20, marginRight: 8 }
            }
          />
        ) : (
          <span style={{ position: 'relative' }}>
            <img src={avatarIcon} alt="" />
            <span
              style={{
                fontSize: '24px',
                fontWeight: '800',
                color: '#fff',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {currentUser?.accountName
                ? currentUser?.accountName?.charAt(0)?.toUpperCase()
                : user?.given_name?.charAt(0)?.toUpperCase()}
            </span>
          </span>
        )}

        {parentItem && parentItem.bullet === 'dot' && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === 'line' && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        {item.acronym !== 'AS' ? (
          item.acronym !== '_RP' ? (
            <span
              className="kt-menu__link-text"
              style={{
                whiteSpace: 'nowrap',
                fontSize: 14,
                fontFamily: 'Manrope',
                color: `${isActive ? '#222222' : '#666666'}`,
                fontWeight: 600,
                lineHeight: '20px',
              }}
            >
              <span>
                {!item.translate ? (
                  item.title
                ) : (
                  <FormattedMessage
                    id={item.translate}
                    defaultMessage={item.title}
                  />
                )}
              </span>
            </span>
          ) : (
            <>
              <div
                style={{
                  backgroundColor: '#7478ED',
                  display: 'none',
                  padding: '4px',
                }}
                className="kt-menu__download-report"
              >
                <img
                  src={`${window.location.origin}/media/icons/svg/AsideLeft/${item.icon}.svg`}
                  alt="icon"
                />
              </div>
              <div
                className="kt-menu__link-text"
                style={{
                  backgroundColor: '#7478ED',
                  padding: '1rem 2rem',
                  borderRadius: '30px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span style={{ color: '#ffffff', fontWeight: '600' }}>
                    {!item.translate ? (
                      item.title
                    ) : (
                      <FormattedMessage
                        id={item.translate}
                        defaultMessage={item.title}
                      />
                    )}
                  </span>
                  <img
                    src={`${window.location.origin}/media/icons/svg/AsideLeft/${item.icon}.svg`}
                    alt="icon"
                  />
                </div>
              </div>
            </>
          )
        ) : (
          <span
            className="kt-menu__link-text"
            style={{
              marginLeft: '8px',
              fontFamily: 'Manrope',
            }}
          >
            <span
              className="kt-menu__link-text"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <span className="kt-menu__link-text">
                <span
                  style={{
                    color: '#222',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                  }}
                >
                  {currentUser?.accountName || `${user?.given_name}'s account`}
                </span>
              </span>
              <span className="kt-menu__link-text">
                <span
                  style={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    color: 'var(--icon-2nd-pr-text, #666)',
                  }}
                >
                  {currentUser?.firstName || user?.given_name || ''}
                </span>
                <span
                  style={{
                    marginLeft: '4px',
                    borderRadius: '4px',
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                    background: 'rgba(121, 127, 237, 0.10)',
                    color: '#666',
                    fontSize: '12px',
                    fontWeight: 500,
                    padding: '1px 6px',
                  }}
                >
                  {currentUser?.role || 'Owner'}
                </span>
              </span>
            </span>
            <span
              className="kt-menu__link-text"
              style={{ transform: 'rotate(180deg)' }}
            >
              <Icon name="expand-more" />
            </span>
          </span>
        )}

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}

        {item.beta && (
          <>
            <div
              style={{
                width: 40,
                height: 17,
                fontWeight: 600,
                fontSize: 12,
                color: '#fff',
                backgroundColor: '#c978f0',
                textAlign: 'center',
                position: 'absolute',
                right: -10,
                fontFamily: 'Manrope',
                borderRadius: 3,
              }}
            >
              Beta
            </div>
            <div
              style={{
                position: 'absolute',
                right: -10,
                top: 31,
                border: '5px solid',
                width: 0,
                height: 0,
                borderTopColor: '#7e4b96',
                borderRightColor: 'transparent',
                borderBottomColor: 'transparent',
                borderLeftColor: '#7e4b96',
                zIndex: -1,
              }}
            ></div>
          </>
        )}
      </>
    );
  }
}
