import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';

export const useApiData = (key = '', defaultValue = null) => {
  return useSelector(
    (state) => _.get(state, key, defaultValue),
    shallowEqual
  );
};

export const sortTags = (tags) => {
  if (!tags) return [];
  const pinLabels = tags?.filter((tag) => {
    return tag.pin;
  });
  const noPinLabels = tags?.filter((tag) => {
    return !tag.pin;
  });
  return [...pinLabels, ...noPinLabels]
}
