export const actionTypes = {
    GetUsers: "[GetUsers] Action",
    GetUsersSuccess: "[GetUsersSuccess] Action",
    GetUsersFailure: "[GetUsersFailure] Action",
    AddUser: "[AddUser] Action",
    AddUserSuccess: "[AddUserSuccess] Action",
    AddUserFailure: "[AddUserFailure] Action",
    UpdateUser: "[UpdateUser] Action",
    UpdateUserSuccess: "[UpdateUserSuccess] Action",
    UpdateUserFailure: "[UpdateUserFailure] Action",
    RemoveUser: "[RemoveUser] Action",
    RemoveUserSuccess: "[RemoveUserSuccess] Action",
    RemoveUserFailure: "[RemoveUserFailure] Action",
    UpdateUserId: "[UpdateUserId] Action",
  };