export const actionTypes = {
  FetchListProduct: '[FetchListProduct] Action',
  FetchListInvoices: '[FetchListInvoices] Action',
  UpdateListProduct: '[UpdateListProduct] Action',
  UpdateListInvoices: '[UpdateListInvoices] Action',
  CreateSubscription: '[CreateSubscription] Action',
  OnSubscriptionComplete: '[OnSubscriptionComplete] Action',
  RetryInvoiceWithNewPaymentMethod: '[RetryInvoiceWithNewPaymentMethod] Action',
  UpdateSubscription: '[UpdateSubscription] Action',
  UpdateSuccess: '[UpdateSuccess] Action',
  GetUserPlan: '[GetUserPlan] Action',
  UpdateProductResource: '[UpdateProductResource] Action',
  RetrievePaymentInfo: '[RetrievePayment] Action',
  UpdatePaymentInfo: '[UpdatePaymentInfo] Action',
  CancelSubscription: '[CancelSubscription] Action',
  CancelSuccess: '[CancelSuccess] Action',
  GrantAccess: '[GrantAccess] Action',
  RetrieveUpcomingInvoice: '[RetrieveUpcomingInvoice] Action',
  UpdateUpComingInvoice: '[UpdateUpComingInvoice] Action',
  SetSendingStatus: '[SetSendingStatus] Action',
  SetSendingInvoices: '[SetSendingInvoices] Action',
  UpdateUserPaymentMethod: '[UpdateUserPaymentMethod] Action',
  GetClientSecret: '[GetClientSecret] Action',
  SetClientSecret: '[SetClientSecret] Action',
  IsUpdatePayment: '[IsUpdatePayment] Action',
  GetInvoices: '[GetInvoices] Action',
  UpdateInvoices: '[UpdateInvoices] Action',
  ReactivateSubscription: '[ReactivateSubscription] Action',
  ReactivateSuccess: '[ReactivateSuccess] Action',
  SetSubscribeNewPlan: '[SetSubscribeToNewPlan] Action',
  PayLastInvoice: '[PayLastInvoice] Action',
  ApplyCoupon: '[ApplyCoupon] Action',
  VerifyCoupon: '[VerifyCoupon] Action',
  FetchInvoicePayment: '[FetchInvoicePayment] Action',
  UpdateInvoicePayment: '[UpdateInvoicePayment] Action',
  SetSendingPayment: '[SetSendingPayment] Action',
  GetInvoiceDetails: '[GetInvoiceDetails] Action',
  GetCustomerInfo: '[GetCustomerInfo] Action',
  SetCustomerInfo: '[SetCustomerInfo] Action',
  UpdateCustomerInfo: '[UpdateCustomerInfo] Action',
};