/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import KTToggle from '../../_assets/js/toggle';
import * as builder from '../../ducks/builder';
import useStyles from './Styles';

const Brand = props => {
  const ktToggleRef = React.createRef();
  useEffect(() => {
    new KTToggle(ktToggleRef.current, props.toggleOptions);
  }, [ktToggleRef, props.toggleOptions]);
  const classes = useStyles();
  return (
    <div
      className={`kt-aside__brand kt-grid__item ${props.brandClasses} ${classes.brand}`}
      id="kt_aside_brand"
    >
      {props.asideSelfMinimizeToggle && (
        <div className="kt-aside__brand-tools">
          <div className="kt-aside__brand-logo-mini">
            <Link to="" className={classes.headerLogo}>
              <img alt="logo" src='/media/logos/logo-datarova-mini.svg' />
            </Link>
          </div>
          <div className="kt-aside__brand-logo">
            <Link to="" className={classes.headerLogo}>
              <img alt="logo" src='/media/logos/logoDatarova.svg' />
            </Link>
          </div>
          {/* <button
            ref={ktToggleRef}
            className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--active"
            id="kt_aside_toggler"
            // Resize rank tracker chart
            onClick={() => {
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 50);
            }}
          >
            <span>
              <AngleDoubleLeftIcon />
            </span>
            <span>
              <AngleDoubleRightIcon />
            </span>
          </button> */}
        </div>
      )}
      {/* <div className="kt-aside__brand-logo">
        <Link to="" className={classes.headerLogo}>
          <img alt="logo" src={props.headerLogo} />
        </Link>
      </div> */}
    </div>
  );
};

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: 'brand',
      toString: true,
    }),
    asideSelfMinimizeToggle: objectPath.get(
      store.builder.layoutConfig,
      'aside.self.minimize.toggle',
    ),
    headerLogo: builder.selectors.getLogo(store),
    headerStickyLogo: builder.selectors.getStickyLogo(store),
    toggleOptions: {
      target: 'body',
      targetState: 'kt-aside--minimize',
      togglerState: 'kt-aside__brand-aside-toggler--active',
    },
  };
};

export default connect(mapStateToProps)(Brand);
