import { put, takeLatest, call } from "redux-saga/effects";
import moment from "moment";
import { request, serialize, getHeaders } from "../../../utils/fetch";
import { actions as authActions } from "../auth/actions";
import { actions } from './actions';
import { actionTypes } from './actionTypes';

export function* watchSeasonalitySaga() {
    yield takeLatest(actionTypes.FetchSeasonDetails, apiFetchSeasonDetailsSaga);
}

function* apiFetchSeasonDetailsSaga(action) {
  yield put(authActions.disableSendButtonState());
  const requestPayload = {
    searchTerm: action.payload.searchTerm,
    startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
    endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
    rangeType: action.payload.rangeType.toLowerCase(),
    searchType: action.payload.searchType.toLowerCase(),
    onlyOneKeyword: action.payload.onlyOneKeyword
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get(`/seasonality?${serialize(requestPayload)}`));
  if (apiRes) {
    yield put(actions.updateSeasonDetails(apiRes.data));
  }
  yield put(authActions.disableSendButtonState(false));
}