import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import posthog from 'posthog-js';

import * as auth from 'app/store/ducks/auth/actions';
import { awsLogout } from 'app/crud/auth.crud';
import { actions } from 'app/store/ducks/auth/actions';
import { LayoutSplashScreen } from '../../../_metronic';

function Logout(props) {
  const dispatch = useDispatch();
  const { fulfillUser, setParentEmail } = actions;

  useEffect(() => {
    props.logout();
    awsLogout()
      .then(user => {
        dispatch(fulfillUser(undefined));
        dispatch(setParentEmail());
        localStorage.removeItem('x-plan');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('OverviewSettings');
        localStorage.removeItem('currentProduct');
        localStorage.removeItem('ProjectSettings');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('x-parent-user-id');
        localStorage.removeItem('x-parent-user-email');
        localStorage.removeItem('ownAccount');
        localStorage.removeItem('selectedUser');
        posthog.reset();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {props.hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />};
    </>
  );
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions,
)(Logout);
