import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  beta: {
    display: 'inline-grid !important',
    fontWeight: 600,
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#c978f0',
    textAlign: 'center',
    fontFamily: 'Manrope',
    borderRadius: 3,
    position: 'absolute',
    top: -6,
    right: -6,
    lineHeight: '17px',
    padding: '0px 7px',
    width: 'max-content',
  },
}));

export const BetaTag = (props) => {
  const classes = useStyles();

  const { title, className } = props;

  return <div className={clsx(classes.beta, className)}>{title}</div>;
};
