export const searchHistoryKeywordsAdapter = (searchHistoryKeywords) => {
    const resultKeywords = [];

    for (let i = 0; i < searchHistoryKeywords.length; i += 1) {
        const searchHistoryItem = searchHistoryKeywords[i]

        resultKeywords.push({...searchHistoryItem, 
                                searchTime: searchHistoryItem.searchTime,
                                searchType: searchHistoryItem.params.searchType, 
                                searchTerm: searchHistoryItem.params.searchTerm.split(',').join(', ') });
    }

    return resultKeywords;
}