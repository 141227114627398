import { actionTypes } from "./actionTypes";

const initialAsinAnalysisState = {
    results: [],
    suggestedKeywords: []
  };
  
export const reducer =
    (state = initialAsinAnalysisState, action) => {
      switch (action.type) {
        // case actionTypes.LocationChange: {
        //   return {
        //     ...state,
        //     seasonDetails: []
        //   }
        // }
  
        case actionTypes.UpdateAsinAnalysis: {
          return {
            ...state,
            results: action.payload
          }
        }
  
        case actionTypes.UpdateSuggestedKeywords: {
          return {
            ...state,
            suggestedKeywords: action.payload
          }
        }
  
        default:
          return state;
    }
}