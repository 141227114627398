import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePaymentStyles } from '../classNames';
import { actions } from 'app/store/ducks/stripe/actions';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useApiData } from 'app/utils/helpers';
import { useDispatch } from 'react-redux';
import { notify } from 'app/Services/app/notificationService';
import { Button, ButtonTypes } from 'app/components/Material/Inputs/Button';
// import security from 'app/assets/images/icons/security.jpg';

function PaymentMethodModalBody({ onClose }) {
  const classes = usePaymentStyles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { isUpdatePayment, client_secret } = useApiData('stripe', {});

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !client_secret) {
      return;
    }
    setLoading(true);

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });
    if (result.error) {
      setLoading(false);
      notify.error(result.error.message);
    } else {
      dispatch(
        actions.updateUserPaymentMethod({
          paymentMethodId: result.setupIntent.payment_method,
        }),
      );
      setLoading(false);
      onClose({ showSuccessAlert: false });
    }
  };

  return (
    <>
      <div className={classes.formTitle}>Add Payment Method</div>
      {/* <div className={classes.formSummary}>
        <img className={classes.summaryIcon} src={security} alt="icon" />
        <div className={classes.purchaseSecurityText}>
          <span style={{ color: }}>Safe & Secure Payment.</span> 128-bit SSL encrypted payment.
        </div>
      </div> */}
      <PaymentElement />
      <div className={classes.buttonGroup}>
        <Button
          type={ButtonTypes.ghostSmall}
          disabled={isUpdatePayment || loading}
          onClick={onClose}
        >
          Cancel
        </Button>

        <div style={{ marginLeft: '32px' }}>
          <Button
            type={ButtonTypes.secondary}
            showBorder={true}
            disabled={isUpdatePayment || loading}
            onClick={handleSubmit}
          >
            Add
            {loading && <CircularProgress size={15} style={{ margin: 1 }} />}
          </Button>
        </div>
      </div>
    </>
  );
}
PaymentMethodModalBody.defaultProps = {
  open: false,
};
PaymentMethodModalBody.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
export default PaymentMethodModalBody;
