import { actionTypes } from './actionTypes';

export const actions = {
    fetchSeasonDetails: payload => ({
      type: actionTypes.FetchSeasonDetails,
      payload
    }),
    updateSeasonDetails: payload => ({
      type: actionTypes.UpdateSeasonDetails,
      payload
    })
  };