import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';
import { requestPassword } from '../../../crud/auth.crud';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import DialogNotifyWindow from 'app/components/DialogNotifyWindow/DialogNotifyWindow';
import styles from './SendVerification.module.scss';

const SendVerification = () => {
  const verifyEmail = localStorage.getItem('verifyEmail');

  const [isRequested, setIsRequested] = useState(false);
  const [message, setMessage] = useState(false);

  const handleResend = () => {
    requestPassword(verifyEmail)
      .then(() => {
        setIsRequested(true);
        setMessage('We sent you another link. Please check your inbox.');
      })
      .catch(() => {
        setIsRequested(true);
        setMessage(`The requested ${verifyEmail} is not found`);
      });
  };

  return (
    <>
      <AuthWrapper>
        <div className="login-form-title">Check your email</div>
        <div className={styles.verificationContent}>
          <div>We sent an email to</div>
          <div className={styles.verifyEmail}>{verifyEmail}</div>
          <br />
          <div>Not seeing the email?</div>
          <div>
            <button
              onClick={handleResend}
              className={`btn ${styles.resendBtn}`}
            >
              Resend
            </button>
          </div>
          <div>
            Still not seeing the email? Your account may be associated with a
            different email.
          </div>
          <div className={styles.contactSupport}>
            If you need help{' '}
            <span>
              <a href="mailto:support@datarova.com">contact support</a>
            </span>
          </div>
          <div style={{ paddingTop: '40px' }} className="kt-login__back">
            <Link to="/auth">
              <ArrowBackIcon />
            </Link>
            <span>Back to Sign in</span>
          </div>
        </div>
        {isRequested && (
          <DialogNotifyWindow
            text={message}
            open={isRequested}
            handleClose={() => setIsRequested(false)}
          />
        )}
      </AuthWrapper>
    </>
  );
};

export default SendVerification;
