import React, { useEffect, useState, useRef } from 'react';
 
import { LinearProgressWithLabel } from 'app/components/Material/LinearProgressWithLabel';
 
 export const ProgressBar = (props) => {
     const [progress, setProgress] = useState(0);
     const fastStarted = useRef(false);
     const defaultStarted = useRef(false);
     const fastTimerId = useRef();
     const defaultTimerId = useRef();
 
     const { loading, hideIndicator = false } = props;


     useEffect(() => {
        return () => {
            clearInterval(fastTimerId.current);
            clearInterval(defaultTimerId.current);
        }
     }, [])
 
     useEffect(() => {
            if (progress === 100) {
                clearInterval(fastTimerId.current);
                clearInterval(defaultTimerId.current);
            }
            if (!loading) {
                clearInterval(defaultTimerId.current);
                if (!fastStarted.current) {
                    const loadingRemainingTime = Math.ceil(1000/(100 - progress));
                    fastTimerId.current = setInterval(() => {
                        setProgress((prevProgress) => (prevProgress + 1 >= 100 ? 100 : prevProgress + 1));
                    }, loadingRemainingTime);
                    fastStarted.current = true;
                }
            } else {
                if (!defaultStarted.current) {
                    defaultTimerId.current = setInterval(() => {
                        setProgress((prevProgress) => (prevProgress + 3 >= 99 ? 99 : prevProgress + 3));
                    }, 1000);
                    defaultStarted.current = true;
                }
            }
     }, [loading, progress]);
 
     return <LinearProgressWithLabel value={progress} hideIndicator={hideIndicator} />;
 };