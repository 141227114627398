import { put, takeLatest, call, select } from "redux-saga/effects";
import moment from "moment";
import _isFunction from "lodash/isFunction";
import { request, getHeaders, serialize, postRequestWrapper } from "../../../utils/fetch";
import { getJwt } from "../../../crud/auth.crud";
import { actionTypes } from './actionTypes';
import { actions } from './actions';
import { actions as frontendAnalyticsActions } from '../frontend-analytics/actions';
import { toast } from 'react-toastify';
import { searchHistoryKeywordsAdapter } from './adapter';

const queryString = require('query-string');

export function* watchKeywordSalesSaga() {
    yield takeLatest(actionTypes.FetchResults, apiFetchSaga);
    yield takeLatest(actionTypes.FetchDetails, apiFetchDetailsSaga);
    yield takeLatest(actionTypes.SearchHistoryKeywordsRequest, apiFetchSearchHistoryKeywordsSaga);
    yield takeLatest(actionTypes.SearchHistoryKeywordsDeleteRequest, apiFetchSearchHistoryKeywordsDeleteSaga);
}

export const selectUserSelected = (state) => state.auth.user;

function* apiFetchSaga(action) {
    yield put(actions.disableSendButtonState());
    const requestPayload = {
      searchTerm: action.payload.searchTerm,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      rangeType: action.payload.rangeType.toLowerCase(),
      searchType: action.payload.searchType.toLowerCase(),
      onlyOneKeyword: action.payload.onlyOneKeyword,
      marketplace: action.payload.marketplace,
    };
    const token = yield getJwt();

    const userSelected = yield select(selectUserSelected);
    const action_id = Math.floor(Math.random() * (99999999999999 - 10**12 + 1) + 10**12);
    const dateRange = {
      startDate: requestPayload.startDate,
      endDate: requestPayload.endDate,
    }

    const dataLogging = {
      asin: requestPayload.ASIN,
      user_id: userSelected.sub,
      email: userSelected.email,
      dateRange: JSON.stringify(dateRange),
      keyword: requestPayload.searchTerm,
      type: "keyword-spy-search",
      page: "keyword-spy",
      action: "keyword-spy-search",
      action_id: action_id,
    }
    yield put(frontendAnalyticsActions.addFrontendAnalytics(dataLogging));
    const apiRes = yield postRequestWrapper("/records", token, requestPayload);
    // let apiRes = yield fetch('./mock-keyword.json');
    // apiRes = yield apiRes.json();
    if (apiRes) {
        if (apiRes.status === 'FAILED') {
            toast.error(apiRes.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
          const dataLoggingUpdate = {
            action_id: action_id,
          }
          yield put(frontendAnalyticsActions.updateFrontendAnalytics(dataLoggingUpdate));
          yield put(
            actions.updateResults(apiRes.data)
          );
        }
    }
    yield put(actions.disableSendButtonState(false));
}

function* apiFetchDetailsSaga(action) {
    yield put(actions.setFetchingRecordState(true));
    const isDefault = action.payload.isDefault;
    let requestPayload;
    if (isDefault) {
      requestPayload = {
        keyword: action.payload.keyword || '',
        project_id: action.payload.projectId || '',
        marketplace: action.payload.marketplace || '',
        isDefault,
      };
    } else {
      requestPayload = {
        keyword: action.payload.keyword || '',
        project_id: action.payload.projectId || '',
        startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
        endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
        rangeType: action.payload.rangeType.toLowerCase(),
        marketplace: action.payload.marketplace || '',
      };
    }
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/records/record?${serialize(requestPayload)}`));
    if (apiRes) {
      let res = {
        tableData: apiRes.data.tableData,
        chartData: {
          arrDates: apiRes.data.arr_dates,
          arrSales: apiRes.data.arr_sales,
          arrClicks: apiRes.data.arr_clicks,
          arrConversions: apiRes.data.arr_conversions,
        }
      }
      if (action.payload.projectId) {
        res = {...res, project_id: action.payload.projectId}
      } else {
        res = {...res, keyword: action.payload.keyword}
      }
      yield put(actions.updateDetails(res));
    } else {
      yield put(actions.rowClicked(null))
    }
    yield put(actions.setFetchingRecordState(false));
}

function* apiFetchSearchHistoryKeywordsSaga(action) {
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/history?area=KeywordSpy`));

    if (apiRes.status === 'OK') {
        const adaptedPayload = searchHistoryKeywordsAdapter(apiRes.data);
        yield put(actions.searchHistoryKeywordsSuccess(adaptedPayload));
            if (_isFunction(action.cb)) {
                action.cb();
            }
    } else {
        yield put(actions.searchHistoryKeywordsFailure());
    }
}

function* apiFetchSearchHistoryKeywordsDeleteSaga(action) {
    const headers = yield getHeaders();
    const id = action.payload;
    const stringifiedString = queryString.stringify({id});
    const apiRes = yield call(() => request(headers).delete(`/history?${stringifiedString}`));
    if (apiRes.status === 'OK') {
        if (_isFunction(action.cb)) {
            action.cb();
        }
        yield put(actions.searchHistoryKeywordsDeleteSuccess(action.payload));
    } else {
        yield put(actions.searchHistoryKeywordsDeleteFailure());
    }
}
