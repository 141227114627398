import axios from 'axios';
import { Auth } from 'aws-amplify';

export const LOGIN_URL = 'api/auth/login';
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';

export const ME_URL = 'api/me';

export function awsLogin(username, password) {
  let lowerUsername = username.toLowerCase();
  return Auth.signIn(lowerUsername, password);
}

export function awsCompletePassword(user, password) {
  return Auth.completeNewPassword(user, password);
}

export function awsLogout() {
  return Auth.signOut();
}

export async function requestPassword(email) {
  try {
    localStorage.setItem('verifyEmail', email);
    await Auth.forgotPassword(email);
  } catch (err) {
    console.log('Forgot pass', err.message);
  }
}

export async function getJwt() {
  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken();
    return accessToken.getJwtToken();
  } catch (error) {
    localStorage.clear();
    window.location.replace('/');
  }
}

export async function getUsersEmail() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.email;
  } catch (error) {
    console.log('Unable to get current authenticated users email');
  }
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export async function resendEmail(email) {
  try {
    await Auth.resendSignUp(email);
  } catch (err) {
    console.log('Resend email', err.message);
  }
}
