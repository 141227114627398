import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';

import * as routerHelpers from 'app/router/RouterHelpers';
import { AuthActionTypes as actionTypes } from './actionTypes';
import { IAuthState, TAuthAction } from './types';

const selectedUserStorage = localStorage.getItem('selectedUser');

const initialAuthState: IAuthState = {
  user: undefined,
  userPlan: {},
  resetUser: {},
  authToken: undefined,
  sendIsDisabled: false,
  isFetchingRecord: false,
  downloadingCSV: false,
  resultsCsv: [],
  resultsAsinsAnalysis: {},
  asinsDetails: [],
  signUpError: '',
  forgotPassError: '',
  loading: false,
  loadingButtonStyle: { paddingRight: '2.5rem' },
  isFetchingCsvData: false,
  requestError: false,
  priceDetails: [],
  expiredPlanError: '',
  accessDeniedError: '',
  connectionError: false,
  listUser: [],
  userSelected: selectedUserStorage ? JSON.parse(selectedUserStorage) : {},
  userDetails: undefined,
  userSettings: {
    direction: {},
  },
  isFetchingUserDetail: false,
  parentEmail: localStorage.getItem('x-parent-user-email') || null,
};

let xPlan = localStorage.getItem('x-plan');
if (xPlan) {
  try {
    initialAuthState.userPlan = jwtDecode(xPlan);
  } catch (error) {
    localStorage.removeItem('x-plan');
    xPlan = null;
  }
}

let userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
if (userDetails) {
  try {
    initialAuthState.userDetails = userDetails;
  } catch (error) {
    localStorage.removeItem('userDetails');
  }
}

export const reducer = persistReducer(
  {
    storage,
    key: 'demo2-auth',
    whitelist: ['user', 'authToken', 'filter'],
  },
  (
    state: IAuthState = initialAuthState,
    action: TAuthAction,
  ) => {
    // @ts-ignore
    if (window.Sentry) {
      if (
        ((action.type === 'persist/REHYDRATE' && action.key === 'demo2-auth') ||
          action.type === actionTypes.UserLoaded) &&
        _.get(action, 'payload.user')
      ) {
        // @ts-ignore
        window.Sentry.configureScope(function(scope) {
          scope.setUser({
            id: _.get(action, 'payload.user.id'),
            email: _.get(action, 'payload.user.email'),
          });
        });
      }
    }

    switch (action.type) {
      case actionTypes.LocationChange: {
        return {
          ...state,
          sendIsDisabled: false,
          downloadingCSV: false,
          resultsCsv: [],
          resultsAsinsAnalysis: {},
          asinsDetails: [],
          requestError: false,
          isFetchingCsvData: false,
          userCustomColumns: [],
          accessDeniedError: '',
        };
      }

      case actionTypes.SetUserPlan: {
        const xPlan = localStorage.getItem('x-plan');
        if (xPlan) {
          const userPlan = jwtDecode(xPlan);
          return {
            ...state,
            userPlan: userPlan,
          };
        }
        return state;
      }

      case actionTypes.enableLoading: {
        return {
          ...state,
          loading: true,
          loadingButtonStyle: { paddingRight: '3.5rem' },
        };
      }

      case actionTypes.saveUser: {
        return {
          ...state,
          resetUser: action.payload,
        };
      }

      case actionTypes.disableLoading: {
        return {
          ...state,
          loading: false,
          loadingButtonStyle: { paddingRight: '2.5rem' },
        };
      }

      case actionTypes.Login: {
        const { authToken } = action.payload;

        return {
          ...state,
          authToken,
          user: undefined,
        };
      }

      case actionTypes.signUpError: {
        return {
          ...state,
          signUpError: action.payload,
        };
      }

      case actionTypes.forgotPassError: {
        return {
          ...state,
          forgotPassError: action.payload,
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return {
          ...state,
          authToken,
          user: undefined
        };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return { ...initialAuthState, userPlan: {}, userDetails: {} };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return {
          ...state,
          user,
        };
      }

      case actionTypes.DisableSendButtonState: {
        return {
          ...state,
          sendIsDisabled: action.payload,
        };
      }

      case actionTypes.SetFetchingRecordState: {
        return {
          ...state,
          isFetchingRecord: action.payload,
        };
      }

      case actionTypes.SetDownloadingCSVState: {
        return {
          ...state,
          downloadingCSV: action.payload.flag,
        };
      }

      case actionTypes.RowClicked: {
        return {
          ...state,
          clickedRow: action.payload,
        };
      }

      case actionTypes.UpdateCsvData: {
        return {
          ...state,
          resultsCsv: action.payload.results,
        };
      }
      // report ~ Csv
      case actionTypes.DeleteReportSuccess: {
        let newReports = [...state.resultsCsv];
        newReports = newReports.filter(item => !action.payload.idRP.includes(String(item.id)));
        return {
          ...state,
          resultsCsv: newReports,
        };
      }

      case actionTypes.UpdateIsFetchingCsvData: {
        return {
          ...state,
          isFetchingCsvData: action.payload,
        };
      }

      case actionTypes.UpdateAsinsDetails: {
        const updatedResults = action.payload.apiRes;
        return {
          ...state,
          asinsDetails: updatedResults,
        };
      }

      case actionTypes.UpdateRequestError: {
        return {
          ...state,
          requestError: action.payload,
        };
      }

      case actionTypes.UpdateAsinPriceDetails: {
        let newDetails;
        if (state.priceDetails) {
          newDetails = [...state.priceDetails];
        } else {
          newDetails = [];
        }
        const updatedResult = action.payload.apiRes;
        const index = newDetails.findIndex(item => item.ASIN === updatedResult.ASIN);
        if (index < 0) {
          newDetails.push(updatedResult);
        }
        return {
          ...state,
          priceDetails: newDetails,
        };
      }

      case actionTypes.SetExpiredPlanError: {
        return {
          ...state,
          expiredPlanError: action.payload,
        };
      }

      case actionTypes.SetAccessDeniedError: {
        return {
          ...state,
          accessDeniedError: action.payload,
        };
      }

      case actionTypes.SetConnectionError: {
        return {
          ...state,
          connectionError: action.payload,
        };
      }

      case actionTypes.UpdateListUser: {
        let sortedListUser = action.payload;
        sortedListUser = _.orderBy(sortedListUser, [user => user.email.toLowerCase()], ['asc']);
        return {
          ...state,
          listUser: sortedListUser,
        };
      }

      case actionTypes.RevokeTokenSuccess: {
        let details = userDetails;
        details.isGoogleConnected = false;
        return {
          ...state,
          userDetails: details,
        };
      }

      case actionTypes.SelectUser: {
        let userSelectedSub = {};
        if (state.user?.sub === action.payload.sub) {
          userSelectedSub = {};
        } else {
          userSelectedSub = action.payload;
        }
        return {
          ...state,
          userSelected: userSelectedSub,
        };
      }

      case actionTypes.SetUserDetails: {
        const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
        if (userDetails) {
          return {
            ...state,
            userDetails,
            isFetchingUserDetail: false,
          };
        }
      }

      // eslint-disable-next-line no-fallthrough
      case actionTypes.SetUserPlanFromDB: {
        return {
          ...state,
          userPlan: {
            ...action.payload,
            iat: state.userPlan?.iat,
            exp: state.userPlan?.exp,
          },
        };
      }

      case actionTypes.SetUserSettings: {
        return {
          ...state,
          userSettings: action.payload,
        };
      }

      case actionTypes.FetchUserDetails: {
        return {
          ...state,
          isFetchingUserDetail: true,
        };
      }

      case actionTypes.SetParentEmail: {
        return {
          ...state,
          parentEmail: action.payload,
        };
      }

      default:
        return state;
    }
  },
);
