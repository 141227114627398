export const actionTypes = {
    FetchAsinSales: "[FetchAsinSales] Action",
    DisableSendButtonState: "[DisableSendButtonState] Action",
    UpdateResultsAsinSales: "[UpdateResultsAsinSales] Action",
    FetchWeekDetails: "[FetchWeekDetails] Action",
    UpdateWeekDetails: "[UpdateWeekDetails] Action",
    RowClicked: "[RowClicked] Action",
    ClearWeekDetails: "[ClearWeekDetails] Action",
    SetFetchingRecordState: "[SetFetchingRecordState] Action",
    LocationChange: "[LocationChange] Action"
};