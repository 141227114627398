import React from 'react';
import clsx from 'clsx';
import MuiMenu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiMenu-paper': {
            borderRadius: 12,
            boxShadow: theme.primaryShadow + '!important',
        },
        '&.With-Mobile-View': {
            '& .MuiMenu-paper': {
                [theme.breakpoints.down('sm')]: {
                    width: '100% !important',
                    height: '100%',
                    top: '0 !important',
                    left: '0 !important',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderRadius: 0,
                    '& .MuiList-root, .MuiMenu-list': {
                        height: '100%',
                    },
                }, 
            }
        }
    },
}))

export const Menu = (props) => {
    const {
        withMobileView = false,
        className,
        ...args
    } = props;
    const classes = useStyles();

    return <MuiMenu 
        {...args} 
        className={clsx(
            classes.root,
            className,
            {
                'With-Mobile-View': withMobileView,
            }
        )}
    />
}