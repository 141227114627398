import { put, takeLatest, call } from "redux-saga/effects";
import _get from "lodash/get";
import { getJwt } from "../../../crud/auth.crud";
import { postRequestWrapper, request, serialize, getHeaders } from "../../../utils/fetch";
import { actions as authActions } from "../auth/actions";
import { actionTypes } from './actionTypes';
import { actions } from './actions';

var stringify = require("qs-stringify");

export function* watchRankTrackerSaga() {
    yield takeLatest(actionTypes.AddRankTracker, apiAddRankTrackerSaga);
    yield takeLatest(actionTypes.FetchRankTrackerDetails, apiFetchRankTrackerDetailsSaga);
    yield takeLatest(actionTypes.DeleteAsinRankTracker, apiDeleteAsinRankTrackerSaga);
    yield takeLatest(actionTypes.ModifyKeywordTracker, apiModifyKeywordTrackerSaga);
    yield takeLatest(actionTypes.AddKeywordTracker, apiAddKeywordTrackerSaga);
    yield takeLatest(actionTypes.DeleteKeywordTracker, apiDeleteKeywordTrackerSaga);
    yield takeLatest(actionTypes.FetchKeywordDetails, apiFetchKeywordDetailsSaga);
    yield takeLatest(actionTypes.FetchRankDataForParticularProduct, apiFetchRankDataForParticularProductSaga);
    yield takeLatest(actionTypes.FetchParticularKeyword, apiFetchParticularKeywordSaga);
    yield takeLatest(actionTypes.FetchRankTrackerForProject, apiFetchRankTrackerForProjectSaga);
    yield takeLatest(actionTypes.AddKeywordTrackerForProject, apiAddKeywordTrackerForProjectSaga);
    yield takeLatest(actionTypes.DeleteKeywordTrackerForProject, apiDeleteKeywordTrackerForProjectSaga);
    yield takeLatest(actionTypes.FetchParticularKeywordForProject, apiFetchParticularKeywordForProjectSaga);
}

function* apiAddRankTrackerSaga(action) {
    yield put(authActions.disableSendButtonState());
    const requestPayload = {
      name: action.payload.name,
      ASIN: action.payload.textASINs,
      keywords: action.payload.keywords,
      exactAsinOnly: action.payload.exactAsin
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).post("/rank-tracker", stringify(requestPayload)));
    if (apiRes) {
      yield put({
        type: actionTypes.FetchRankTrackerDetails
      });
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiFetchRankTrackerDetailsSaga(action) {
    yield put(actions.setLoading(true));
    const selectedUserSub = _get(action, "payload.sub", null);
    const path = selectedUserSub ? `/rank-tracker?selectedUserSub=${selectedUserSub}` : "/rank-tracker";
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(path));
    if (apiRes && apiRes.status === "OK") {
      yield put(actions.updateResultsRankTracker(apiRes.data));
    }
    yield put(actions.setLoading(false));
}

function* apiDeleteAsinRankTrackerSaga(action) {
    yield put(authActions.disableSendButtonState());
    const requestPayload = {
      ASIN: action.payload.textASINs
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).delete(`/rank-tracker?${serialize(requestPayload)}`));
    if (apiRes) {
      yield put(actions.removeTracker(requestPayload));
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiModifyKeywordTrackerSaga(action) {
    yield put(authActions.disableSendButtonState());
    const requestPayload = {
      name: action.payload.name,
      ASIN: action.payload.textASINs,
      keywords: action.payload.keywords,
      exactAsinOnly: action.payload.exactAsin
    };
    const headers = yield getHeaders(); 
    const apiRes = yield call(() => request(headers).put("/rank-tracker", stringify(requestPayload)));
    if (apiRes) {
      yield put({
        type: actionTypes.FetchRankTrackerDetails
      });
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiAddKeywordTrackerSaga(action) {
    yield put(authActions.disableSendButtonState());
    const ASIN = action.payload.textASINs;
    const requestPayload = {
      newKeywords: action.payload.newKeywords
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).post(`/rank-tracker/${ASIN}`, stringify(requestPayload)));
    if (apiRes) {
      yield put({
        type: actionTypes.FetchRankTrackerDetails
      });
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiDeleteKeywordTrackerSaga(action) {
    yield put(authActions.disableSendButtonState());
    const keyword = action.payload.keyword;
    const ASIN = action.payload.textASINs;
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).delete(`/rank-tracker/${ASIN}?${serialize({keyword})}`));
    if (apiRes) {
      yield put(actions.removeKeywordTracker({
        ASIN,
        keyword
      }));
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiFetchKeywordDetailsSaga(action) {
    yield put(actions.updateSending(true));
    const token = yield getJwt();
    const requestPayload = {
      keyword: action.payload.keyword,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      rangeType: action.payload.rangeType.toLowerCase()
    };
    const apiRes = yield postRequestWrapper("/keyword-tracker-details", token, requestPayload);
    if (apiRes) {
      const res = {
        keyword: action.payload.keyword,
        data: apiRes
      }
      yield put(actions.updateKeywordDetails(res));
    }
    yield put(actions.updateSending(false));
}

function* apiFetchRankDataForParticularProductSaga(action) {
    yield put(actions.setLoadingProduct(action.payload.ASIN));
    const ASIN = action.payload.ASIN;
    const selectedUserSub = _get(action, "payload.selectedUserSub", null);
    const path = selectedUserSub ? `/rank-tracker/${ASIN}?selectedUserSub=${selectedUserSub}` : `/rank-tracker/${ASIN}`;
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(path));
    if (apiRes) {
      const res = {
        ASIN,
        data: apiRes.data
      }
      yield put(actions.updateProductRankValues(res));
    }
    yield put(actions.setLoadingProduct(""));
}

function* apiFetchParticularKeywordSaga(action) {
    yield put(actions.setLoadingKeyword(action.payload.keyword));
    const headers = yield getHeaders();
    const ASIN = action.payload.ASIN;
    const selectedUserSub = _get(action, "payload.selectedUserSub", null);
    const keyword = action.payload.keyword;
    const path = selectedUserSub ? `/rank-tracker/${ASIN}?${serialize({keyword})}&selectedUserSub=${selectedUserSub}` : `/rank-tracker/${ASIN}?${serialize({keyword})}`;
    const apiRes = yield call(() => request(headers).get(path));
    if (apiRes) {
      // Set oldest date in table content
      yield put(actions.updateKeywordRankValues(apiRes.data));
      if (_get(apiRes, "data.agg_date_with_timezone", "") !== "") {
        yield action.payload.onSuccess(_get(apiRes, "data.agg_date_with_timezone", "").split(",")[0].slice(0, 10))
      } else {
        yield action.payload.onSuccess();
      }
    }
    yield put(actions.setLoadingKeyword(""));
}

function* apiFetchRankTrackerForProjectSaga(action) {
    yield put(actions.setLoadingProduct(action.payload.ASIN));
    const ASIN = action.payload.ASIN;
    const selectedUserSub = _get(action, "payload.selectedUserSub", null);
    const path = selectedUserSub ? `/rank-tracker/${ASIN}?selectedUserSub=${selectedUserSub}` : `/rank-tracker/${ASIN}`;
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(path));
    if (apiRes) {
      const res = {
        ASIN,
        data: apiRes.data
      }
      yield put(actions.updateRankTrackerForProject(res));
    }
    yield put(actions.setLoadingProduct(""));
}

function* apiAddKeywordTrackerForProjectSaga(action) {
    yield put(authActions.disableSendButtonState());
    const ASIN = action.payload.textASINs;
    const requestPayload = {
      newKeywords: action.payload.newKeywords,
      name: action.payload.name
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).post(`/rank-tracker/${ASIN}`, stringify(requestPayload)));
    if (apiRes) {
      yield put({
        type: actionTypes.FetchRankTrackerForProject,
        payload: { ASIN }
      });
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiDeleteKeywordTrackerForProjectSaga(action) {
    yield put(authActions.disableSendButtonState());
    const keyword = action.payload.keyword;
    const ASIN = action.payload.textASINs;
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).delete(`/rank-tracker/${ASIN}?${serialize({keyword})}`));
    if (apiRes) {
      yield put(actions.removeKeywordTrackerForProject({
        ASIN,
        keyword
      }));
    }
    yield put(authActions.disableSendButtonState(false));
}

function* apiFetchParticularKeywordForProjectSaga(action) {
    yield put(actions.setLoadingKeyword(action.payload.keyword));
    const headers = yield getHeaders();
    const ASIN = action.payload.ASIN;
    const selectedUserSub = _get(action, "payload.selectedUserSub", null);
    const keyword = action.payload.keyword;
    const path = selectedUserSub ? `/rank-tracker/${ASIN}?${serialize({keyword})}&selectedUserSub=${selectedUserSub}` : `/rank-tracker/${ASIN}?${serialize({keyword})}`;
    const apiRes = yield call(() => request(headers).get(path));
    if (apiRes) {
      // Set oldest date in table content
      yield put(actions.updateKeywordRankValuesForProject(apiRes.data));
      if (_get(apiRes, "data.agg_date_with_timezone", "") !== "") {
        yield action.payload.onSuccess(_get(apiRes, "data.agg_date_with_timezone", "").split(",")[0].slice(0, 10))
      } else {
        yield action.payload.onSuccess();
      }
    }
    yield put(actions.setLoadingKeyword(""));
}