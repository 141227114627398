import React from 'react';
import '_metronic/_assets/sass/pages/login/login-7.scss';
import '_metronic/_assets/sass/global/components/base/mixins/_form-update.scss';
import logo from 'app/assets/images/logo/logo.jpg';

export function AuthWrapper({ children }) {
  return (
    <>
      <div>
        <div className="login-form">
          <div className="login-form-image">
            <img src={logo} alt="logo" />
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
