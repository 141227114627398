import { actionTypes } from './actionTypes';

const initialKeywordSalesState = {
    results: [],
    sendIsDisabled: false,
    clickedRow: undefined,
    topAsinRank: [],
    keywordSales: [],
    isFetchingRecord: false,
    details: [],
    searchedASIN: {},
    listSimilarASINs: [],
    isValidAsin: true,
    searchedNoResults: false,
    searchHistoryASINs: [],
    searchHistoryASINsLoading: false,
    deleteSearchHistoryASINsLoading: false,
};
  
export const reducer =
    (state = initialKeywordSalesState, action) => {
      switch (action.type) {
        case actionTypes.LocationChange: {
          return initialKeywordSalesState;
        }
  
        case actionTypes.UpdateResultsAsinKeywords: {
          if (action.payload.isValidAsin) {
            const updatedResults = action.payload.data;
            // TODO: refactor this
            if (updatedResults.length > 0) {
              return {
                ...state,
                results: updatedResults,
                sendIsDisabled: false,
                clickedRow: undefined,
                details: [],
                searchedASIN: action.payload.searchedASIN,
                listSimilarASINs: action.payload.listSimilarASINs,
                isValidAsin: action.payload.isValidAsin,
                searchedNoResults: false,
              };
            } else {
              return {
                ...state,
                results: [],
                sendIsDisabled: false,
                clickedRow: undefined,
                details: [],
                searchedASIN: {},
                listSimilarASINs: [],
                isValidAsin: action.payload.isValidAsin,
                searchedNoResults: true,
              };
            }
          } else {
            return {
              ...state,
              results: [],
              sendIsDisabled: false,
              clickedRow: undefined,
              details: [],
              searchedASIN: {},
              listSimilarASINs: [],
              isValidAsin: action.payload.isValidAsin,
              searchedNoResults: false,
            }
          }
        }
  
        case actionTypes.DisableSendButtonState: {
          return {
            ...state,
            sendIsDisabled: action.payload
          };
        }
  
        case actionTypes.RowClicked: {
          return {
            ...state,
            clickedRow: action.payload
          };
        }
  
        case actionTypes.UpdateTopAsinRank: {
          const updatedTopAsinRank = action.payload;
          const { page } = updatedTopAsinRank;
          const newTopAsinRank = [...state.topAsinRank];
          const index = newTopAsinRank.findIndex(item => item.page === page);
          if (index < 0) {
            newTopAsinRank.push(updatedTopAsinRank);
          } else {
            newTopAsinRank[index] = updatedTopAsinRank
          }
          return {
            ...state,
            topAsinRank: newTopAsinRank,
          };
        }
  
        case actionTypes.UpdateKeywordSales: {
          const updatedKeywordSales = action.payload;
          const { keyword } = updatedKeywordSales;
          const newKeywordSales = [...state.keywordSales];
          const index = newKeywordSales.findIndex(item => item.keyword === keyword);
          if (index < 0) {
            newKeywordSales.push(updatedKeywordSales);
          } else {
            newKeywordSales[index] = updatedKeywordSales
          }
          return {
            ...state,
            keywordSales: newKeywordSales,
          };
        }
  
        case actionTypes.SetFetchingRecordState: {
          return {
            ...state,
            isFetchingRecord: action.payload
          }
        }
  
        case actionTypes.UpdateAsinKeywordDetails: {
          const updatedDetails = action.payload;
          const newDetails = [...state.details];
          newDetails.push(updatedDetails);
          return {
            ...state,
            details: newDetails,
          };
        }
  
        case actionTypes.SetIsValidAsin: {
          return {
            ...state,
            isValidAsin: action.payload,
          };
        }

        case actionTypes.SearchHistoryASINsRequest: {
            return {
                ...state,
                searchHistoryASINsLoading: true
            }
        }

        case actionTypes.SearchHistoryASINsSuccess: {
            return {
                ...state,
                searchHistoryASINsLoading: false,
                searchHistoryASINs: action.payload,
            }
        }

        case actionTypes.SearchHistoryASINsFailure: {
            return {
                ...state,
                searchHistoryASINsLoading: false
            }
        }

        case actionTypes.SearchHistoryASINsDeleteRequest: {
            return {
                ...state,
                deleteSearchHistoryASINsLoading: true,
            }
        }

        case actionTypes.SearchHistoryASINsDeleteSuccess: {
            return {
                ...state,
                deleteSearchHistoryASINsLoading: false,
                successFullyDeleted: true
            }
        }

        case actionTypes.SearchHistoryASINsDeleteFailure: {
            return {
                ...state,
                deleteSearchHistoryASINsLoading: false,
                successFullyDeleted: false,
            }
        }
  
        default:
          return state;
      }
}