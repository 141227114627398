import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { requestPassword } from '../../crud/auth.crud';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';

const ForgotPassword = () => {
  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (isRequested) {
      setIsRequested(false)
      history.push('/auth/confirm-password');
    }
  }, [history, isRequested])

  return (
    <AuthWrapper>
      <div className="login-form-title">
        <FormattedMessage id="AUTH.FORGOT.TITLE" />
      </div>
      <div className="login-form-summary">
        <FormattedMessage id="AUTH.FORGOT.DESC" />
      </div>
      <div className="kt-login__body" style={{ position: 'relative' }}>
        <div className="kt-login__form">
          <Formik
            initialValues={{ email: '' }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_FIELD',
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              requestPassword(values.email)
                .then(() => {
                  history.push('/auth/send-verification');
                  // setIsRequested(true);
                })
                .catch(() => {
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage(
                      { id: 'AUTH.VALIDATION.NOT_FOUND' },
                      { name: values.email },
                    ),
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="kt-form">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <div className="label">Email</div>
                  <TextField
                    type="email"
                    margin="normal"
                    fullWidth={true}
                    name="email"
                    className="kt-width-full custom-form-label"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    placeholder="Enter email"
                  />
                </div>

                <div className="login-form-actions">
                  <button
                    type="submit"
                    className="btn button-login"
                    disabled={isSubmitting || !values.email}
                  >
                    Reset
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        {/* {isRequested && (
          <DialogWindow
            text={forgotPasswordCompleteText}
            open={isRequested}
            handleClose={handleClose}
          />
        )} */}
        <div className="kt-login__back">
          <Link to="/auth">
            <ArrowBackIcon />
          </Link>
          <span>Back to Sign in</span>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
