import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  toolTip: {
    // position: "relative",
    position: "absolute",
    right: 0
  },
  button: {
    margin: theme.spacing(1),
    cursor: "pointer"
  },
  customWidth: {
    maxWidth: 500
  },
  noMaxWidth: {
    maxWidth: "none"
  },
  fontSized: {
    fontSize: "13px"
  }
}));

export default useStyles;
