import { call, put } from "redux-saga/effects";
import config from "config";
import axios from "axios";
import posthog from 'posthog-js';

import { awsLogout } from "../crud/auth.crud";
import { sentryCaptureException } from "./sentry";
import { actions } from "../store/ducks/auth/actions";
import { getJwt } from "../crud/auth.crud";
import store from "../store/store";

var stringify = require("qs-stringify");

export function* postRequestWrapper(path, token, requestPayload) {
  let error = false;
  let setXPlan = false;
  let planHasExpired = false;
  let errorMessage = false;
  const xPlan = localStorage.getItem("x-plan") || "";
  const apiRes = yield call(() => {
    return fetch(config.api.SERVER_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`,
        "x-plan": xPlan,
        "x-parent-user-id": localStorage.getItem("x-parent-user-id") || "",
        "x-parent-user-email": localStorage.getItem("x-parent-user-email") || "",
      },
      body: stringify(requestPayload)
    })
      .then(resp => {
        // Access header to get x-plan
        for (var pair of resp.headers.entries()) {
          if (pair[0] === 'x-plan') {
            localStorage.setItem("x-plan", pair[1]);
            setXPlan = true;
          }
          if (pair[0] === 'x-parent-user-id' && pair[1]) {
            localStorage.setItem("x-parent-user-id", pair[1]);
            localStorage.setItem("ownAccount", 'disabled');
          }
          if (pair[0] === 'x-parent-user-email' && pair[1]) {
            localStorage.setItem("x-parent-user-email", pair[1]);
            store.dispatch(actions.setParentEmail(pair[1]));
          }
        }
        const status = resp.status;
        if (status === 401) {
          awsLogout();
          localStorage.clear();
          posthog.reset();
          window.location.replace("/");
        }
        return resp.json();
      })
      .then(data => {
        if(data && ((data.message && data.message.includes('Connection terminated')) || (data.error && data.error.includes('Connection terminated')))) {
          store.dispatch(actions.setConnectionError(true));
        }
        if (['Please choose a plan.', 'Please contact the account owner to renew the subscription.'].includes(data)) {
          planHasExpired = true;
          errorMessage = data;
        }
        if (data && [`You do not have permission to access this resource. Please to contact the account owner if you want access.`, `You aren't connected to this account. Please to contact the account owner if you want access again.`].includes(data.message)) {
          store.dispatch(actions.setAccessDeniedError(data.message));
        }
        if (!data.responseHasData && data.responseDataLocation) {
          return waitForResponseData(data.responseDataLocation);
        }
        store.dispatch(actions.setConnectionError(false));
        return data;
      })
      .catch(e => {
        error = true;
        sentryCaptureException(e, {
          payload: requestPayload,
          error: e
        });
      });
  });
  if (error) {
    if (path !== "/latest-date") {
      yield put(actions.updateRequestError(true));
    }
  }
  if (setXPlan) {
    yield put(actions.setUserPlan());
  }
  if (planHasExpired) {
    yield put(actions.setExpiredPlanError(errorMessage));
    return null;
  }
  return apiRes;
}

async function waitForResponseData(responseDataLocation) {
  let waitSeconds = 5;
  const maxWaitSeconds = 15;
  const start = new Date();
  const TEN_MINUTES = 1000 * 60 * 10;
  let lastError;
  while (new Date() - start < TEN_MINUTES) {
    try {
      const response = await fetch(responseDataLocation);
      if (response.status !== 403) {
        return await response.json();
      }
    } catch (e) {
      lastError = e;
      console.log(e);
    }
    // eslint-disable-next-line
    await new Promise(resolve => setTimeout(resolve, 1000 * waitSeconds));
    if (waitSeconds < maxWaitSeconds) {
      waitSeconds++;
    }
  }

  sentryCaptureException(lastError, {
    message: 'Request timed out',
    lastError,
    responseDataLocation,
  });

  throw new Error('Request timed out');
}

export const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

const behaviorAfterRequest = (resp) =>{
  store.dispatch(actions.setConnectionError(false));
  if(resp.headers && resp.headers["x-plan"]) {
    localStorage.setItem("x-plan", resp.headers["x-plan"]);
    store.dispatch(actions.setUserPlan());
  }
  if(resp.headers && resp.headers["x-parent-user-id"]) {
    localStorage.setItem("ownAccount", 'disabled');
    localStorage.setItem("x-parent-user-id", resp.headers["x-parent-user-id"]);
  }
  if(resp.headers && resp.headers["x-parent-user-email"]) {
    localStorage.setItem("x-parent-user-email", resp.headers["x-parent-user-email"]);
    store.dispatch(actions.setParentEmail(resp.headers["x-parent-user-email"]));
  }
  const status = resp.status;
  if (status === 401) {
    awsLogout();
    posthog.reset();
    localStorage.clear();
    window.location.replace("/");
  }
  const data = resp.data;
  if (['Please choose a plan.', 'Please contact the account owner to renew the subscription.'].includes(data)) {
    store.dispatch(actions.setExpiredPlanError(data));
    return null;
  }
  if (data && [`You do not have permission to access this resource. Please to contact the account owner if you want access.`, `You aren't connected to this account. Please to contact the account owner if you want access again.`].includes(data.message)) {
    store.dispatch(actions.setAccessDeniedError(data.message));
    return null;
  }
  if (!data.responseHasData && data.responseDataLocation) {
    return waitForResponseData(data.responseDataLocation);
  }
  if(data && ((data.message && data.message.includes('Connection terminated')) || (data.error && data.error.includes('Connection terminated')))) {
    store.dispatch(actions.setConnectionError(true));
  }
  return resp.data;
}

const behaviorWhenError = (error, url) =>{
  if(error.message === "Network Error") {
    store.dispatch(actions.setConnectionError(true));
    store.dispatch(actions.updateRequestError(true));
    sentryCaptureException(error, {
      url,
      error: error
    });
    return "Network Error";
  }
  if(error.message === "Request failed with status code 402") {
    const response = error.response || {};
    if(response.headers && response.headers["x-plan"]) {
      localStorage.setItem("x-plan", response.headers["x-plan"]);
      store.dispatch(actions.setUserPlan());
    }
    if(response.headers && response.headers["x-parent-user-id"]) {
      localStorage.setItem("x-parent-user-id", response.headers["x-parent-user-id"]);
      localStorage.setItem("ownAccount", 'disabled');
    }
    if(response.headers && response.headers["x-parent-user-email"]) {
      localStorage.setItem("x-parent-user-email", response.headers["x-parent-user-email"]);
      store.dispatch(actions.setParentEmail(response.headers["x-parent-user-email"]));
    }
    const data = response.data
    if(['Please choose a plan.', 'Please contact the account owner to renew the subscription.'].includes(data)) {
      store.dispatch(actions.setExpiredPlanError(data));
      return null;
    }
    if (data && [`You do not have permission to access this resource. Please to contact the account owner if you want access.`, `You aren't connected to this account. Please to contact the account owner if you want access again.`].includes(data.message)) {
      store.dispatch(actions.setAccessDeniedError(data.message));
      return null;
    }
  }

  if(error.message === "Request failed with status code 403") {
    const response = error.response || {};
    if(response.headers && response.headers["x-plan"]) {
      localStorage.setItem("x-plan", response.headers["x-plan"]);
      store.dispatch(actions.setUserPlan());
    }
    if(response.headers && response.headers["x-parent-user-id"]) {
      localStorage.setItem("x-parent-user-id", response.headers["x-parent-user-id"]);
      localStorage.setItem("ownAccount", 'disabled');
    }
    if(response.headers && response.headers["x-parent-user-email"]) {
      localStorage.setItem("x-parent-user-email", response.headers["x-parent-user-email"]);
      store.dispatch(actions.setParentEmail(response.headers["x-parent-user-email"]));
    }
    const data = response.data
    if(['Please choose a plan.', 'Please contact the account owner to renew the subscription.'].includes(data)) {
      store.dispatch(actions.setExpiredPlanError(data));
      return null;
    }
    if (data && [`You do not have permission to access this resource. Please to contact the account owner if you want access.`, `You aren't connected to this account. Please to contact the account owner if you want access again.`].includes(data.message)) {
      store.dispatch(actions.setAccessDeniedError(data.message));
      return null;
    }
  }
  if(error && error.response && error.response.data && ((error.response.data.message && error.response.data.message.includes('Connection terminated')) || (error.response.data.error && error.response.data.error.includes('Connection terminated')))) {
    store.dispatch(actions.setConnectionError(true));
    return null;
  }
  return error.response?.data;
}

export const getHeaders = async () => {
  const token = await getJwt();
  const xPlan = localStorage.getItem("x-plan") || "";
  const xParentUserId = localStorage.getItem("x-parent-user-id") || "";
  const xParentUserEmail = localStorage.getItem("x-parent-user-email") || "";
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    "Authorization": `Bearer ${token}`,
    "x-plan": xPlan,
    "x-parent-user-id": xParentUserId,
    "x-parent-user-email": xParentUserEmail,
  }
}

export const getHeadersNoAuth = async () => {
  return {
    "Content-Type": "application/x-www-form-urlencoded",
  }
}

export const fetchDatarova = (url, payload) => {
  var data = JSON.stringify(payload);

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
      console.log(this.responseText);
    }
  });

  xhr.open("POST", `https://dev.datarova.com/v2.0/api/n${url}`);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(data);
}

export const request = (opts = {}) => {
  const defaultOptions = {
    headers: {
      ...opts,
    },
  };

  const axiosApi = axios.create({
    baseURL: config.api.SERVER_URL
  });

  return {
    get: (url, options = {}) => {
      return axiosApi
        .get(url, { ...defaultOptions, ...options })
        .then(response => behaviorAfterRequest(response))
        .catch(error => behaviorWhenError(error, url))
    },
    post: (url, data, options = {}) =>
      axiosApi
        .post(url, data, { ...defaultOptions, ...options })
        .then(response => behaviorAfterRequest(response))
        .catch(error => behaviorWhenError(error, url)),
    put: (url, data, options = {}) =>
      axiosApi
        .put(url, data, { ...defaultOptions, ...options })
        .then(response => behaviorAfterRequest(response))
        .catch(error => behaviorWhenError(error, url)),
    patch: (url, data, options = {}) =>
      axiosApi
        .patch(url, data, { ...defaultOptions, ...options })
        .then(response => behaviorAfterRequest(response))
        .catch(error => behaviorWhenError(error, url)),
    delete: (url, options = {}) =>
      axiosApi
        .delete(url, { ...defaultOptions, ...options })
        .then(response => behaviorAfterRequest(response))
        .catch(error => behaviorWhenError(error, url)),
  };
};

export default request;
