import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import useStyles from "./Styles";

export default function TriggersTooltips({ title, position }) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className={classes.toolTip}>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={title}
            placement={position}
            classes={{ tooltip: classes.fontSized }}
          >
            <HelpOutlineIcon
              fontSize="medium"
              onClick={handleTooltipOpen}
              className={classes.button}
            />
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
}
