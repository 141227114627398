import { actionTypes } from './actionTypes';

export const actions = {
    fetchSearchTerms: payload => ({
      type: actionTypes.FetchSearchTerms,
      payload
    }),
    disableSendButtonState: (payload = true) => ({
      type: actionTypes.DisableSendButtonState,
      payload
    }),
    UpdateResultsAsinKeywords: payload => ({
      type: actionTypes.UpdateResultsAsinKeywords,
      payload: payload
    }),
    rowClicked: payload => ({
      type: actionTypes.RowClicked,
      payload
    }),
    fetchTopAsinRank: payload => ({
      type: actionTypes.FetchTopAsinRank,
      payload
    }),
    updateTopAsinRank: payload => ({
      type: actionTypes.UpdateTopAsinRank,
      payload
    }),
    fetchKeywordSales: payload => ({
      type: actionTypes.FetchKeywordSales,
      payload
    }),
    updateKeywordSales: payload => ({
      type: actionTypes.UpdateKeywordSales,
      payload
    }),
    setFetchingRecordState: payload => ({
      type: actionTypes.SetFetchingRecordState,
      payload
    }),
    fetchAsinKeywordDetails: payload => ({
      type: actionTypes.FetchAsinKeywordDetails,
      payload
    }),
    updateAsinKeywordDetails: (payload) => ({
      type: actionTypes.UpdateAsinKeywordDetails,
      payload
    }),
    setIsValidAsin: (payload) => ({
      type: actionTypes.SetIsValidAsin,
      payload
    }),
    searchHistoryASINsRequest: () => ({
        type: actionTypes.SearchHistoryASINsRequest,
    }),
    searchHistoryASINsSuccess: (payload) => ({
        type: actionTypes.SearchHistoryASINsSuccess,
        payload
    }),
    searchHistoryASINsFailure: () => ({
        type: actionTypes.SearchHistoryASINsFailure,
    }),
    searchHistoryASINsDeleteRequest: (payload, cb) => ({
        type: actionTypes.SearchHistoryASINsDeleteRequest,
        payload,
        cb
    }),
    searchHistoryASINsDeleteSuccess: (payload) => ({
        type: actionTypes.SearchHistoryASINsDeleteSuccess,
        payload
    }),
    searchHistoryASINsDeleteFailure: () => ({
        type: actionTypes.SearchHistoryASINsDeleteFailure,
    }),
};