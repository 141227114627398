import { actionTypes } from './actionTypes';

export interface ITag {
  id: number;
  tag: string;
  colour: string;
  pin: boolean;
  keywords?: number;
  asins?: number;
}

interface IState {
  tags: ITag[];
  tagsExist: boolean;
  loadingTag: boolean;
  addingTag: boolean;
}

const initialUserFiltersState: IState = {
  tags: [],
  tagsExist: false,
  loadingTag: false,
  addingTag: false,
};

export const reducer = (state = initialUserFiltersState, action) => {
  switch (action.type) {
    case actionTypes.SetLoadingTag: {
      return {
        ...state,
        loadingTag: action.payload,
      };
    }

    case actionTypes.SetUserTags: {
      return {
        ...state,
        tags: action.payload,
        tagsExist: true,
      };
    }

    case actionTypes.AddUserTags: {
      const addedTags = action.payload.map(item => ({
        id: item.id,
        tag: item.tag,
        colour: item.colour,
        pin: item.pin,
        keywords: 0,
        asins: 0,
      }));
      return {
        ...state,
        tags: [...state.tags, ...addedTags],
      };
    }

    case actionTypes.SetAddingTag: {
      return {
        ...state,
        addingTag: action.payload,
      };
    }

    case actionTypes.RemoveTag: {
      const tags = [...state.tags];
      const removedTag = action.payload;
      const index = tags.findIndex(tag => tag.id === removedTag.id);
      if (index > -1) {
        tags.splice(index, 1);
      }
      return {
        ...state,
        tags,
      };
    }

    case actionTypes.UpdateUserTag: {
      const tags = [...state.tags];
      const { id, tag, colour, pin } = action.payload;
      const index = tags.findIndex(tag => tag.id === id);
      if (index > -1) {
        tags[index] = {
          ...tags[index],
          tag,
          colour,
          pin,
        };
      }
      return {
        ...state,
        tags,
      };
    }

    default:
      return state;
  }
};