import { actionTypes } from './actionTypes';

const initialAdditionalUsersState = {
    loading: false,
    updateSuccess: false,
    addSuccess: false,
    removeSuccess: false,
    additionalUsers: [],
    additionalUsersLimit: 0,
    additionalUsersCount: 0,
  };
  
export const reducer =
    (state = initialAdditionalUsersState, action) => {
      switch (action.type) {
  
        case actionTypes.GetUsers: {
          return {
            ...state,
            loading: true,
          }
        }
        case actionTypes.GetUsersFailure: {
          return {
            ...state,
            loading: false,
            additionalUsers: [],
            additionalUsersLimit: 0,
            additionalUsersCount: 0,
          }
        }
        case actionTypes.GetUsersSuccess: {
          return {
            ...state,
            loading: false,
            additionalUsers: action.payload.data.users,
            additionalUsersLimit: action.payload.data.additionalUsersLimit,
            additionalUsersCount: action.payload.data.additionalUsersCount,
          }
        }

        case actionTypes.UpdateUser: {
          return {
            ...state,
            loading: true,
            updateSuccess: false,
          }
        }
        case actionTypes.UpdateUserFailure: {
          return {
            ...state,
            loading: false,
            updateSuccess: false,
          }
        }
        case actionTypes.UpdateUserSuccess: {
          return {
            ...state,
            loading: false,
            updateSuccess: true
          }
        }

        case actionTypes.AddUser: {
          return {
            ...state,
            loading: true,
            addSuccess: false,
          }
        }
        case actionTypes.AddUserFailure: {
          return {
            ...state,
            loading: false,
            addSuccess: false,
          }
        }
        case actionTypes.AddUserSuccess: {
          return {
            ...state,
            loading: false,
            addSuccess: true
          }
        }

        case actionTypes.RemoveUser: {
          return {
            ...state,
            loading: true,
            removeSuccess: false,
          }
        }
        case actionTypes.RemoveUserFailure: {
          return {
            ...state,
            loading: false,
            removeSuccess: false,
          }
        }
        case actionTypes.RemoveUserSuccess: {
          return {
            ...state,
            loading: false,
            removeSuccess: true
          }
        }
  
        default:
          return state;
      }
}
  