import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper';
import styles from './CompletePurchase.module.scss';
import security from 'app/assets/images/icons/security.jpg';
import CardForm from './CardForm';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import moment from 'moment';
import {useApiData} from "../../../utils/helpers";
import {actions} from "../../../store/ducks/stripe/actions";
import {ProgressBar} from "../../../components/Material/ProgressBar";

const CompletePurchase = () => {
  const history = useHistory();

  const userPlan = useSelector(state => state.auth.userPlan);

  const [currentProduct, setCurrentProduct] = useState();
  const dispatch = useDispatch();
  const { client_secret } = useApiData('stripe', {});
  const getCurrentProduct = localStorage.getItem('currentProduct');
  const [showLoading, setShowLoading] = useState(true);


  useEffect(() => {
    if(!client_secret) {
      dispatch(actions.getClientSecret());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(client_secret) {
      setShowLoading(false);
    }
  }, [client_secret])

  useEffect(() => {
    setCurrentProduct(JSON.parse(getCurrentProduct));
  }, [getCurrentProduct]);

  useEffect(() => {
    if (userPlan && userPlan.expiry_date) {
      history.push('/keyword-spy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPlan]);

  const date = new Date();
  const selectedInterval = currentProduct && currentProduct.selectedInterval ? currentProduct.selectedInterval : 'month';
  const currentPrice = currentProduct ? currentProduct.prices.find(item => item.interval === selectedInterval) : null;
  return (
    <AuthWrapper>
      <div className={styles.purchase}>
        <div className="login-form-title">Start Your Free Trial</div>
        <div className={styles.purchaseSecurity}>
          <img src={security} alt="icon" />
          <div className={styles.purchaseSecurityText}>
            Safe & Secure Payment. 128-bit SSL encrypted payment.
          </div>
        </div>
        {(currentProduct && currentPrice) && (
          <>
            <div className={styles.purchasePlanName}>
              {currentProduct.name} Plan
            </div>
            <div className={styles.purchasePlanSummary}>
              Free 14-day trial, then $
              {(currentPrice.price / 100)?.toFixed(2)}/every {selectedInterval === 'month' ? 'month' : 'year'}. No
              commitment.
              <br />
              Cancel any time. 30-day money back guarantee.
            </div>
            <div className={styles.purchaseBilled}>
              <div>Billed Now</div>
              <div>$0.00</div>
            </div>
            {showLoading ? (
                <>
                  <ProgressBar
                      showLoading={showLoading}
                      hideIndicator={true}
                  />
                </>
            ) : <Elements stripe={window.stripe} options={{clientSecret: client_secret}}>
              <CardForm priceId={currentPrice.priceId}/>
            </Elements>}
            <div className={styles.purchaseSummary}>
              After your trial ends you will be charged $
              {(currentPrice.price / 100)?.toFixed(2)} per {selectedInterval === 'month' ? 'month' : 'year'} starting{' '}
              {moment(date)
                .add(14, 'days')
                .format('ll')}
              . Billing will renew automatically every {selectedInterval === 'month' ? 'month' : 'year'}. Cancel anytime in
              Settings.
            </div>
            <div style={{ paddingTop: '32px' }} className="kt-login__back">
              <Link to="/subscription-plans">
                <ArrowBackIcon />
              </Link>
              <span>Back to plans</span>
            </div>
          </>
        )}
      </div>
    </AuthWrapper>
  );
};

export default CompletePurchase;
