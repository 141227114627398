import { actionTypes } from './actionTypes';

export const actions = {
    fetchUserProjectTags: (payload) => ({
        type: actionTypes.FetchUserProjectTags,
        payload,
    }),
    setLoadingProjectTag: (payload) => ({
        type: actionTypes.SetLoadingProjectTag,
        payload,
    }),
    setUserProjectTags: (payload) => ({
        type: actionTypes.SetUserProjectTags,
        payload,
    }),
    addUserProjectTags: (payload) => ({
        type: actionTypes.AddUserProjectTags,
        payload,
    }),
    addProjectTags: (payload, cb) => ({
        type: actionTypes.AddProjectTags,
        payload,
        cb,
    }),
    setAddingProjectTag: (payload) => ({
        type: actionTypes.SetAddingProjectTag,
        payload,
    }),
    deleteProjectTag: (payload) => ({
        type: actionTypes.DeleteProjectTag,
        payload,
    }),
    removeProjectTag: (payload, cb) => ({
        type: actionTypes.RemoveProjectTag,
        payload,
        cb,
    }),
    updateProjectTag: (payload, cb) => ({
        type: actionTypes.UpdateProjectTag,
        payload,
        cb,
    }),
    updateUserProjectTag: (payload) => ({
        type: actionTypes.UpdateUserProjectTag,
        payload,
    }),
};