import { actionTypes } from './actionTypes';

export const actions = {
  fetchUserTags: (payload) => ({
    type: actionTypes.FetchUserTags,
    payload,
  }),
  setLoadingTag: (payload) => ({
    type: actionTypes.SetLoadingTag,
    payload,
  }),
  setUserTags: (payload) => ({
    type: actionTypes.SetUserTags,
    payload,
  }),
  addUserTags: (payload) => ({
    type: actionTypes.AddUserTags,
    payload,
  }),
  addTags: (payload, cb) => ({
    type: actionTypes.AddTags,
    payload,
    cb,
  }),
  setAddingTag: (payload) => ({
    type: actionTypes.SetAddingTag,
    payload,
  }),
  deleteTag: (payload) => ({
    type: actionTypes.DeleteTag,
    payload,
  }),
  removeTag: (payload, cb) => ({
    type: actionTypes.RemoveTag,
    payload,
    cb,
  }),
  updateTag: (payload, cb) => ({
    type: actionTypes.UpdateTag,
    payload,
    cb,
  }),
  updateUserTag: (payload) => ({
    type: actionTypes.UpdateUserTag,
    payload,
  }),
};
